import {
  Button,
  Col,
  DatePicker,
  Form,
  FormProps,
  InputNumber,
  Row,
  Select,
  App,
} from 'antd';
import { FC, useContext, useEffect, useState } from 'react';
import {
  NamedItem,
  LandParcel,
  HarvestEvent,
  CalendarEventContent,
} from '../../../types/types';
import { baseFormLayout, fullWidth } from '../../../shared/styles/baseStyle';
import LoadingSpin from '../../LoadingSpin/LoadingSpin';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getData, mutateData } from '../../../shared/services/apiServices';
import useCreateAxiosInstance from '../../../shared/hooks/useCreateAxiosInstance';
import endPoints from '../../../shared/endPoints.json';
import type { Dayjs } from 'dayjs';
import { Method } from 'axios';
import dayjs from 'dayjs';
import CommonFormItems from './CommonFormItems';
import useReminderMutation from '../../../shared/hooks/useEventReminderMutation';
import EventReminder from '../../EventReminder/EventReminder';
import { CultivationActivity } from '../../../shared/context/activityMutation';

type Props = {
  selectedCultivation: LandParcel | undefined;
  selectedDate: Dayjs | undefined;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEvent: CalendarEventContent | undefined;
  apiMethod: Method;
  successMessage: string;
  viewOnly: boolean;
  disabledDate: any;
};
const HarvestForm: FC<Props> = ({
  selectedCultivation,
  selectedDate,
  setIsModalOpen,
  selectedEvent,
  apiMethod,
  successMessage,
  viewOnly,
  disabledDate,
}) => {
  const { message } = App.useApp();

  const { activityMutation, setActivityMutation } =
    useContext(CultivationActivity);
  const { request } = useCreateAxiosInstance();
  const { mutate: mutateReminder } = useReminderMutation();
  const [form] = Form.useForm();
  const [harvestMethodId, setHarvestMethodId] = useState<number>();
  const dateFormat = 'DD/MM/YYYY';
  const requiredRuleConfig = {
    required: true,
    message: 'Υποχρεωτικό πεδίο',
  };
  const { data: harvestData } = useQuery({
    queryKey: ['harvestData'],
    queryFn: () =>
      getData(
        request,
        `${endPoints.CALENDAR.LANDPARCELHARVEST}/${selectedEvent?.id}`,
      ),
    select(data: { data: HarvestEvent }) {
      return data.data;
    },
    enabled: !!selectedEvent,
  });

  useEffect(() => {
    if (harvestData) {
      setHarvestMethodId(harvestData.harvestMethodId);
      form.setFieldsValue({
        cultivation: !selectedEvent?.reminderId
          ? selectedEvent?.landParcelCultivation
          : selectedEvent?.cultivationName,
        landParcelId: selectedEvent?.landParcelId,
        harvestDate: !selectedEvent?.reminderId
          ? dayjs(harvestData.harvestDate)
          : undefined,
        preEstimatePerHectare: harvestData.preEstimatePerHectare,
        preEstimateTotal: harvestData.preEstimateTotal,
        finalDeliverableQuantity: harvestData.finalDeliverableQuantity,
        difference: harvestData.difference,
        preEstimatedDate: dayjs(harvestData.preEstimatedDate),
        harvestMethodId: harvestData.harvestMethod,
      });
    }
  }, [harvestData]);

  useEffect(() => {
    if (selectedCultivation) {
      form.setFieldsValue({
        cultivation: selectedCultivation?.cultivation,
        landParcelId: selectedCultivation?.id,
        harvestDate: selectedDate?.startOf('day'),
      });
    }
  }, [selectedCultivation]);
  const { data: harvestMethodItems, isLoading } = useQuery({
    queryKey: ['harvestMethodItems'],
    queryFn: () => getData(request, endPoints.CALENDAR.HARVEST),
    select(data) {
      return data.data.map((item: NamedItem) => ({
        value: item.id,
        label: item.name,
      }));
    },
  });
  const { mutate, isPending } = useMutation({
    mutationFn: (formValues: HarvestEvent & { reminderDate: number }) =>
      mutateData(
        request,
        endPoints.CALENDAR.LANDPARCELHARVEST,
        formValues,
        apiMethod,
      ),
    onSuccess(data, variables) {
      message.success(successMessage);
      setIsModalOpen(false);
      setActivityMutation(!activityMutation);
      variables?.reminderDate &&
        mutateReminder({
          eventId: data.data,
          eventType: endPoints.CALENDAR.LANDPARCELHARVEST,
          reminderDate: variables.reminderDate,
        });
    },
  });

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    const formattedValues = {
      ...values,
      preEstimatedDate: dayjs(values.preEstimatedDate?.$d)
        .startOf('day')
        .valueOf(),
      harvestDate: dayjs(values.harvestDate?.$d).startOf('day').valueOf(),
      id: selectedEvent?.id,
      harvestMethodId: harvestMethodId,
      reminderDate: values?.reminderDate?.$d.getTime(),
    };
    mutate(formattedValues);
  };
  if (isLoading || isPending) return <LoadingSpin />;
  return (
    <>
      <Form
        {...baseFormLayout}
        layout="vertical"
        size="large"
        form={form}
        onFinish={onFinish}
      >
        <CommonFormItems />
        <Row>
          <Col xs={12}>
            <Form.Item
              name="preEstimatedDate"
              label="Ημ/νια Προεκτίμησης"
              rules={[requiredRuleConfig]}
            >
              <DatePicker
                format={dateFormat}
                placeholder="Ημ/νια Προεκτίμησης"
                style={fullWidth}
                disabled={viewOnly}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="preEstimatePerHectare"
              label="Προεκτίμηση ανά Εκτάριο (kg/ha)"
              rules={[requiredRuleConfig]}
            >
              <InputNumber
                type="number"
                style={fullWidth}
                step={1}
                min={0}
                placeholder="Προεκτίμηση ανά Εκτάριο (kg/ha)"
                disabled={viewOnly}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              name="preEstimateTotal"
              label="Προεκτίμηση Ολική (kg)"
              rules={[requiredRuleConfig]}
            >
              <InputNumber
                type="number"
                style={fullWidth}
                step={1}
                min={0}
                placeholder="Προεκτίμηση Ολική (kg)"
                disabled={viewOnly}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="harvestDate"
              label="Ημ/νια Συγκομιδής"
              rules={[requiredRuleConfig]}
            >
              <DatePicker
                format={dateFormat}
                placeholder="Ημ/νια Συγκομιδής"
                style={fullWidth}
                disabled={viewOnly}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              name="harvestMethodId"
              label="Τρόπος Συγκομιδής"
              rules={[requiredRuleConfig]}
            >
              <Select
                allowClear
                options={harvestMethodItems}
                placeholder="Τρόπος Συγκομιδής"
                disabled={viewOnly}
                onSelect={(value) => {
                  setHarvestMethodId(value);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="difference"
              label="Διαφορά (%)"
              rules={[requiredRuleConfig]}
            >
              <InputNumber
                type="number"
                style={fullWidth}
                step={1}
                min={0}
                max={100}
                placeholder="Διαφορά (%)"
                disabled={viewOnly}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              name="finalDeliverableQuantity"
              label="Τελική Παραδοτέα Ποσότητα (kg)"
              rules={[requiredRuleConfig]}
            >
              <InputNumber
                type="number"
                style={fullWidth}
                step={1}
                min={0}
                placeholder="Τελική Παραδοτέα Ποσότητα (kg)"
                disabled={viewOnly}
              />
            </Form.Item>
          </Col>
        </Row>
        {!viewOnly && harvestData && (
          <EventReminder
            reminderDate={apiMethod == 'put' ? harvestData?.reminderDate : null}
          />
        )}
        {apiMethod == 'post' && (
          <Row justify="center">
            <Button htmlType="submit">Προσθήκη</Button>
          </Row>
        )}
        {!viewOnly && apiMethod == 'put' && (
          <Row justify="center">
            <Button htmlType="submit">Αποθήκευση</Button>
          </Row>
        )}
      </Form>
    </>
  );
};

export default HarvestForm;
