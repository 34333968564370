import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  DatePicker,
  FormProps,
  App,
  Select,
} from 'antd';
import { FC, useEffect, useContext } from 'react';
import { AxiosError, Method } from 'axios';
import { useMutation } from '@tanstack/react-query';
import {
  baseStyle,
  baseFormLayout,
  cardStyle,
} from '../../../shared/styles/baseStyle';
import LoadingSpin from '../../LoadingSpin/LoadingSpin';
import { AuthContext } from '../../../shared/context/auth-context';
import useCreateAxiosInstance from '../../../shared/hooks/useCreateAxiosInstance';
import endPoints from '../../../shared/endPoints.json';
import { mutateData } from '../../../shared/services/apiServices';
import {
  AgriculturalOutflowFormValues,
  AgriculturalOutflowDetails,
} from '../../../types/types';
import { EAESeasonContext } from '../../../shared/context/EAESeason';
import {
  messagesFromApi,
  dateEpochToGR,
} from '../../../shared/services/helperFunctions';
import dayjs from 'dayjs';
import { EAESeasonItems } from '../../../types/types';

type Props = {
  selectedAgriculturalOutflow: AgriculturalOutflowDetails | null;
  apiMethod: Method;
  successTitle?: string;
  setIsEditCatalogueModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const AgriculturalOutflowForm: FC<Props> = ({
  selectedAgriculturalOutflow,
  apiMethod,
  successTitle,
  setIsEditCatalogueModalOpen,
}) => {
  const { userId: userIdFromToken } = useContext(AuthContext);
  const requiredRuleConfig = {
    required: true,
    message: 'Υποχρεωτικό πεδίο',
  };
  const numberRegex = new RegExp(/^\d+$/);

  const requiredRuleNumber = {
    required: true,
    pattern: numberRegex,
    message: 'Μόνο αριθμοί',
  };

  const dateFormat = 'DD/MM/YYYY';
  const [form] = Form.useForm();
  const { request } = useCreateAxiosInstance();
  const { message } = App.useApp();
  const { eaeSeason } = useContext(EAESeasonContext);
  if (!eaeSeason) return <></>;
  const eaeSeasonItems = eaeSeason?.map((item: EAESeasonItems) => ({
    value: item.id,
    label: `${item.range}, ΕΑΕ: ${item.eae}`,
    start: item.start,
    end: item.end,
    id: item.id,
  }));

  useEffect(() => {
    form.setFieldsValue({
      userId: selectedAgriculturalOutflow?.userId,
      invoiceNumber: selectedAgriculturalOutflow?.invoiceNumber,
      invoiceDate: selectedAgriculturalOutflow?.invoiceDate
        ? dayjs(
            dateEpochToGR(selectedAgriculturalOutflow.invoiceDate),
            dateFormat,
          )
        : null,
      buyerName: selectedAgriculturalOutflow?.buyerName,
      address: selectedAgriculturalOutflow?.address,
      productType: selectedAgriculturalOutflow?.productType,
      tradeName: selectedAgriculturalOutflow?.tradeName,
      quantity: selectedAgriculturalOutflow?.quantity,
      eaeSeasonId: selectedAgriculturalOutflow?.eaeSeasonId,
    });
  }, [selectedAgriculturalOutflow]);

  const { mutate, isPending } = useMutation({
    mutationFn: (formValues: any) =>
      mutateData(
        request,
        endPoints.AGRICULTURAL.AGRICULTURALOUTFLOW,
        formValues,
        apiMethod,
      ),
    onSuccess() {
      message.success(successTitle);
      setIsEditCatalogueModalOpen(false);
      form.resetFields();
    },
    onError(error: AxiosError) {
      if (error.status === 400) {
        messagesFromApi(error.response?.data)?.forEach((errorMessage: any) => {
          message.error(errorMessage);
        });
      }
    },
  });

  const onFinish: FormProps['onFinish'] = async (
    values: AgriculturalOutflowFormValues,
  ) => {
    const newValues: AgriculturalOutflowFormValues = {
      ...values,
      invoiceDate: values.invoiceDate?.$d.getTime(),
      userId: Number(userIdFromToken),
      id: selectedAgriculturalOutflow?.id,
    };
    mutate(newValues);
  };

  if (isPending) return <LoadingSpin />;
  return (
    <>
      <Row justify="center" style={baseStyle.mainRow}>
        <Col span={24}>
          <Card
            title={
              apiMethod === 'post' ? 'Προσθήκη Εκροής' : 'Επεξεργασία Εκροής'
            }
            style={cardStyle}
          >
            <Row>
              <Col span={24}>
                <Form
                  {...baseFormLayout}
                  name="basic"
                  layout="vertical"
                  size="middle"
                  form={form}
                  onFinish={onFinish}
                >
                  <Row>
                    <Col xs={12} sm={12} lg={6} xl={6}>
                      <Form.Item
                        name="invoiceNumber"
                        label="Αριθμός Παραστατικού"
                        rules={[requiredRuleNumber]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={6}>
                      <Form.Item
                        name="invoiceDate"
                        label="Ημερομηνία Παραστατικού"
                        rules={[requiredRuleConfig]}
                      >
                        <DatePicker
                          format={dateFormat}
                          placeholder="Ημερομηνία Παραστατικού"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={6} xl={6}>
                      <Form.Item
                        name="buyerName"
                        label="Επωνυμία Αγοραστή"
                        rules={[requiredRuleConfig]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={6} xl={6}>
                      <Form.Item
                        name="address"
                        label="Διεύθυνση"
                        rules={[requiredRuleConfig]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={6} xl={6}>
                      <Form.Item
                        name="productType"
                        label="Είδος Προιόντος"
                        rules={[requiredRuleConfig]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={6} xl={6}>
                      <Form.Item
                        name="tradeName"
                        label="Εμπορικό Όνομα/Ποικιλία"
                        rules={[requiredRuleConfig]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={6} xl={6}>
                      <Form.Item
                        name="quantity"
                        label="Ποσότητα"
                        rules={[requiredRuleConfig]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={6} xl={6}>
                      <Form.Item
                        name="eaeSeasonId"
                        label="Καλλιεργητική Περίοδος"
                      >
                        <Select options={eaeSeasonItems} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Button htmlType="submit" type="primary">
                      Αποθήκευση
                    </Button>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AgriculturalOutflowForm;
