import { jwtDecode, JwtPayload } from 'jwt-decode';
import { FC, createContext, useCallback, useMemo, useState } from 'react';

type Props = {
  children: any;
};
interface ExtendedJwtPayload extends JwtPayload {
  role: string;
  unique_name: string;
  UserId: string;
  FirstName: string;
  LastName: string;
  Subscription: string;
  UserOrigin: string;
}
interface IAuthContext {
  uniqueName: string | null;
  role: string | null;
  userId: string | null;
  firstName: string | null;
  lastName: string | null;
  token: string | null;
  refreshToken: string | null;
  subscription: string | null;
  userOrigin: string | null;
  login: (tokens: any) => void;
  impersonateUser: (tokens: any) => void;
  logout: () => void;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
  setRefreshToken: React.Dispatch<React.SetStateAction<string | null>>;
}

export const AuthContext = createContext<IAuthContext>({
  uniqueName: '',
  role: '',
  userId: '',
  token: '',
  refreshToken: '',
  firstName: '',
  lastName: '',
  subscription: '',
  userOrigin: '',
  login: () => {
    // intentionally empty
  },
  logout: () => {
    // intentionally empty
  },
  impersonateUser: () => {
    // intentionally empty
  },
  setToken: () => {
    // intentionally empty
  },
  setRefreshToken: () => {
    // intentionally empty
  },
});

const AuthContextProvider: FC<Props> = ({ children }) => {
  const [role, setRole] = useState(
    sessionStorage.getItem('role') || localStorage.getItem('role'),
  );
  const [userOrigin, setUserOrigin] = useState(
    sessionStorage.getItem('userOrigin') || localStorage.getItem('userOrigin'),
  );
  const [subscription, setSubscription] = useState(
    sessionStorage.getItem('subscription') ||
      localStorage.getItem('subscription'),
  );
  const [userId, setUserId] = useState(
    sessionStorage.getItem('userOrigin') || localStorage.getItem('userId'),
  );
  const [token, setToken] = useState(
    sessionStorage.getItem('accessToken') ||
      localStorage.getItem('accessToken'),
  );
  const [refreshToken, setRefreshToken] = useState(
    sessionStorage.getItem('refreshToken') ||
      localStorage.getItem('refreshToken'),
  );
  const [uniqueName, setUniqueName] = useState(
    sessionStorage.getItem('uniqueName') || localStorage.getItem('uniqueName'),
  );
  const [firstName, setFirstName] = useState(
    sessionStorage.getItem('firstName') || localStorage.getItem('firstName'),
  );
  const [lastName, setLastName] = useState(
    sessionStorage.getItem('lastName') || localStorage.getItem('lastName'),
  );

  const updateStorageAndState = (tokens: any, storage: Storage) => {
    const decodedItem = jwtDecode<ExtendedJwtPayload>(tokens.accessToken);
    const userDetails = {
      accessToken: tokens.accessToken,
      refreshToken: tokens.refreshToken,
      role: decodedItem.role,
      userId: decodedItem.UserId,
      uniqueName: decodedItem.unique_name,
      firstName: decodedItem.FirstName,
      lastName: decodedItem.LastName,
      subscription: decodedItem.Subscription,
      userOrigin: decodedItem.UserOrigin,
    };
    Object.entries(userDetails).forEach(([key, value]) =>
      storage.setItem(key, value),
    );

    setToken(tokens.accessToken);
    setRefreshToken(tokens.refreshToken);
    setRole(decodedItem.role);
    setUniqueName(decodedItem.unique_name);
    setUserId(decodedItem.UserId);
    setFirstName(decodedItem.FirstName);
    setLastName(decodedItem.LastName);
    setSubscription(decodedItem.Subscription);
    setUserOrigin(decodedItem.UserOrigin);
  };
  const login = useCallback((tokens: any) => {
    updateStorageAndState(tokens, localStorage);
  }, []);
  const impersonateUser = useCallback((tokens: any) => {
    updateStorageAndState(tokens, sessionStorage);
  }, []);
  const clearStorage = (storage: Storage) => {
    [
      'accessToken',
      'refreshToken',
      'role',
      'userId',
      'uniqueName',
      'firstName',
      'lastName',
      'subscription',
      'userOrigin',
      'opekepeApplicationYear',
    ].forEach((key) => storage.removeItem(key));
  };
  const logout = () => {
    if (sessionStorage.length === 0) {
      clearStorage(localStorage);
    }
    clearStorage(sessionStorage);
    sessionStorage.removeItem('impersonatedUserId');
    setToken(null);
    setRefreshToken(null);
    setRole(null);
    setUniqueName(null);
    setUserId(null);
    setFirstName(null);
    setLastName(null);
    setSubscription(null);
    setUserOrigin(null);
  };

  const memoedValue = useMemo(
    () => ({
      uniqueName,
      role,
      token,
      refreshToken,
      userId,
      firstName,
      lastName,
      subscription,
      userOrigin,
      login,
      logout,
      impersonateUser,
      setToken,
      setRefreshToken,
    }),
    [
      uniqueName,
      role,
      token,
      refreshToken,
      userId,
      firstName,
      lastName,
      subscription,
      userOrigin,
      login,
      logout,
      impersonateUser,
      setToken,
      setRefreshToken,
    ],
  );

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
};
export default AuthContextProvider;
