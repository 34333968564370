import { FC, useState } from 'react';
import { Card, Row, Space, Col, Button, Flex } from 'antd';
import {
  EyeOutlined,
  EditOutlined,
  AppstoreAddOutlined,
} from '@ant-design/icons';
import BaseModal from '../../components/BaseModal/BaseModal';
import BaseTable from '../../components/BaseTable/BaseTable';
import {
  baseStyle,
  cardStyle,
  addCatalogueButton,
} from '../../shared/styles/baseStyle';
import CultivationsViewList from '../../components/List/CultivationsViewList';
import endPoints from '../../shared/endPoints.json';
import { formatDate } from '../../shared/services/helperFunctions';
import { dateEpochToGR } from '../../shared/services/helperFunctions';
import EditCultivation from './EditCultivation';
import { LandParcel } from '../../types/types';
import { useQuery } from '@tanstack/react-query';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import { getData } from '../../shared/services/apiServices';
import LoadingSpin from '../../components/LoadingSpin/LoadingSpin';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

const CultivationCatalogue: FC = () => {
  const navigate = useNavigate();
  const dateFormat = 'DD/MM/YYYY';
  const { request } = useCreateAxiosInstance();
  const [isViewCatalogueModalOpen, setIsViewCatalogueModalOpen] =
    useState(false);
  const [isEditCatalogueModalOpen, setIsEditCatalogueModalOpen] =
    useState(false);
  const defaultCurrentPage = 1;
  const pageSize = 10;
  const defaultUrl = `${endPoints.CULTIVATION.LANDPARCEL}?Items=${pageSize}&Page=${defaultCurrentPage}`;
  const [url, setUrl] = useState(defaultUrl);
  const [selectedEditCultivationId, setSelectedEditCultivationId] =
    useState<number>();
  const [selectedViewCultivationId, setSelectedViewCultivationId] =
    useState<number>();
  const [editClick, setEditClick] = useState(false);
  const [viewClick, setViewClick] = useState(false);
  const formattedCultivationDetails = (cultivationDetails: any) => {
    if (!cultivationDetails) return;
    return {
      ...cultivationDetails,
      from: dayjs(dateEpochToGR(cultivationDetails.from), dateFormat),
      harvestingDate: cultivationDetails.harvestingDate
        ? dayjs(dateEpochToGR(cultivationDetails.harvestingDate), dateFormat)
        : null,
      plantingDate: dayjs(
        dateEpochToGR(cultivationDetails.plantingDate),
        dateFormat,
      ),
      to: cultivationDetails.to
        ? dayjs(dateEpochToGR(cultivationDetails.to), dateFormat)
        : null,
    };
  };
  const { data: cultivations, isLoading } = useQuery({
    queryKey: ['cultivations', url, isEditCatalogueModalOpen],
    queryFn: () => getData(request, url),
    select(data) {
      return data.data;
    },
    enabled: isEditCatalogueModalOpen == false,
  });
  const { data: selectedCultivationForView, isLoading: isViewLoading } =
    useQuery({
      queryKey: ['selectedCultivationForView', viewClick],
      queryFn: () =>
        getData(
          request,
          `${endPoints.CULTIVATION.LANDPARCEL}/${selectedViewCultivationId}`,
        ),
      enabled: !!selectedViewCultivationId,
      select(data) {
        return data.data;
      },
    });
  const { data: selectedCultivationForEdit, isLoading: isEditLoading } =
    useQuery({
      queryKey: ['selectedCultivationForEdit', editClick],
      queryFn: () =>
        getData(
          request,
          `${endPoints.CULTIVATION.LANDPARCEL}/${selectedEditCultivationId}`,
        ),
      enabled: !!selectedEditCultivationId,
      select(data) {
        return data.data;
      },
    });
  const onChange = (pagination: any) => {
    setUrl(
      `${endPoints.CULTIVATION.LANDPARCEL}?Items=${pageSize}&Page=${pagination.current}`,
    );
  };
  const handleEyeClick = async (record: LandParcel) => {
    setSelectedViewCultivationId(record.id);
    setIsViewCatalogueModalOpen(true);
    setViewClick(!viewClick);
  };
  const handleEditClick = async (record: LandParcel) => {
    setSelectedEditCultivationId(record.id);
    setIsEditCatalogueModalOpen(true);
    setEditClick(!editClick);
  };
  const columns = [
    {
      title: '',
      key: 'action',
      render: (_: any, record: LandParcel) => (
        <Space size="middle">
          <EditOutlined onClick={() => handleEditClick(record)} />
          <EyeOutlined onClick={() => handleEyeClick(record)} />
        </Space>
      ),
    },
    {
      title: 'Όνομασία',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Καλλιέργεια',
      dataIndex: 'cultivation',
      key: 'cultivation',
    },
    {
      title: 'Ποικιλία',
      dataIndex: 'variety',
      key: 'variety',
    },
    {
      title: 'Φάση Καλλιέργειας',
      dataIndex: 'cultivationPhase',
      key: 'cultivationPhase',
    },
    {
      title: 'Από',
      dataIndex: 'from',
      key: 'from',
    },
    {
      title: 'Έως',
      dataIndex: 'to',
      key: 'to',
    },
    {
      title: 'Τοπωνύμιο',
      dataIndex: 'placeName',
      key: 'placeName',
    },
    {
      title: 'Περιφερειακή Ενότητα',
      dataIndex: 'region',
      key: 'region',
    },
    {
      title: 'Δήμος',
      dataIndex: 'municipality',
      key: 'municipality',
    },
    {
      title: 'Περιοχή',
      dataIndex: 'district',
      key: 'district',
    },
  ];
  return (
    <Row justify="center" style={baseStyle.mainRow}>
      <Col span={24}>
        <Flex gap="middle" vertical>
          <Button
            onClick={() => {
              navigate('/cultivations/add');
            }}
            style={addCatalogueButton}
            type="primary"
            size="large"
            icon={<AppstoreAddOutlined />}
          >
            Προσθήκη
          </Button>
          <Card
            style={cardStyle}
            title="Αγροτεμάχια"
            loading={isViewLoading || isEditLoading}
          >
            <BaseTable
              dataSource={formatDate(cultivations?.items)}
              columns={columns}
              loading={isLoading}
              paginationConfig={{
                pageSize: pageSize,
                total: cultivations?.totalCount,
              }}
              onChange={onChange}
              rowSelection={null}
            />
            <BaseModal
              modalTitle=""
              modalWidth={'85%'}
              isModalOpen={isViewCatalogueModalOpen}
              setIsModalOpen={setIsViewCatalogueModalOpen}
            >
              <CultivationsViewList
                selectedCultivation={{
                  ...formattedCultivationDetails(selectedCultivationForView),
                  id: selectedViewCultivationId,
                }}
              />
            </BaseModal>
            <BaseModal
              modalTitle=""
              modalWidth={'85%'}
              isModalOpen={isEditCatalogueModalOpen}
              setIsModalOpen={setIsEditCatalogueModalOpen}
            >
              <EditCultivation
                selectedCultivation={{
                  ...formattedCultivationDetails(selectedCultivationForEdit),
                  id: selectedEditCultivationId,
                }}
                setIsEditCatalogueModalOpen={setIsEditCatalogueModalOpen}
              ></EditCultivation>
            </BaseModal>
          </Card>
        </Flex>
      </Col>
    </Row>
  );
};
export default CultivationCatalogue;
