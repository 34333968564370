import { Card, Col, Row, Flex } from 'antd';
import { FC, useState } from 'react';
import FertilizerForm from '../../components/Forms/Calendar/FertilizerForm';
import IrrigationForm from '../../components/Forms/Calendar/IrrigationForm';
import HarvestForm from '../../components/Forms/Calendar/HarvestForm';
import PlantProtectionForm from '../../components/Forms/Calendar/PlantProtectionForm';
import VisitsForm from '../../components/Forms/Calendar/VisitsForm';
import QualityCharacteristicsForm from '../../components/Forms/Calendar/QualityCharacteristicsForm';
import CultivationCareForm from '../../components/Forms/Calendar/CultivationCareForm';
import { marginRow, cardStyle } from '../../shared/styles/baseStyle';
import { CalendarEventContent, LandParcel } from '../../types/types';
import SelectActivity from '../../components/SelectActivity/SelectActivity';
import type { Dayjs } from 'dayjs';
import { Method } from 'axios';
import { getEAESeasonDisabledDates } from '../../shared/services/helperFunctions';

type Props = {
  selectedDate: Dayjs | undefined;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setActivityMutation: React.Dispatch<React.SetStateAction<boolean>>;
  activityMutation: boolean;
  selectedEaeSeason: any;
};
const AddEvent: FC<Props> = ({
  activityMutation,
  setActivityMutation,
  setIsModalOpen,
  selectedDate,
  selectedEaeSeason,
}) => {
  const [selectedActivityForForm, setSelectedActivityForForm] =
    useState<string>('');
  const [selectedCultivation, setSelectedCultivation] = useState<LandParcel>();
  const disabledDate = getEAESeasonDisabledDates(selectedEaeSeason);

  const commonProps = (): {
    selectedCultivation: LandParcel | undefined;
    setActivityMutation: React.Dispatch<React.SetStateAction<boolean>>;
    activityMutation: boolean;
    selectedDate: Dayjs | undefined;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    selectedEvent: CalendarEventContent | undefined;
    successMessage: string;
    apiMethod: Method;
    viewOnly: boolean;
    disabledDate: any;
  } => {
    return {
      selectedCultivation,
      setActivityMutation,
      activityMutation,
      selectedDate,
      setIsModalOpen,
      selectedEvent: undefined,
      apiMethod: 'post',
      successMessage: 'Η ενέργεια προστέθηκε με επιτυχία!',
      viewOnly: false,
      disabledDate,
    };
  };
  const calendarActivityForms: { [key: string]: JSX.Element } = {
    Λίπανση: <FertilizerForm {...commonProps()} />,
    ΚαλλιεργητικέςΦροντίδες: <CultivationCareForm {...commonProps()} />,
    Άρδευση: <IrrigationForm {...commonProps()} />,
    Συγκομιδή: <HarvestForm {...commonProps()} />,
    Φυτοπροστασία: <PlantProtectionForm {...commonProps()} />,
    Επισκέψεις: <VisitsForm {...commonProps()} />,
    ΠοιοτικάΧαρακτηριστικά: <QualityCharacteristicsForm {...commonProps()} />,
  };

  return (
    <Flex gap="small" vertical>
      <Row gutter={[8, 8]} justify="center" style={{ ...marginRow }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={22} xxl={20}>
          <Card
            style={{ ...cardStyle }}
            title="Προσθήκη Δραστηριότητας / Ενέργειας"
          >
            <SelectActivity
              selectedCultivation={selectedCultivation}
              setSelectedCultivation={setSelectedCultivation}
              setSelectedActivity={setSelectedActivityForForm}
              selectedEaeSeason={selectedEaeSeason}
            >
              {selectedActivityForForm &&
                calendarActivityForms[
                  selectedActivityForForm.replace(/\s+/g, '')
                ]}
            </SelectActivity>
          </Card>
        </Col>
      </Row>
    </Flex>
  );
};
export default AddEvent;
