import { FC, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getData } from '../../shared/services/apiServices';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
type Props = {
  url: string;
  fileName: string | null;
  fileType: 'pdf' | 'excel';
  children?: React.ReactNode;
};
const DownloadFile: FC<Props> = ({ url, fileName, fileType, children }) => {
  const blobType =
    fileType === 'pdf'
      ? 'application/pdf'
      : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const { request } = useCreateAxiosInstance();

  const { data, refetch } = useQuery({
    queryKey: [fileType],
    queryFn: async () => {
      const response = await getData(request, url, 'blob');
      const fileNameFromHeader =
        response.headers?.['content-disposition'].split("filename*=UTF-8''")[1];

      const extractedFilename =
        fileNameFromHeader && decodeURIComponent(fileNameFromHeader);

      return {
        fileBlob: response.data,
        downloadedFilename: fileName || extractedFilename,
      };
    },
    enabled: false,
  });
  useEffect(() => {
    if (data) {
      const { fileBlob, downloadedFilename } = data;
      const blob = new Blob([fileBlob], { type: blobType });
      const url = window.URL.createObjectURL(blob);
      const tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.setAttribute('download', `${downloadedFilename}`);
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    }
  }, [data]);
  return (
    <div
      onClick={() => {
        refetch();
      }}
    >
      {children}
    </div>
  );
};

export default DownloadFile;
