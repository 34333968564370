import { FC, useEffect, useRef } from 'react';
import {
  Typography,
  Row,
  Col,
  Steps,
  Button,
  Form,
  Input,
  ConfigProvider,
  Image,
  App,
} from 'antd';
import { motion } from 'framer-motion';
import { leftTransition, rightTransition } from './styles';
import { baseColors } from '../../shared/styles/baseStyle';
import logo from '../../assets/Color logo - no background.png';
import { useLocation } from 'react-router-dom';
import endPoints from '../../shared/endPoints.json';
import { mutateData } from '../../shared/services/apiServices';
import { useMutation } from '@tanstack/react-query';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';

const ContactForm: FC = () => {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const { Text } = Typography;
  const contactRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const lastHash = useRef('');

  const { request } = useCreateAxiosInstance();
  const { mutate } = useMutation({
    mutationFn: (formValues) =>
      mutateData(
        request,
        endPoints.COMMUNICATION.COMMUNICATION,
        formValues,
        'post',
      ),
    onSuccess() {
      message.success('Το μήνυμά σας καταχωρήθηκε με επιτυχία!');
      form.resetFields();
    },
  });

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1);
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        lastHash.current = '';
      }, 100);
    }
  }, [location]);
  const stepsItems = [
    {
      title: (
        <Text
          strong
          style={{
            fontSize: 20,
            color: baseColors.fsBlue,
          }}
        >
          Phone Number
        </Text>
      ),
      description: '+30 2410669107',
    },
    {
      title: (
        <Text
          strong
          style={{
            fontSize: 20,
            color: baseColors.fsBlue,
          }}
        >
          E-mail Address
        </Text>
      ),
      description: 'info@agros-online.gr',
    },
    {
      title: (
        <Text
          strong
          style={{
            fontSize: 20,
            color: baseColors.fsBlue,
          }}
        >
          Official Website
        </Text>
      ),
      description: 'agros-online.gr',
    },
  ];
  const onFinish = (values: any) => {
    mutate(values);
  };
  return (
    <>
      <Row
        style={{
          minHeight: '50vh',
        }}
        justify="center"
        align="top"
        id="contactUs"
        ref={contactRef}
        gutter={[20, 30]}
      >
        <Col xs={23} sm={23} md={12} lg={10} xl={{ span: 6, pull: 2 }}>
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={leftTransition}
            viewport={{ once: false, amount: 0.5 }}
          >
            <Row align="middle" justify="center">
              <Image
                loading="lazy"
                style={{
                  cursor: 'pointer',
                }}
                preview={false}
                src={logo}
                width="180px"
                height="auto"
              />
            </Row>
            <Typography.Paragraph
              style={{
                fontSize: 39,
                color: baseColors.fsBlue,
                fontWeight: 'bold',
              }}
            >
              Επικοινωνήστε μαζί μας
            </Typography.Paragraph>

            <Row>
              <Steps
                progressDot
                current={3}
                direction="vertical"
                items={stepsItems}
              />
            </Row>
          </motion.div>
        </Col>
        <Col
          xs={23}
          sm={23}
          md={12}
          lg={{ span: 10, push: 1 }}
          xl={{ span: 6, push: 1 }}
        >
          <motion.div
            initial="hidden"
            whileInView="visible"
            variants={rightTransition}
            viewport={{ once: false, amount: 0.5 }}
          >
            <ConfigProvider
              theme={{
                components: {
                  Form: {
                    labelColor: baseColors.fsBlue,
                  },
                },
              }}
            >
              <Form layout="vertical" onFinish={onFinish} form={form}>
                <Form.Item
                  label="ΟΝΟΜΑΤΕΠΩΝΥΜΟ"
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="EMAIL"
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Παρακαλώ εισάγετε έγκυρο email',
                    },
                  ]}
                >
                  <Input placeholder="Insert Your E-mail Address" />
                </Form.Item>
                <Form.Item
                  label="ΤΗΛΕΦΩΝΟ"
                  name="phone"
                  rules={[
                    { required: true },
                    {
                      pattern: /^\d{10}$/,
                      message: 'Παρακαλώ εισάγετε έγκυρο τηλέφωνο',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="ΕΠΑΓΓΕΛΜΑ" name="profession">
                  <Input />
                </Form.Item>
                <Form.Item label="ΠΟΛΗ - ΠΕΡΙΟΧΗ - ΤΚ" name="location">
                  <Input />
                </Form.Item>
                <Form.Item label="ΠΑΚΕΤΟ ΣΥΝΔΡΟΜΗΣ" name="subscriptionPackage">
                  <Input />
                </Form.Item>
                <Form.Item label="ΠΕΡΙΓΡΑΦΗ" name="description">
                  <Input placeholder="Insert Your Message" />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 24 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: '100%',
                      borderRadius: 0,
                    }}
                  >
                    Submit Message
                  </Button>
                </Form.Item>
              </Form>
            </ConfigProvider>
          </motion.div>
        </Col>
      </Row>
    </>
  );
};

export default ContactForm;
