/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import L from 'leaflet';
import 'leaflet.fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css';
import {
  MapContainer,
  TileLayer,
  useMap,
  Marker,
  Polygon,
  Tooltip,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import fieldIcon from '../../assets/field.png';
import DrawTools from './DrawTools';
import LayerControl from './LayerControl';
import {
  ComponentResize,
  InitilizeMap,
  CoordinatesOnMouse,
  FullscreenControl,
  ResetZoom,
} from './MapTools';

const customIcon = new L.Icon({
  iconUrl: fieldIcon,
  iconSize: [32, 40],
  iconAnchor: [16, 37],
  popupAnchor: [0, -30],
});

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const MapView = ({
  height,
  coordinates,
  setPolygon,
  showDrawControls,
  landParcelMutation,
  clearCoordinates,
  path,
  mapDate,
  cultivationsNames,
}) => {
  const [flyTo, setFlyTo] = useState(null);
  const [selectedPolygon, setSelectedPolygon] = useState([]);
  const center = [39.5, 22.0];
  const zoomLevel = 9;
  const maxZoom = 18;
  const minZoom = 2;

  const FlyToPosition = () => {
    const map = useMap();
    useEffect(() => {
      if (map && coordinates && flyTo) {
        map.setView([flyTo.lat, flyTo.lng], 16);
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 2500);
      }
    }, [flyTo]);
    return null;
  };

  function calculateBounds(polygons) {
    const bounds = L.latLngBounds();
    polygons?.forEach((polygon) => {
      polygon?.forEach((coord) => {
        bounds?.extend(coord);
      });
    });
    return bounds;
  }

  const PolygonCenter = ({ polygons }) => {
    const map = useMap();
    const overallBounds = calculateBounds(polygons);
    useEffect(() => {
      map.setView(overallBounds.getCenter(), map.getBoundsZoom(overallBounds));
    }, []);
    return null;
  };

  const Markers = () =>
    coordinates?.map((polygon, index) => {
      const center = L.latLngBounds(polygon).getCenter();
      return (
        <Marker
          position={center}
          key={index}
          icon={customIcon}
          eventHandlers={{
            click: () => {
              if (path === 'home') {
                setFlyTo(center);
                setSelectedPolygon(polygon);
              }
            },
          }}
        >
          <Tooltip offset={[0, -35]} direction="top" opacity={1} permanent>
            <div style={{ fontSize: 13, fontWeight: 'bold' }}>
              {cultivationsNames[index]}
            </div>
          </Tooltip>
        </Marker>
      );
    });

  return (
    <MapContainer
      attributionControl={false}
      maxZoom={maxZoom}
      minZoom={minZoom}
      center={center}
      zoom={zoomLevel}
      style={{
        height: height,
      }}
    >
      <TileLayer
        url="http://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
        subdomains={['mt1']}
      />
      <FullscreenControl />
      <InitilizeMap />
      <ComponentResize />
      <CoordinatesOnMouse />
      <ResetZoom setFlyTo={setFlyTo} setSelectedPolygon={setSelectedPolygon} />
      <DrawTools
        setPolygon={setPolygon}
        showDrawControls={showDrawControls}
        landParcelMutation={landParcelMutation}
        clearCoordinates={clearCoordinates}
      />
      {coordinates?.length > 0 && coordinates && (
        <PolygonCenter polygons={coordinates} />
      )}
      {coordinates && <Markers />}
      {coordinates && <FlyToPosition />}
      {coordinates && path !== 'home' && (
        <Polygon
          positions={coordinates}
          pathOptions={{
            color: 'green',
            weight: 5,
            fill: false,
            stroke: true,
          }}
        ></Polygon>
      )}
      {selectedPolygon && (
        <Polygon
          positions={selectedPolygon}
          pathOptions={{
            color: 'green',
            weight: 5,
            fill: false,
            stroke: true,
          }}
        ></Polygon>
      )}
      {path === 'home' && <LayerControl polygons={coordinates} />}
    </MapContainer>
  );
};

export default MapView;
