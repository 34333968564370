import { baseColors } from '../../shared/styles/baseStyle';

export const title = { marginBottom: 15, marginTop: 10 };
export const registerButton = { marginLeft: -10 };
export const formStyle = {
  marginTop: 10,
};
export const cardRow = {
  marginTop: 30,
  alignItems: 'center',
  justifyContent: 'space-between',
};
export const registerBackgroundColor = {
  backgroundColor: baseColors.fsGrey,
};
export const signUpRow = { marginTop: 40 };
export const whiteText = {
  color: 'white',
};
export const registerButtonRow = { marginTop: 30 };
export const forgotPasswordbutton = { fontSize: '1em', marginTop: -12 };
export const checkboxRow = { marginBottom: 5 };
export const checkboxLink = { fontSize: '1em', margin: -10 };
export const loginButton = { width: '30%' };
