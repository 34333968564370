import { useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import { useQuery } from '@tanstack/react-query';
import { getData } from '../../shared/services/apiServices';
import endPoints from '../../shared/endPoints.json';
import LoadingSpin from '../../components/LoadingSpin/LoadingSpin';
import { AuthContext } from '../../shared/context/auth-context';
import { App } from 'antd';

const AuthenticationCallback = () => {
  const { login } = useContext(AuthContext);
  const { request } = useCreateAxiosInstance();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');
  const error = searchParams.get('error');

  const { message } = App.useApp();
  const { data, error: authCodeError } = useQuery({
    queryKey: ['opekepeToken'],
    queryFn: () => getData(request, `${endPoints.USER.LOGIN}?authCode=${code}`),
    enabled: !!code,
  });

  useEffect(() => {
    if (data) {
      login(data.data);
    }
    if (error || authCodeError) {
      message.error('Τα στοιχεία εισόδου δεν αντιστοιχούν σε λογαριασμό');
      navigate('/login');
    }
  }, [authCodeError, data]);
  return <LoadingSpin />;
};
export default AuthenticationCallback;
