// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:where(
    .css-dev-only-do-not-override-12og7bu
  ).ant-menu-light.ant-menu-submenu-popup
  > .ant-menu,
:where(.css-dev-only-do-not-override-12og7bu).ant-menu-light
  > .ant-menu.ant-menu-submenu-popup
  > .ant-menu {
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: white;
  text-align: center;
}

:where(
    .css-dev-only-do-not-override-1p916zg
  ).ant-menu-light.ant-menu-submenu-popup
  > .ant-menu,
:where(.css-dev-only-do-not-override-1p916zg).ant-menu-light
  > .ant-menu.ant-menu-submenu-popup
  > .ant-menu {
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 200px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Info/Layout/Info.css"],"names":[],"mappings":"AAAA;;;;;;;EAOE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;;;;;;;EAOE,mBAAmB;EACnB,kBAAkB;EAClB,uBAAuB;EACvB,YAAY;AACd","sourcesContent":[":where(\n    .css-dev-only-do-not-override-12og7bu\n  ).ant-menu-light.ant-menu-submenu-popup\n  > .ant-menu,\n:where(.css-dev-only-do-not-override-12og7bu).ant-menu-light\n  > .ant-menu.ant-menu-submenu-popup\n  > .ant-menu {\n  display: grid;\n  justify-content: center;\n  align-items: center;\n  background-color: white;\n  text-align: center;\n}\n\n:where(\n    .css-dev-only-do-not-override-1p916zg\n  ).ant-menu-light.ant-menu-submenu-popup\n  > .ant-menu,\n:where(.css-dev-only-do-not-override-1p916zg).ant-menu-light\n  > .ant-menu.ant-menu-submenu-popup\n  > .ant-menu {\n  align-items: center;\n  text-align: center;\n  justify-content: center;\n  width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
