import { Row, Button, Form, Typography, message } from 'antd';
import { InputOTP } from 'antd-input-otp';
import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { mutateData } from '../../shared/services/apiServices';
import endPoints from '../../shared/endPoints.json';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import { useNavigate, useParams } from 'react-router-dom';
import LoginLayout from '../../components/LoginLayout/LoginLayout';
import { AuthContext } from '../../shared/context/auth-context';
import { helperText, resendButton, validateButton } from './style';
type OTP = {
  otp: string[];
};
type OTPValidationValues = {
  otp: string;
  userId: number;
  isImpersonatedBy: number;
};

const PhoneConfirmation = () => {
  const { login, impersonateUser } = useContext(AuthContext);
  const [disabledValidateOTP, setDisabledValidateOTP] = useState(true);
  const [usersPhoneNumber, setUsersPhoneNumber] = useState('');
  const [form] = Form.useForm();
  const { request } = useCreateAxiosInstance();
  const impersonatedUserId = sessionStorage.getItem('impersonatedUserId');
  const { userId } = useParams();
  const navigate = useNavigate();
  const userIdNumber = parseInt(userId || '');
  const { mutate: sendOtpMutate, isPending: isPendingSendOTP } = useMutation({
    mutationFn: () =>
      mutateData(
        request,
        endPoints.USER.SENDOTP,
        { userId: userIdNumber },
        'post',
      ),
    onSuccess(data) {
      setUsersPhoneNumber(data.data);
      message.info('Ο κωδικός είναι σε ισχύ για 5 λεπτα.');
    },
    onError(error: any) {
      if (
        error.status === 400 &&
        error?.response?.data.OtpEXpiration.includes('Το OTP δεν έχει λήξει')
      ) {
        message.error(
          'Ο κωδικός που έχει σταλεί στο κινητό σας, είναι ακόμα σε ισχύ.',
        );
        return;
      }
      message.error(
        'Κάτι πήγε στραβά. Παρακαλώ δοκιμάστε επαναποστολή κωδικού.',
      );
    },
  });
  const { mutate: validateOtpMutate, isPending: isPendingValidateOTP } =
    useMutation({
      mutationFn: (values: OTPValidationValues) =>
        mutateData(request, endPoints.USER.VALIDATEOTP, values, 'post'),
      onSuccess(data) {
        impersonatedUserId ? impersonateUser(data.data) : login(data.data);
        navigate('/', { replace: true });
      },
      onError(error: any) {
        if (
          error.status === 400 &&
          error?.response?.data?.OtpEXpiration?.includes('Το OTP έχει λήξει')
        ) {
          message.error(
            'Ο κωδικός OTP έχει λήξει. Παρακαλώ δοκιμάστε επαναποστολή κωδικου.',
          );
          return;
        }
        message.error(
          'Ο κωδικός OTP που εισάγατε είναι λανθασμένος. Παρακαλώ δοκιμάστε ξανά.',
        );
      },
    });
  const onFinish = (values: OTP) => {
    const transformedValues = {
      userId: userIdNumber,
      otp: values.otp?.join(''),
      isImpersonatedBy: parseInt(localStorage.getItem('userId') || ''),
    };
    validateOtpMutate(transformedValues);
  };

  useEffect(() => {
    sendOtpMutate();
  }, []);

  const handleOTPChange = (value: string[]) => {
    setDisabledValidateOTP(
      !(value.length === 6 && value.every((char) => char)),
    );
  };

  const handleResendOTP = () => sendOtpMutate();

  return (
    <LoginLayout loading={isPendingSendOTP || isPendingValidateOTP}>
      <>
        <Row justify="center">
          <Typography.Title level={3}>
            Απαιτείται Ταυτοποίηση Χρήστη
          </Typography.Title>
        </Row>
        <Row justify="center">
          <Typography.Text style={helperText}>
            Εισάγετε τον 6-ψήφιο κωδικό που λάβατε στo{' '}
            {usersPhoneNumber.replace(/.(?=.{4})/g, '*')}
          </Typography.Text>
        </Row>
        <Form onFinish={onFinish} form={form}>
          <Form.Item name="otp">
            <InputOTP
              inputType="numeric"
              length={6}
              onChange={handleOTPChange}
            />
          </Form.Item>
          <Form.Item>
            <Row justify="center">
              <Button
                type="primary"
                htmlType="submit"
                style={validateButton}
                disabled={disabledValidateOTP}
              >
                Ταυτοποίηση
              </Button>
            </Row>
          </Form.Item>
          <Row justify="center">
            <Button type="link" onClick={handleResendOTP} style={resendButton}>
              Επαναποστολή
            </Button>
          </Row>
        </Form>
      </>
    </LoginLayout>
  );
};
export default PhoneConfirmation;
