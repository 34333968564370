import { Card, Col, FormProps, Row, Space, Tag, Button, Flex } from 'antd';
import { FC, useContext, useEffect, useState } from 'react';
import { EyeOutlined, EditOutlined, UserAddOutlined } from '@ant-design/icons';
import EndPoints from '../../shared/endPoints.json';
import BaseTable from '../../components/BaseTable/BaseTable';
import { dateEpochToGR } from '../../shared/services/helperFunctions';
import {
  baseStyle,
  cardStyle,
  addCatalogueButton,
} from '../../shared/styles/baseStyle';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import { useQuery } from '@tanstack/react-query';
import { getData } from '../../shared/services/apiServices';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AddUser from './AddUser';
import BaseModal from '../../components/BaseModal/BaseModal';
import { User } from '../../types/types';
import endPoints from '../../shared/endPoints.json';
import { AuthContext } from '../../shared/context/auth-context';
const UsersCatalogue: FC = () => {
  const { role } = useContext(AuthContext);
  const { request } = useCreateAxiosInstance();
  const defaultCurrentPage = 1;
  const pageSize = 6;
  const defaultUrl = `${EndPoints.USER.USER}?Items=${pageSize}&Page=${defaultCurrentPage}`;
  const [url, setUrl] = useState(defaultUrl);
  const [isViewCatalogueModalOpen, setIsViewCatalogueModalOpen] =
    useState(false);
  const [isEditCatalogueModalOpen, setIsEditCatalogueModalOpen] =
    useState(false);
  const [selectedViewUser, setSelectedViewUser] = useState<User>();
  const [selectedEditUser, setSelectedEditUser] = useState<User>();
  const [searchParams] = useSearchParams();
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const navigate = useNavigate();
  const handleEditClick = async (record: any) => {
    setSelectedEditUser(record);
    setIsEditCatalogueModalOpen(true);
  };
  const handleEyeClick = async (record: any) => {
    setSelectedViewUser(record);
    setIsViewCatalogueModalOpen(true);
  };

  const columns = [
    {
      title: '',
      key: 'action',
      render: (_: any, record: any) => (
        <Space size="middle">
          {role !== 'Γεωπόνος' ? (
            <>
              <EditOutlined onClick={() => handleEditClick(record)} />
              <EyeOutlined onClick={() => handleEyeClick(record)} />
            </>
          ) : (
            <Button type="primary" shape="round">
              Προσομοίωση
            </Button>
          )}
        </Space>
      ),
    },
    {
      title: 'Όνομα',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Επίθετο',
      dataIndex: 'lastName',
      key: 'lastName',
    },

    {
      title: 'Username (e-mail)',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'ΑΦΜ',
      dataIndex: 'vatNumber',
      key: 'vatNumber',
    },
    {
      title: 'Τηλέφωνο',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Οδός',
      dataIndex: 'addressName',
      key: 'addressName',
    },
    {
      title: 'Αριθμός',
      dataIndex: 'addressNumber',
      key: 'addressNumber',
    },
    {
      title: 'Περιοχή',
      dataIndex: 'district',
      key: 'district',
    },
    {
      title: 'Δήμος',
      dataIndex: 'municipality',
      key: 'municipality',
    },
    {
      title: 'Τ.Κ',
      dataIndex: 'postalCode',
      key: 'postalCode',
    },
    {
      title: 'Επιλογή Πακέτου',
      dataIndex: 'subscriptionPackage',
      key: 'subscriptionPackage',
    },
    {
      title: 'Έναρξη Συνδρομής',
      dataIndex: 'subscriptionDateStarted',
      key: 'subscriptionDateStarted',
      render: (epochTime: string) => dateEpochToGR(+epochTime),
    },
    {
      title: 'Προέλευση ',
      dataIndex: 'userOrigin',
      key: 'userOrigin',
    },
    {
      title: 'Ρόλος',
      dataIndex: 'role',
      key: 'role',
      render: (role: string) => {
        return (
          <Tag color={role == 'Καλλιεργητής' ? 'green' : 'geekblue'}>
            {role}
          </Tag>
        );
      },
    },
    {
      title: 'Σημειώσεις',
      dataIndex: 'notes',
      key: 'notes',
    },
  ];
  const { data: users, isLoading } = useQuery({
    queryKey: ['users', url, isEditCatalogueModalOpen],
    queryFn: () => getData(request, url),
    select(data) {
      return data.data;
    },
  });

  useEffect(() => {
    setUrl(`${defaultUrl}&${searchParams}`);
  }, [searchParams]);

  const onChange = (pagination: any) => {
    setUrl(
      `${EndPoints.USER.USER}?Items=${pageSize}&Page=${pagination.current}&${searchParams}`,
    );
  };
  const onFinish: FormProps['onFinish'] = async (formValues: any) => {
    const values = {
      ...formValues,
    };
    Object.keys(values).forEach((key) => !values[key] && delete values[key]);
    const params = new URLSearchParams(values);
    navigate(`/users?${params}`, { replace: true });
    setUrl(`${defaultUrl}&${params}`);
  };

  return (
    <Row justify="center" gutter={[8, 8]} style={baseStyle.mainRow}>
      <Col span={24}>
        <Flex gap="middle" vertical>
          {role !== 'Γεωπόνος' && (
            <Button
              onClick={() => {
                setOpenAddUserModal(true);
              }}
              style={addCatalogueButton}
              type="primary"
              size="large"
              icon={<UserAddOutlined />}
            >
              Προσθήκη
            </Button>
          )}

          <AddUser
            showFieldOnFilter={false}
            title={'Κριτήρια Αναζήτησης'}
            button={'Αναζήτηση'}
            onFinishFunction={onFinish}
            viewOnly={false}
            selectedUserDetails={undefined}
            apiMethod={'post'}
            successTitle={''}
            setIsEditCatalogueModalOpen={() => {
              // intentionally empty
              //
            }}
            endPoint={''}
          />
        </Flex>
      </Col>
      <Col span={24}>
        <Card style={cardStyle}>
          <BaseTable
            dataSource={users?.items}
            columns={columns}
            loading={isLoading}
            paginationConfig={{
              pageSize: pageSize,
              total: users?.totalCount,
            }}
            onChange={onChange}
            rowSelection={null}
          />
        </Card>
        <BaseModal
          modalTitle="Προβολή"
          modalWidth={'85%'}
          isModalOpen={isViewCatalogueModalOpen}
          setIsModalOpen={setIsViewCatalogueModalOpen}
        >
          <AddUser
            showFieldOnFilter={true}
            title={''}
            button={''}
            onFinishFunction={undefined}
            viewOnly={true}
            selectedUserDetails={selectedViewUser}
            apiMethod={'post'}
            successTitle={''}
            setIsEditCatalogueModalOpen={() => {
              // intentionally empty
            }}
            endPoint={''}
          />
        </BaseModal>
        <BaseModal
          modalTitle="Προβολή"
          modalWidth={'85%'}
          isModalOpen={isEditCatalogueModalOpen}
          setIsModalOpen={setIsEditCatalogueModalOpen}
        >
          <AddUser
            showFieldOnFilter={true}
            title={''}
            button={'Αποθήκευση'}
            onFinishFunction={undefined}
            viewOnly={false}
            selectedUserDetails={selectedEditUser}
            apiMethod={'put'}
            successTitle="Ο χρήστης επεξεργάστηκε με επιτυχία!"
            setIsEditCatalogueModalOpen={setIsEditCatalogueModalOpen}
            endPoint={EndPoints.USER.USER}
          />
        </BaseModal>
      </Col>
      <BaseModal
        modalTitle="Δημιουργία Χρήστη"
        modalWidth={'85%'}
        isModalOpen={openAddUserModal}
        setIsModalOpen={setOpenAddUserModal}
      >
        <AddUser
          showFieldOnFilter={true}
          title={''}
          button={'Αποθήκευση'}
          onFinishFunction={undefined}
          viewOnly={false}
          selectedUserDetails={undefined}
          apiMethod={'post'}
          successTitle={'Ο χρήστης δημιουργήθηκε με επιτυχία!'}
          setIsEditCatalogueModalOpen={() => {
            // intentionally empty
          }}
          endPoint={endPoints.USER.REGISTERADMIN}
        />
      </BaseModal>
    </Row>
  );
};
export default UsersCatalogue;
