import {
  Button,
  Col,
  DatePicker,
  Form,
  FormProps,
  InputNumber,
  Row,
  Select,
  App,
} from 'antd';
import { FC, useContext, useEffect, useState } from 'react';
import {
  NamedItem,
  LandParcel,
  FertilizerType,
  FertilizeEvent,
  CalendarEventContent,
} from '../../../types/types';
import { baseFormLayout, fullWidth } from '../../../shared/styles/baseStyle';
import LoadingSpin from '../../LoadingSpin/LoadingSpin';
import { getData, mutateData } from '../../../shared/services/apiServices';
import useCreateAxiosInstance from '../../../shared/hooks/useCreateAxiosInstance';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { Dayjs } from 'dayjs';
import endPoints from '../../../shared/endPoints.json';
import dayjs from 'dayjs';
import { Method } from 'axios';
import CommonFormItems from './CommonFormItems';
import useReminderMutation from '../../../shared/hooks/useEventReminderMutation';
import EventReminder from '../../EventReminder/EventReminder';
import { CultivationActivity } from '../../../shared/context/activityMutation';

type Props = {
  selectedCultivation: LandParcel | undefined;

  selectedDate: Dayjs | undefined;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEvent: CalendarEventContent | undefined;
  apiMethod: Method;
  successMessage: string;
  viewOnly: boolean;
};
const FertilizerForm: FC<Props> = ({
  selectedCultivation,
  selectedDate,
  setIsModalOpen,
  selectedEvent,
  apiMethod,
  successMessage,
  viewOnly,
}) => {
  const { message } = App.useApp();

  const { activityMutation, setActivityMutation } =
    useContext(CultivationActivity);
  const { request } = useCreateAxiosInstance();
  const { mutate: mutateReminder } = useReminderMutation();
  const [form] = Form.useForm();
  const dateFormat = 'DD/MM/YYYY';
  const requiredRuleConfig = {
    required: true,
    message: 'Υποχρεωτικό πεδίο',
  };
  const [
    fertilizerApplicationFrequencyId,
    setFertilizerApplicationFrequencyId,
  ] = useState<number>();
  const [fertilizerApplicationMethodId, setFertilizerApplicationMethodId] =
    useState<number>();
  const [fertilizerModeId, setFertilizerModeId] = useState<number>();
  const [fertilizerTypeId, setFertilizerTypeId] = useState<number>();
  const [fertilizerWeatherId, setFertilizerWeatherId] = useState<number>();
  useEffect(() => {
    if (selectedCultivation) {
      form.setFieldsValue({
        cultivation: selectedCultivation?.cultivation,
        landParcelId: selectedCultivation?.id,
        applicationDate: selectedDate?.startOf('day'),
      });
    }
  }, [selectedCultivation]);
  const { data: fertilizeData } = useQuery({
    queryKey: ['fertilizeData'],
    queryFn: () =>
      getData(
        request,
        `${endPoints.CALENDAR.LANDPARCELFERTILIZER}/${selectedEvent?.id}`,
      ),
    select(data: { data: FertilizeEvent }) {
      return data.data;
    },
    enabled: !!selectedEvent,
  });

  useEffect(() => {
    if (fertilizeData) {
      setFertilizerApplicationFrequencyId(
        fertilizeData.fertilizerApplicationFrequencyId,
      );
      setFertilizerApplicationMethodId(
        fertilizeData.fertilizerApplicationMethodId,
      );
      setFertilizerModeId(fertilizeData.fertilizerModeId);
      setFertilizerTypeId(fertilizeData.fertilizerTypeId);
      setFertilizerWeatherId(fertilizeData.fertilizerWeatherId);
      form.setFieldsValue({
        cultivation: !selectedEvent?.reminderId
          ? selectedEvent?.landParcelCultivation
          : selectedEvent?.cultivationName,
        landParcelId: selectedEvent?.landParcelId,
        applicationDate: !selectedEvent?.reminderId
          ? dayjs(fertilizeData.applicationDate)
          : undefined,
        fertilizerApplicationFrequencyId:
          fertilizeData.fertilizerApplicationFrequency,
        fertilizerApplicationMethodId:
          fertilizeData.fertilizerApplicationMethod,
        fertilizerDose: fertilizeData.fertilizerDose,
        fertilizerModeId: fertilizeData.fertilizerMode,
        fertilizerTypeId: fertilizeData.fertilizerType,
        fertilizerWeatherId: fertilizeData.fertilizerWeatherId,
      });
    }
  }, [fertilizeData]);

  const { data: fertilizerModeItems, isLoading: fertilizerModeLoading } =
    useQuery({
      queryKey: ['fertilizerModeItems'],
      queryFn: () => getData(request, endPoints.CALENDAR.FERTILIZERMODE),
      select(data: { data: NamedItem[] }) {
        return data.data.map((item: NamedItem) => ({
          value: item.id,
          label: item.name,
        }));
      },
    });
  const { data: fertilizerWeatherItems, isLoading: fertilizerWeatherLoading } =
    useQuery({
      queryKey: ['fertilizerWeatherItems'],
      queryFn: () => getData(request, endPoints.CALENDAR.FERTILIZERWEATHER),
      select(data: { data: NamedItem[] }) {
        return data.data.map((item: NamedItem) => ({
          value: item.id,
          label: item.name,
        }));
      },
    });
  const { data: fertilizerTypeItems, isLoading: fertilizerTypeLoading } =
    useQuery({
      queryKey: ['fertilizerTypeItems'],
      queryFn: () => getData(request, endPoints.CALENDAR.FERTILIZERTYPE),
      select(data: { data: FertilizerType[] }) {
        return data.data.map((item: FertilizerType) => ({
          value: item.id,
          label: item.nameGr,
        }));
      },
    });
  const {
    data: FertilizerApplicationMethodItems,
    isLoading: fertilizerApplicationMethodLoading,
  } = useQuery({
    queryKey: ['fertilizerApplicationMethodItems'],
    queryFn: () =>
      getData(request, endPoints.CALENDAR.FERTILIZERAPPLICATIONMETHOD),
    select(data: { data: NamedItem[] }) {
      return data.data.map((item: NamedItem) => ({
        value: item.id,
        label: item.name,
      }));
    },
  });
  const {
    data: fertilizerApplicationFrequencyItems,
    isLoading: fertilizerApplicationFrequencyLoading,
  } = useQuery({
    queryKey: ['fertilizerApplicationFrequencyItems'],
    queryFn: () =>
      getData(request, endPoints.CALENDAR.FERTILIZERAPPLICATIONFREQUENCY),
    select(data: { data: NamedItem[] }) {
      return data.data.map((item: NamedItem) => ({
        value: item.id,
        label: item.name,
      }));
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (formValues: FertilizeEvent & { reminderDate: number }) =>
      mutateData(
        request,
        endPoints.CALENDAR.LANDPARCELFERTILIZER,
        formValues,
        apiMethod,
      ),
    onSuccess(data, variables) {
      message.success(successMessage);
      setIsModalOpen(false);
      setActivityMutation(!activityMutation);
      variables?.reminderDate &&
        mutateReminder({
          eventId: data.data,
          eventType: endPoints.CALENDAR.LANDPARCELFERTILIZER,
          reminderDate: variables.reminderDate,
        });
    },
  });

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    const formattedValues = {
      ...values,
      applicationDate: dayjs(values.applicationDate?.$d)
        .startOf('day')
        .valueOf(),
      fertilizerApplicationFrequencyId: fertilizerApplicationFrequencyId,
      fertilizerApplicationMethodId: fertilizerApplicationMethodId,
      fertilizerModeId: fertilizerModeId,
      fertilizerTypeId: fertilizerTypeId,
      fertilizerWeatherId: fertilizerWeatherId,
      id: selectedEvent?.id,
      reminderDate: values?.reminderDate?.$d.getTime(),
    };
    mutate(formattedValues);
  };
  if (
    fertilizerModeLoading ||
    fertilizerWeatherLoading ||
    fertilizerApplicationFrequencyLoading ||
    fertilizerApplicationMethodLoading ||
    fertilizerTypeLoading ||
    isPending
  )
    return <LoadingSpin />;
  return (
    <Form
      {...baseFormLayout}
      layout="vertical"
      size="large"
      form={form}
      onFinish={onFinish}
    >
      <CommonFormItems />
      <Row>
        <Col xs={12}>
          <Form.Item
            name="applicationDate"
            label="Ημ/νια Εφαρμογής"
            rules={[requiredRuleConfig]}
          >
            <DatePicker
              format={dateFormat}
              placeholder="Ημ/νια Εφαρμογής"
              style={fullWidth}
              disabled={viewOnly}
            />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name="fertilizerWeatherId"
            label="Καιρός κατά την Εφαρμογή"
            rules={[requiredRuleConfig]}
          >
            <Select
              allowClear
              options={fertilizerWeatherItems}
              placeholder="Καιρός κατά την Εφαρμογή"
              disabled={viewOnly}
              onSelect={(value: number) => {
                setFertilizerWeatherId(value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.Item
            name="fertilizerTypeId"
            label="Τύπος Λιπάσματος"
            rules={[requiredRuleConfig]}
          >
            <Select
              allowClear
              options={fertilizerTypeItems}
              placeholder="Τύπος Λιπάσματος"
              disabled={viewOnly}
              onSelect={(value: number) => {
                setFertilizerTypeId(value);
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name="fertilizerDose"
            label="Δόση Λίπανσης (kg/ha ή lt/ha)"
            rules={[requiredRuleConfig]}
          >
            <InputNumber
              type="number"
              style={fullWidth}
              step={1}
              min={0}
              placeholder="Δόση Λίπανσης"
              disabled={viewOnly}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start">
        <Col xs={12}>
          <Form.Item
            name="fertilizerModeId"
            label="Τρόπος Εφαρμογής"
            rules={[requiredRuleConfig]}
          >
            <Select
              allowClear
              options={fertilizerModeItems}
              placeholder="Τρόπος Εφαρμογής"
              disabled={viewOnly}
              onSelect={(value: number) => {
                setFertilizerModeId(value);
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name="fertilizerApplicationMethodId"
            label="Μέθοδος Εφαρμογής"
            rules={[requiredRuleConfig]}
          >
            <Select
              allowClear
              options={FertilizerApplicationMethodItems}
              placeholder="Τρόπος Εφαρμογής"
              disabled={viewOnly}
              onSelect={(value: number) => {
                setFertilizerApplicationMethodId(value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start">
        <Col xs={12}>
          <Form.Item
            name="fertilizerApplicationFrequencyId"
            label="Συχνότητα Εφαρμογής"
            rules={[requiredRuleConfig]}
          >
            <Select
              allowClear
              options={fertilizerApplicationFrequencyItems}
              placeholder="Συχνότητα Εφαρμογής"
              disabled={viewOnly}
              onSelect={(value: number) => {
                setFertilizerApplicationFrequencyId(value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      {!viewOnly && fertilizeData && (
        <EventReminder
          reminderDate={apiMethod == 'put' ? fertilizeData?.reminderDate : null}
        />
      )}
      {apiMethod == 'post' && (
        <Row justify="center">
          <Button htmlType="submit">Προσθήκη</Button>
        </Row>
      )}
      {!viewOnly && apiMethod == 'put' && (
        <Row justify="center">
          <Button htmlType="submit">Αποθήκευση</Button>
        </Row>
      )}
    </Form>
  );
};
export default FertilizerForm;
