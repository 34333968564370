/* eslint-disable react/prop-types */
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useMap } from 'react-leaflet';
import Endpoints from '../../shared/endPoints.json';
import L, { CRS } from 'leaflet';
import { geojsonToWKT } from '@terraformer/wkt';
import { Control, DomUtil } from 'leaflet';
import { createRoot } from 'react-dom/client';
import { Collapse, Checkbox, Col, Tooltip } from 'antd';

const closePolygon = (polygon) => {
  polygon.push(polygon[0]);
  return polygon;
};

const LayerControl = ({ polygons }) => {
  const createWmsLayer = useCallback(
    (layerId) => {
      return polygons?.map(
        (polygon) =>
          new L.tileLayer.wms(Endpoints.SENTINEL, {
            uppercase: true,
            layers: layerId,
            tms: true,
            tyled: true,
            format: 'image/png',
            maxZoom: '20',
            minZoom: '1',
            crs: CRS.EPSG4326,
            transparent: true,
            bounds: polygon,
            version: '1.3.0',
            detectRetina: true,
            geometry: geojsonToWKT({
              type: 'Polygon',
              coordinates: [closePolygon(polygon)],
            }),
          }),
      );
    },
    [polygons],
  );

  const layers = useMemo(() => {
    return [
      {
        key: '1',
        group: 'Δορυφορικά',
        layers: [
          {
            checked: false,
            name: 'NDVI',
            layer: createWmsLayer('3_NDVI'),
            tooltip:
              'Ο κανονικοποιημένος δείκτης κόκκινου άκρου διαφοράς (NDRE) είναι μια μέτρηση που μπορεί να χρησιμοποιηθεί για να αναλύσει αν οι πολυφασματικές εικόνες που λαμβάνονται περιέχουν υγιή βλάστηση ή όχι. Είναι παρόμοιο με το δείκτη βλάστησης κανονικοποιημένης διαφοράς (NDVI) αλλά χρησιμοποιεί την αναλογία των κοντινών υπέρυθρων και της άκρης του κόκκινου.',
          },
          {
            checked: false,
            name: 'True Color',
            layer: createWmsLayer('1_TRUE_COLOR'),
            tooltip:
              'Η πραγματική απεικόνιση του αγροτεμαχίου επάνω στο χάρτη, στο ορατό φάσμα.',
          },
          {
            checked: false,
            name: 'Chlorophyll-Red-Edge',
            layer: createWmsLayer('CHLOROPHYLL-RED-EDGE'),
            tooltip: 'Ο δείκτης μετρά την χλωροφύλλη στον θόλο των φυτών.',
          },
          {
            checked: false,
            name: 'Burned Area Index',
            layer: createWmsLayer('BAIS2'),
            tooltip:
              'Υποδηλώνει τα υπολλείμματα καμμένων φυτών. Τα σημεία που είναι πιο ανοιχτόχρωμα στο χάρτη υποδεικνύουν τις καμμένες περιοχές.',
          },
          {
            checked: false,
            name: 'Moisture-Index',
            layer: createWmsLayer('5-MOISTURE-INDEX1'),
            tooltip: 'Δείκτης Υγρασίας',
          },
        ],
      },
    ];
  }, []);

  const map = useMap();
  const [collapsedKey, setCollapsedKey] = useState([]);
  const initializeLayerState = (layers) =>
    layers.reduce((acc, { group, layers: groupLayers }) => {
      acc[group] = groupLayers?.map((layer) => ({ ...layer })) || [];
      return acc;
    }, {});

  const [layerState, setLayerState] = useState(() =>
    initializeLayerState(layers),
  );

  const toggleLayer = (selectedLayer, group) => {
    setLayerState(() => {
      const updatedGroup = layerState[group].map((layer) => {
        if (layer?.name === selectedLayer?.name) {
          const isChecked = !layer.checked;
          layer.layer?.forEach((mapLayer) =>
            isChecked ? map.addLayer(mapLayer) : map.removeLayer(mapLayer),
          );
          return { ...layer, checked: isChecked };
        }
        return layer;
      });

      return { ...layerState, [group]: updatedGroup };
    });
  };

  const onCollapseChange = (key) => {
    setCollapsedKey(key);
  };

  useEffect(() => {
    const control = new Control({ position: 'topright' });
    const container = DomUtil.create('div');
    control.onAdd = () => container;
    control.addTo(map);
    const root = createRoot(container);

    root.render(
      <>
        {Object.keys(layerState).map((group) => (
          <Collapse
            key={group}
            defaultActiveKey={collapsedKey}
            onChange={onCollapseChange}
            style={{ backgroundColor: 'white' }}
            items={[
              {
                label: group,
                key: group,
                children: layerState[group]?.map((layer) => {
                  return (
                    <Col key={layer.name}>
                      <Checkbox
                        key={layer.name}
                        checked={layer.checked}
                        onChange={() => {
                          toggleLayer(layer, group);
                        }}
                      >
                        <Tooltip placement="left" title={layer?.tooltip}>
                          {layer.name}
                        </Tooltip>
                      </Checkbox>
                    </Col>
                  );
                }),
              },
            ]}
          ></Collapse>
        ))}
      </>,
    );

    return () => {
      DomUtil.remove(container);
    };
  }, [map, layerState, layers]);

  return null;
};

export default LayerControl;
