import { Navigate, createBrowserRouter } from 'react-router-dom';
import UsersCatalogue from '../../pages/Users/UsersCatalogue';
import AddCultivation from '../../pages/Cultivations/AddCultivation';
import CultivationsCatalogue from '../../pages/Cultivations/CultivationCatalogue';
import Login from '../../pages/Login/Login';
import Mainlayout from '../MainLayout';
import PrivateRoute from './PrivateRoute';
import Register from '../../pages/Register/Register';
import GlobalRoute from './GlobalRouter';
import ResetPassword from '../../pages/Profile/ResetPassword';
import ForgotPassword from '../../pages/ForgotPassword/ForgotPassword';
import InfoLayout from '../../pages/Info/Layout/InfoLayout';
import endPoints from '../../shared/endPoints.json';
import Subscriptions from '../../pages/ Subscriptions/ Subscriptions';
import CalendarRouter from './CalendarRouter';
import FarmerCalendar from '../../pages/Calendar/FarmerCalendar';
import InfoMenuLayout from '../../pages/Info/InfoMenuLayout';
import AboutUs from '../../pages/AboutUs/AboutUs';
import AuthenticationCallback from '../AuthenticationCallback/AuthenticationCallback';
import RoleBasedHome from '../../pages/Home/RoleBasedHome';
import Logout from '../../pages/Logout';
import OTPValidation from '../../pages/OTPValidation/OTPValidation';
import ImpersonateUser from '../../pages/ImpersonateUser';
import PrivateImpersonateForAgronomist from './PrivateImpersonateForAgronomist';
import AgriculturalOutflowCatalogue from '../../pages/Agricultural/AgriculturalOutflow/AgriculturalOutflowCatalogue';
import AgriculturalInflowCatalogue from '../../pages/Agricultural/AgriculturalInflow/AgriculturalInflowCatalogue';
import AddAgriculturalOutflow from '../../pages/Agricultural/AgriculturalOutflow/AddAgriculturalOutflow';
import AddAgriculturalInflow from '../../pages/Agricultural/AgriculturalInflow/AddAgriculturalInflow';
import AgriculturalFlowsTabs from '../../pages/Agricultural/AgriculturalFlowsTabs';

type Props = {
  selectedEaeSeasonValue: number;
};
export const router = createBrowserRouter([
  {
    path: '/otpValidation/:userId',
    element: <OTPValidation />,
  },
  {
    path: '/info',
    element: <GlobalRoute />,
    children: [
      {
        path: '',
        element: <InfoLayout />,
      },
    ],
  },
  {
    path: '/subscriptions',
    element: <GlobalRoute />,
    children: [
      {
        path: '',
        element: (
          <InfoMenuLayout>
            <Subscriptions />
          </InfoMenuLayout>
        ),
      },
    ],
  },
  {
    path: '/aboutus',
    element: <GlobalRoute />,
    children: [
      {
        path: '',
        element: (
          <InfoMenuLayout>
            <AboutUs />
          </InfoMenuLayout>
        ),
      },
    ],
  },
  {
    path: '/login',
    element: <GlobalRoute />,
    children: [{ path: '', element: <Login /> }],
  },
  {
    path: '/register',
    element: <GlobalRoute />,
    children: [{ path: '', element: <Register /> }],
  },
  {
    path: '/activatePassword',
    element: <GlobalRoute />,
    children: [
      {
        path: '',
        element: (
          <ResetPassword
            title={'Αλλαγή Προσωρινού Κωδικού'}
            endPoint={endPoints.USER.ACTIVATETEMPPASSWORD}
          />
        ),
      },
    ],
  },

  {
    path: '/forgotPassword',
    element: <GlobalRoute />,
    children: [{ path: '', element: <ForgotPassword /> }],
  },
  {
    path: '/resetPassword',
    element: <GlobalRoute />,
    children: [
      {
        path: '',
        element: (
          <ResetPassword
            title={'Επαναφορά κωδικού πρόσβασης'}
            endPoint={endPoints.USER.RESETPASSWORDWITHTOKEN}
          />
        ),
      },
    ],
  },
  {
    path: '/',
    element: <PrivateRoute />,
    children: [
      {
        path: '/',
        element: <Mainlayout />,
        children: [
          { path: '', element: <RoleBasedHome /> },
          {
            path: 'authcallback/taxisnet',
            element: <AuthenticationCallback />,
          },
          {
            path: 'users',
            element: <UsersCatalogue />,
          },
          {
            path: 'cultivations/add',
            element: <AddCultivation />,
          },
          {
            path: 'cultivations',
            element: <CultivationsCatalogue />,
          },
          {
            path: 'agriculturalFlows',
            element: <AgriculturalFlowsTabs />,
          },
          {
            path: 'agricultural/addOutflow',
            element: <AddAgriculturalOutflow />,
          },
          {
            path: 'agricultural/addInflow',
            element: <AddAgriculturalInflow />,
          },
          {
            path: '/',
            element: <CalendarRouter />,
            children: [
              {
                path: 'calendar',
                element: (
                  <FarmerCalendar
                    defaultDate={undefined}
                    calendarSpan={14}
                    showEAESeasonSelect
                  />
                ),
              },
            ],
          },

          {
            path: 'profile',
            element: (
              <ResetPassword
                title={'Αλλαγή Στοιχείων Πρόσβασης'}
                endPoint={endPoints.USER.RESETPASSWORD}
              />
            ),
          },
          {
            path: 'mysubscription',
            element: <Subscriptions />,
          },
        ],
      },
      {
        path: 'logout/taxisnet',
        element: <Logout />,
      },
      {
        path: 'impersonate/:userId',
        element: <PrivateImpersonateForAgronomist />,
        children: [
          {
            path: '',
            element: <ImpersonateUser />,
          },
        ],
      },
      { path: '*', element: <Navigate to="/" replace /> },
    ],
  },
]);
