import { FC, useState } from 'react';
import { Row, Col, Card, Calendar } from 'antd';
import { useQuery } from '@tanstack/react-query';
import MapView from '../components/MapView/MapView';
import { cardStyle } from '../shared/styles/baseStyle';
import useCreateAxiosInstance from '../shared/hooks/useCreateAxiosInstance';
import { getData } from '../shared/services/apiServices';
import endPoints from '../shared/endPoints.json';
import dayjs from 'dayjs';

const Home: FC = () => {
  const { request } = useCreateAxiosInstance();
  const [mapDate, setMapDate] = useState(dayjs().format('YYYY-MM-DD'));
  const { data: cultivations, isLoading } = useQuery({
    queryKey: ['cultivations'],
    queryFn: () =>
      getData(
        request,
        `${endPoints.CULTIVATION.LANDPARCEL}?items=10000000&page=1`,
      ),
    select(data) {
      const coordinates = data?.data?.items?.map(
        (item: { coordinates: string }) => JSON.parse(item.coordinates),
      );
      const names = data?.data?.items?.map(
        (item: { name: string }) => item.name,
      );
      return { coordinates, names };
    },
  });
  return (
    <Row justify="center" gutter={[16, 16]}>
      <Col span={24}>
        <Card style={cardStyle} loading={isLoading}>
          <MapView
            mapDate={mapDate}
            path="home"
            height={'75vh'}
            setPolygon={undefined}
            showDrawControls={false}
            landParcelMutation={undefined}
            clearCoordinates={undefined}
            coordinates={cultivations?.coordinates}
            cultivationsNames={cultivations?.names}
          />
        </Card>
      </Col>
      <Col xs={24} md={10}>
        <Card style={cardStyle}>
          <Calendar
            disabledDate={(date) => {
              if (dayjs(date) < dayjs()) {
                return false;
              }
              return true;
            }}
            fullscreen={false}
            onSelect={(e) => {
              setMapDate(e.format('YYYY-MM-DD'));
            }}
          />
        </Card>
      </Col>
      <Col xs={24} md={14}>
        <Card style={cardStyle}>
          <iframe
            src="https://www.ventusky.com/?p=38.17;23.39;8&l=temperature-2m&lang=el"
            style={{
              width: '100%',
              height: '80vh',
              border: 'none',
            }}
          ></iframe>
        </Card>
      </Col>
    </Row>
  );
};
export default Home;
