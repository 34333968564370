import { Flex, Row, Select, Typography } from 'antd';
import { FC, useContext, useEffect } from 'react';
import { EAESeasonContext } from '../../shared/context/EAESeason';
import { EAESeasonItems } from '../../types/types';
type Props = {
  setSelectedEaeSeason: any;
};

const EAESeason: FC<Props> = ({ setSelectedEaeSeason }) => {
  const { eaeSeason } = useContext(EAESeasonContext);
  if (!eaeSeason) return <></>;
  const eaeSeasonItems = eaeSeason?.map((item: EAESeasonItems) => ({
    ...item,
    value: item.id,
    label: `${item.range}, ΕΑΕ: ${item.eae}`,
    start: item.start,
    end: item.end,
    id: item.id,
  }));
  useEffect(() => {
    setSelectedEaeSeason(eaeSeasonItems[0]);
  }, [eaeSeason]);

  return (
    <Row justify="start">
      <Flex vertical>
        <Typography.Text strong style={{ fontWeight: 'bolder' }}>
          Περίοδος:
        </Typography.Text>
        <Select
          defaultValue={eaeSeasonItems[0]}
          style={{ width: 180 }}
          onChange={(value, option) => {
            setSelectedEaeSeason(option);
          }}
          options={eaeSeasonItems}
        />
      </Flex>
    </Row>
  );
};

export default EAESeason;
