import { FC, useContext, useEffect } from 'react';
import { AuthContext } from '../shared/context/auth-context';
import LoadingSpin from '../components/LoadingSpin/LoadingSpin';
const Logout: FC = () => {
  const { logout, token } = useContext(AuthContext);
  useEffect(() => {
    logout();
  }, [token]);
  return <LoadingSpin />;
};
export default Logout;
