import { FC } from 'react';
import AgriculturalOutflowForm from '../../../components/Forms/Agricultural/AgriculturalOutflowForm';

const AddAgriculturalOutflow: FC = () => {
  return (
    <AgriculturalOutflowForm
      selectedAgriculturalOutflow={null}
      apiMethod="post"
      successTitle="Η εκροή προστέθηκε με επιτυχία!"
      setIsEditCatalogueModalOpen={() => {
        // intentionally empty
      }}
    />
  );
};

export default AddAgriculturalOutflow;
