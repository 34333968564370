import { FC } from 'react';
import { Layout, Button, FloatButton, Col, Row, Grid, Flex, Space } from 'antd';
import { baseColors } from '../../../shared/styles/baseStyle';
import { footerbutton } from '../styles';
import { SocialIcon } from 'react-social-icons';
import { useNavigate } from 'react-router-dom';

const Footer: FC = () => {
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const navigate = useNavigate();
  const { Footer } = Layout;
  const privacyPolicyTitle = 'Πολιτική Απορρήτου';
  const termsOfUseTitle = 'Όροι Χρήσης';
  const newsTitle = 'Νέα';
  const contactTitle = 'Επικοινωνία';
  const openTermsOfUsePDF = () => {
    window.open('/pdfs/termsOfUse.pdf', '_blank');
  };
  const openPrivacyPolicyPDF = () => {
    window.open('/pdfs/privacyPolicy.pdf', '_blank');
  };

  const openNews = () => {
    window.open('https://blog.farmacon.gr/nea-etairion/item/3813-agros-online');
  };

  const openFarmaconSite = () => {
    window.location.href = 'https://farmacon.gr';
  };
  const goToContact = () => {
    navigate('/info#contactUs');
  };

  const linkButtons = [
    {
      title: newsTitle,
      action: openNews,
    },
    {
      title: contactTitle,
      action: goToContact,
    },
    {
      title: termsOfUseTitle,
      action: openTermsOfUsePDF,
    },
    {
      title: privacyPolicyTitle,
      action: openPrivacyPolicyPDF,
    },
  ];

  const socialIcons = [
    {
      url: 'https://www.instagram.com/agros_online/',
    },
    {
      url: 'https://www.facebook.com/agrosonline/',
    },
    {
      url: 'https://www.youtube.com/@AgrosOnline',
    },
    {
      url: 'https://www.linkedin.com/company/farmaconsa',
    },
    {
      url: 'https://x.com/FarmaconG',
    },
  ];

  const FarmaconButton = (
    <Button type="link" style={footerbutton} onClick={openFarmaconSite}>
      powered by
      <div
        style={{
          textDecoration: 'underline',
        }}
      >
        Farmacon
      </div>
    </Button>
  );
  return (
    <>
      <Footer
        style={{
          backgroundColor: 'transparent',
        }}
      >
        {breakpoints.xs ? (
          <Flex vertical gap="medium">
            {linkButtons.map((button, key) => (
              <Button
                key={key}
                size="small"
                style={footerbutton}
                type="link"
                onClick={button?.action}
              >
                {button?.title}
              </Button>
            ))}

            {FarmaconButton}
            <Row justify="center">
              <Space>
                {socialIcons.map((icon, key) => (
                  <SocialIcon
                    key={key}
                    target="_blank"
                    bgColor={baseColors.fsBlue}
                    style={{ height: 35, width: 35 }}
                    url={icon.url}
                  />
                ))}
              </Space>
            </Row>
          </Flex>
        ) : (
          <Row align="middle">
            <Col span={8}>{FarmaconButton}</Col>
            <Col span={8}>
              <Row justify="center" align="middle">
                <Space>
                  {socialIcons.map((icon, key) => (
                    <SocialIcon
                      target="_blank"
                      key={key}
                      bgColor={baseColors.fsBlue}
                      style={{ height: 40, width: 40 }}
                      url={icon.url}
                    />
                  ))}
                </Space>
              </Row>
            </Col>
            <Col span={8}>
              <Row align="middle" justify="center">
                {linkButtons.map((button, key) => (
                  <Button
                    key={key}
                    size="small"
                    style={footerbutton}
                    type="link"
                    onClick={button?.action}
                  >
                    {button?.title}
                  </Button>
                ))}
              </Row>
            </Col>
          </Row>
        )}
      </Footer>
      <FloatButton.BackTop
        type="primary"
        style={{ marginBottom: 40 }}
        duration={3000}
      />
    </>
  );
};

export default Footer;
