import { FC } from 'react';
import { Layout, Button, FloatButton, Col, Row, Grid, Flex } from 'antd';
import { baseColors } from '../../../shared/styles/baseStyle';
import { footerbutton } from '../styles';

const Footer: FC = () => {
  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const { Footer } = Layout;
  const privacyPolicyTitle = 'Πολιτική Απορρήτου';
  const termsOfUseTitle = 'Όροι Χρήσης';
  const openTermsOfUsePDF = () => {
    window.open('/pdfs/termsOfUse.pdf', '_blank');
  };
  const openPrivacyPolicyPDF = () => {
    window.open('/pdfs/privacyPolicy.pdf', '_blank');
  };

  const FarmaconButton = (
    <Button
      type="link"
      style={{
        color: 'white',
      }}
      onClick={() => (window.location.href = 'https://farmacon.gr')}
    >
      powered by
      <div
        style={{
          textDecoration: 'underline',
        }}
      >
        Farmacon
      </div>
    </Button>
  );
  const termsOfUseButton = (
    <Button style={footerbutton} type="link" onClick={openTermsOfUsePDF}>
      {termsOfUseTitle}
    </Button>
  );
  const privacyPolicyButton = (
    <Button style={footerbutton} type="link" onClick={openPrivacyPolicyPDF}>
      {privacyPolicyTitle}
    </Button>
  );
  return (
    <>
      <Footer
        style={{
          backgroundColor: baseColors.fsGreyBlue,
        }}
      >
        {breakpoints.xs ? (
          <Flex vertical>
            {FarmaconButton}
            {termsOfUseButton}
            {privacyPolicyButton}
          </Flex>
        ) : (
          <Row justify="start">
            <Col span={12}>{FarmaconButton}</Col>
            <Col span={12}>
              <Row justify="end">
                {termsOfUseButton}
                {privacyPolicyButton}
              </Row>
            </Col>
          </Row>
        )}
      </Footer>
      <FloatButton.BackTop
        type="primary"
        style={{ marginBottom: 40 }}
        duration={3000}
      />
    </>
  );
};

export default Footer;
