import { FC, useContext, useEffect } from 'react';
import { AuthContext } from '../shared/context/auth-context';
import LoadingSpin from '../components/LoadingSpin/LoadingSpin';
import { useNavigate, useParams } from 'react-router-dom';
import { mutateData } from '../shared/services/apiServices';
import { useMutation } from '@tanstack/react-query';
import endPoints from '../shared/endPoints.json';
import useCreateAxiosInstance from '../shared/hooks/useCreateAxiosInstance';
import { message } from 'antd';

const ImpersonateUser: FC = () => {
  const { request } = useCreateAxiosInstance();
  const { userId } = useParams();
  const { impersonateUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: () =>
      mutateData(request, endPoints.USER.IMPERSONATE, { userId }, 'post'),
    onSuccess(data: { data: any }) {
      impersonateUser(data.data);
    },
    onError(error: any) {
      if (
        error?.status === 302 &&
        error?.response?.data === 'Ο χρήστης δεν έχει ενεργοποιηθεί'
      ) {
        message.error('Μη ενεργοποιημένος χρήστης.');
        navigate('/');
        return;
      }

      message.error('Κάτι πήγε στραβά.');
      navigate('/');
    },
  });
  useEffect(() => {
    mutate();
  }, []);

  return <LoadingSpin />;
};
export default ImpersonateUser;
