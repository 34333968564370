import { FC, useState, useEffect } from 'react';
import { Card, Row, Space, Col, Button, Flex } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { EditOutlined, AppstoreAddOutlined } from '@ant-design/icons';
import BaseModal from '../../../components/BaseModal/BaseModal';
import BaseTable from '../../../components/BaseTable/BaseTable';
import {
  baseStyle,
  cardStyle,
  addCatalogueButton,
} from '../../../shared/styles/baseStyle';
import { useNavigate } from 'react-router-dom';
import { getData } from '../../../shared/services/apiServices';
import useCreateAxiosInstance from '../../../shared/hooks/useCreateAxiosInstance';
import endPoints from '../../../shared/endPoints.json';
import EditAgriculturalOutflow from './EditAgriculturalOutflow';
import { AgriculturalOutflow } from '../../../types/types';
import dayjs from 'dayjs';
type Props = {
  selectedEaeSeasonValue: number;
};
const AgriculturalOuflowCatalogue: FC<Props> = ({ selectedEaeSeasonValue }) => {
  const navigate = useNavigate();
  const dateFormat = 'DD/MM/YYYY';
  const { request } = useCreateAxiosInstance();
  const [isEditCatalogueModalOpen, setIsEditCatalogueModalOpen] =
    useState(false);
  const defaultCurrentPage = 1;
  const pageSize = 10;
  const defaultUrl = `${endPoints.AGRICULTURAL.AGRICULTURALOUTFLOW}?Items=${pageSize}&Page=${defaultCurrentPage}&eaeSeasonId=${selectedEaeSeasonValue}`;
  const [url, setUrl] = useState(defaultUrl);
  const [
    selectedEditAgriculturalOutflowId,
    setSelectedEditAgriculturalOutflowId,
  ] = useState<number>();
  const [editClick, setEditClick] = useState(false);

  useEffect(() => {
    setUrl(
      `${endPoints.AGRICULTURAL.AGRICULTURALOUTFLOW}?Items=${pageSize}&Page=${defaultCurrentPage}&eaeSeasonId=${selectedEaeSeasonValue}`,
    );
  }, [selectedEaeSeasonValue]);
  const { data: agriculturalOutflow, isLoading } = useQuery({
    queryKey: ['agriculturalOutflow', url, isEditCatalogueModalOpen],
    queryFn: () => getData(request, url),
    select(data) {
      return data.data;
    },
    enabled: isEditCatalogueModalOpen == false,
  });

  const { data: selectedAgriculturalFlowForEdit, isLoading: isEditLoading } =
    useQuery({
      queryKey: ['selectedAgriculturalFlowForEdit', editClick],
      queryFn: () =>
        getData(
          request,
          `${endPoints.AGRICULTURAL.AGRICULTURALOUTFLOW}/${selectedEditAgriculturalOutflowId}`,
        ),
      enabled: !!selectedEditAgriculturalOutflowId,
      select(data) {
        return data.data;
      },
    });
  const onChange = (pagination: any) => {
    setUrl(
      `${endPoints.AGRICULTURAL.AGRICULTURALOUTFLOW}?Items=${pageSize}&Page=${pagination.current}&eaeSeasonId=${selectedEaeSeasonValue}`,
    );
  };

  const handleEditClick = async (record: AgriculturalOutflow) => {
    setSelectedEditAgriculturalOutflowId(record.id);
    setIsEditCatalogueModalOpen(true);
    setEditClick(!editClick);
  };
  const columns = [
    {
      title: '',
      key: 'action',
      render: (_: any, record: AgriculturalOutflow) => (
        <Space size="middle">
          <EditOutlined onClick={() => handleEditClick(record)} />
        </Space>
      ),
    },
    {
      title: 'Αριθμός Παραστατικού',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
    },
    {
      title: 'Ημερομηνία Παραστατικού',
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      render: (text: any) => dayjs(text).format(dateFormat),
    },
    {
      title: 'Επωνυμία Αγοραστή',
      dataIndex: 'buyerName',
      key: 'buyerName',
    },
    {
      title: 'Διεύθυνση',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Είδος Προιόντος',
      dataIndex: 'productType',
      key: 'productType',
    },
    {
      title: 'Εμπορικό Όνομα/Ποικιλία',
      dataIndex: 'tradeName',
      key: 'tradeName',
    },
    {
      title: 'Ποσότητα',
      dataIndex: 'quantity',
      key: 'quantity',
    },
  ];
  return (
    <Row justify="center" style={baseStyle.mainRow}>
      <Col span={24}>
        <Flex gap="middle" vertical>
          <Button
            onClick={() => {
              navigate('/agricultural/addOutflow');
            }}
            style={addCatalogueButton}
            type="primary"
            size="large"
            icon={<AppstoreAddOutlined />}
          >
            Προσθήκη Εκροής
          </Button>
          <Card style={cardStyle} title="Εκροές" loading={isEditLoading}>
            <BaseTable
              dataSource={agriculturalOutflow?.items}
              columns={columns}
              loading={isLoading}
              paginationConfig={{
                pageSize: pageSize,
                total: agriculturalOutflow?.totalCount,
              }}
              onChange={onChange}
              rowSelection={null}
            />
            <BaseModal
              modalTitle=""
              modalWidth={'85%'}
              isModalOpen={isEditCatalogueModalOpen}
              setIsModalOpen={setIsEditCatalogueModalOpen}
            >
              <EditAgriculturalOutflow
                selectedAgriculturalFlowForEdit={
                  selectedAgriculturalFlowForEdit
                }
                setIsEditCatalogueModalOpen={setIsEditCatalogueModalOpen}
              ></EditAgriculturalOutflow>
            </BaseModal>
          </Card>
        </Flex>
      </Col>
    </Row>
  );
};
export default AgriculturalOuflowCatalogue;
