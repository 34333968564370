import { Card, Col, Row, Flex } from 'antd';
import { FC, useState } from 'react';
import CalendarCultivations from '../../components/CalendarCultivations/CalendarCultivations';
import { marginRow, cardStyle } from '../../shared/styles/baseStyle';
import { LandParcel } from '../../types/types';
const Calendar: FC = () => {
  const [selectedCultivation, setSelectedCultivation] = useState<LandParcel>();

  return (
    <Flex gap="small" vertical>
      <Row gutter={[8, 8]} justify="center" style={marginRow}>
        <Col span={24}>
          <Card title="Αγροτεμάχια" style={cardStyle}>
            <CalendarCultivations
              selectedCultivation={selectedCultivation}
              setSelectedCultivation={setSelectedCultivation}
            />
          </Card>
        </Col>
      </Row>
    </Flex>
  );
};
export default Calendar;
