import { Menu } from 'antd';
import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../shared/context/auth-context';
type Props = {
  items: any;
  selectedKeys: any;
  openKeys: any;
  onOpenChange: any;
};
const SiderMenu: FC<Props> = ({
  items,
  selectedKeys,
  openKeys,
  onOpenChange,
}) => {
  const { subscription } = useContext(AuthContext);
  const navigate = useNavigate();
  const farmabaseUrl =
    subscription === 'Basic'
      ? 'https://www.farmacon.gr/fytoprostateutika-proionta?view=agripro&logiclogin=basicUserFARMALOGICPkcGHIcIqvpgWrxmVniQrCwrWAU35AK8:LOGICFARMA:5d7a245a05a10eb593dff9612bc819cc'
      : 'https://www.farmacon.gr/fytoprostateutika-proionta?view=agripro&logiclogin=premiumagrosFARMALOGICm2Gj0HFiWRRWJog1JqAaEcx81hdWuFv7:LOGICFARMA:b2ad771ef81796f6fee7e5c7614e25eb';

  return (
    <Menu
      mode="inline"
      items={items}
      onClick={(e) => {
        if (e.key === 'pesticides') {
          window.location.href = farmabaseUrl;
          return;
        }
        navigate(e.key);
      }}
      selectedKeys={selectedKeys}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
    />
  );
};
export default SiderMenu;
