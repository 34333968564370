export const backgroundImage = {
  width: '100vw',
  height: '100vh',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  opacity: 0.9,
};
export const coverCard = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottom: '0.05px solid #AACFD0',
};
