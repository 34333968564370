import { Badge, Card, Col, Modal, Row, Space, Typography } from 'antd';
import { FC, useEffect, useState } from 'react';
import { CalendarEvent, CalendarEventContent } from '../../types/types';
import dayjs, { Dayjs } from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import endPoints from '../../shared/endPoints.json';
import { getData } from '../../shared/services/apiServices';
import FertilizerForm from '../../components/Forms/Calendar/FertilizerForm';
import QualityCharacteristicsForm from '../../components/Forms/Calendar/QualityCharacteristicsForm';
import VisitsForm from '../../components/Forms/Calendar/VisitsForm';
import PlantProtectionForm from '../../components/Forms/Calendar/PlantProtectionForm';
import HarvestForm from '../../components/Forms/Calendar/HarvestForm';
import IrrigationForm from '../../components/Forms/Calendar/IrrigationForm';
import CultivationCareForm from '../../components/Forms/Calendar/CultivationCareForm';
import { Method } from 'axios';
type Props = {
  selectedDate: Dayjs;
  panel: string;
  activityMutation: boolean;
  selectedTags: string[];
  setActivityMutation: React.Dispatch<React.SetStateAction<boolean>>;
};
const CalendarList: FC<Props> = ({
  selectedDate,
  panel,
  activityMutation,
  selectedTags,
  setActivityMutation,
}) => {
  const { request } = useCreateAxiosInstance();
  const [eventList, setEventList] = useState<CalendarEvent[] | undefined>();
  const [startDate, setStartDate] = useState(dayjs().startOf('day').valueOf());
  const [selectedEvent, setSelectedEvent] = useState<CalendarEventContent>();
  const [endDate, setEndDate] = useState(
    dayjs().endOf('month').startOf('day').valueOf(),
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const commonProps = (): {
    selectedCultivation: undefined;
    setActivityMutation: React.Dispatch<React.SetStateAction<boolean>>;
    activityMutation: boolean;
    selectedDate: Dayjs;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    selectedEvent: CalendarEventContent | undefined;
    successMessage: string;
    apiMethod: Method;
  } => {
    return {
      selectedCultivation: undefined,
      setActivityMutation,
      activityMutation,
      selectedDate,
      setIsModalOpen,
      selectedEvent,
      successMessage: 'Η ενέργεια επεξεργάστηκε με επιτυχία!',
      apiMethod: 'put',
    };
  };
  useEffect(() => {
    if (panel === 'date') {
      setStartDate(selectedDate.startOf('day').valueOf());
      setEndDate(selectedDate.startOf('day').valueOf());
    }
    if (panel === 'month' || panel === 'year') {
      setStartDate(selectedDate.startOf('month').valueOf());
      setEndDate(selectedDate.endOf('month').startOf('day').valueOf());
    }
  }, [selectedDate, panel]);

  const { data: calendarList, isSuccess } = useQuery({
    queryKey: ['calendarList', selectedDate, startDate, activityMutation],
    queryFn: () =>
      getData(
        request,
        `${endPoints.CALENDAR.CALENDAR}?DateFrom=${startDate}&DateTo=${endDate}`,
      ),
    select(data: { data: CalendarEvent[] }) {
      return data.data;
    },
  });
  useEffect(() => {
    if (isSuccess && calendarList) {
      const filteredList =
        selectedTags.length == 0
          ? calendarList
          : calendarList
              .map((item) => ({
                ...item,
                contents: item.contents.filter((content) =>
                  selectedTags.includes(content.name),
                ),
              }))
              .filter((item) => item.contents.length > 0);

      setEventList(filteredList);
    }
  }, [isSuccess, calendarList, selectedTags]);

  const calendarActivityForms: { [key: string]: JSX.Element } = {
    Λίπανση: <FertilizerForm {...commonProps()} />,
    Καλλιεργητικέςφροντίδες: <CultivationCareForm {...commonProps()} />,
    Άρδευση: <IrrigationForm {...commonProps()} />,
    Συγκομιδή: <HarvestForm {...commonProps()} />,
    Φυτοπροστασία: <PlantProtectionForm {...commonProps()} />,
    Επισκέψεις: <VisitsForm {...commonProps()} />,
    Ποιοτικάχαρακτηριστικά: <QualityCharacteristicsForm {...commonProps()} />,
  };
  return (
    <>
      {eventList && eventList?.length > 0 && (
        <Typography.Title level={4} style={{ textAlign: 'center' }}>
          {dayjs(selectedDate).format('MMMM')}
        </Typography.Title>
      )}
      <Row align="middle" justify="center">
        <Col xs={24} lg={20}>
          <>
            {eventList?.map((item, index) => {
              return (
                <div key={index}>
                  <Typography.Text strong>
                    {dayjs(item.dateTime).format('dddd DD')}
                  </Typography.Text>
                  {item.contents.map((item, index) => {
                    return (
                      <div key={index}>
                        <Space
                          direction="vertical"
                          size="middle"
                          style={{ width: '100%' }}
                        >
                          <Badge.Ribbon
                            text={item.name}
                            color={item.color}
                            key={item.id}
                          >
                            <Card
                              hoverable
                              title={item.landParcelName}
                              size="small"
                              onClick={() => {
                                setIsModalOpen(true);
                                setSelectedEvent(item);
                              }}
                            >
                              Καλλιέργεια: {item.landParcelCultivation}
                            </Card>
                          </Badge.Ribbon>
                        </Space>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </>
          <Modal
            open={isModalOpen}
            footer={false}
            width={800}
            destroyOnClose
            onCancel={() => {
              setIsModalOpen(false);
            }}
          >
            {selectedEvent &&
              calendarActivityForms[selectedEvent.name.replace(/\s+/g, '')]}
          </Modal>
        </Col>
      </Row>
    </>
  );
};
export default CalendarList;
