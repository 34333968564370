import {
  Button,
  Col,
  DatePicker,
  Form,
  FormProps,
  InputNumber,
  Row,
  Select,
  App,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import {
  NamedItem,
  LandParcel,
  IrrigationEvent,
  CalendarEventContent,
} from '../../../types/types';
import { baseFormLayout, fullWidth } from '../../../shared/styles/baseStyle';
import LoadingSpin from '../../LoadingSpin/LoadingSpin';
import { getData, mutateData } from '../../../shared/services/apiServices';
import { useMutation, useQuery } from '@tanstack/react-query';
import useCreateAxiosInstance from '../../../shared/hooks/useCreateAxiosInstance';
import endPoints from '../../../shared/endPoints.json';
import type { Dayjs } from 'dayjs';
import { Method } from 'axios';
import dayjs from 'dayjs';
import CommonFormItems from './CommonFormItems';

type Props = {
  selectedCultivation: LandParcel | undefined;
  setActivityMutation: React.Dispatch<React.SetStateAction<boolean>>;
  activityMutation: boolean;
  selectedDate: Dayjs;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEvent: CalendarEventContent | undefined;
  apiMethod: Method;
  successMessage: string;
};
const IrrigationForm: FC<Props> = ({
  selectedCultivation,
  setActivityMutation,
  activityMutation,
  selectedDate,
  setIsModalOpen,
  selectedEvent,
  apiMethod,
  successMessage,
}) => {
  const { message } = App.useApp();
  const { request } = useCreateAxiosInstance();
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState<boolean>(apiMethod == 'put');
  const [irrigationMethodId, setIrrigationMethodId] = useState<number>();
  const dateFormat = 'DD/MM/YYYY';
  const requiredRuleConfig = {
    required: true,
    message: 'Υποχρεωτικό πεδίο',
  };
  const { data: irrigationData } = useQuery({
    queryKey: ['irrigationData'],
    queryFn: () =>
      getData(
        request,
        `${endPoints.CALENDAR.LANDPARCELIRRIGATION}/${selectedEvent?.id}`,
      ),
    select(data: { data: IrrigationEvent }) {
      return data.data;
    },
  });
  useEffect(() => {
    if (irrigationData) {
      setIrrigationMethodId(irrigationData.irrigationMethodId);
      form.setFieldsValue({
        cultivation: selectedEvent?.landParcelCultivation,
        landParcelId: selectedEvent?.landParcelId,
        startDate: dayjs(irrigationData.startDate),
        duration: irrigationData.duration,
        quantity: irrigationData.quantity,
        cubic: irrigationData.cubic,
        irrigationMethodId: irrigationData.irrigationMethod,
      });
    }
  }, [irrigationData]);
  useEffect(() => {
    if (selectedCultivation) {
      form.setFieldsValue({
        cultivation: selectedCultivation?.cultivation,
        landParcelId: selectedCultivation?.id,
        startDate: selectedDate.startOf('day'),
      });
    }
  }, [selectedCultivation]);
  const { data: irrigationMethodItems, isLoading } = useQuery({
    queryKey: ['irrigationMethodItems'],
    queryFn: () => getData(request, endPoints.CALENDAR.IRRIGATIONMETHOD),
    select(data) {
      return data.data.map((item: NamedItem) => ({
        value: item.id,
        label: item.name,
      }));
    },
  });
  const { mutate } = useMutation({
    mutationFn: (formValues) =>
      mutateData(
        request,
        endPoints.CALENDAR.LANDPARCELIRRIGATION,
        formValues,
        apiMethod,
      ),
    onSuccess() {
      message.success(successMessage);
      setIsModalOpen(false);
      setActivityMutation(!activityMutation);
    },
  });
  const onFinish: FormProps['onFinish'] = async (values: any) => {
    const formattedValues = {
      ...values,
      startDate: values.startDate?.$d.getTime(),
      irrigationMethodId: irrigationMethodId,
      id: selectedEvent?.id,
    };
    mutate(formattedValues);
  };
  if (isLoading) return <LoadingSpin />;
  return (
    <>
      <Form
        {...baseFormLayout}
        layout="vertical"
        size="large"
        form={form}
        onFinish={onFinish}
      >
        <CommonFormItems disabled={disabled} setDisabled={setDisabled} />
        <Row>
          <Col xs={12}>
            <Form.Item
              name="startDate"
              label="Ημ/νια  Έναρξης"
              rules={[requiredRuleConfig]}
            >
              <DatePicker
                format={dateFormat}
                placeholder="Ημ/νια  Έναρξης"
                style={fullWidth}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="duration"
              label="Διάρκεια (Ώρες)"
              rules={[requiredRuleConfig]}
            >
              <InputNumber
                type="number"
                style={fullWidth}
                step={1}
                min={0}
                placeholder="Διάρκεια (Ώρες)"
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              name="quantity"
              label="Ποσότητα/εκτάριο m³"
              rules={[requiredRuleConfig]}
            >
              <InputNumber
                type="number"
                style={fullWidth}
                step={1}
                min={0}
                placeholder="Ποσότητα/εκτάριο m³"
                disabled={disabled}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="cubic"
              label="Κυβικά / ώρα m³ γεώτρησης"
              rules={[requiredRuleConfig]}
            >
              <InputNumber
                type="number"
                style={fullWidth}
                step={1}
                min={0}
                placeholder="Κυβικά/ώρα m³"
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              name="irrigationMethodId"
              label="Μέθοδος Άρδευσης"
              rules={[requiredRuleConfig]}
            >
              <Select
                allowClear
                options={irrigationMethodItems}
                placeholder="Μέθοδος Άρδευσης"
                disabled={disabled}
                onSelect={(value) => {
                  setIrrigationMethodId(value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        {apiMethod == 'post' && (
          <Row justify="center">
            <Button htmlType="submit">Προσθήκη</Button>
          </Row>
        )}
        {!disabled && apiMethod == 'put' && (
          <Row justify="center">
            <Button htmlType="submit">Αποθήκευση</Button>
          </Row>
        )}
      </Form>
    </>
  );
};

export default IrrigationForm;
