import {
  Button,
  Col,
  DatePicker,
  Form,
  FormProps,
  Input,
  InputNumber,
  Row,
  Select,
  App,
  Checkbox,
} from 'antd';
import { FC, useContext, useEffect, useState } from 'react';
import CollapsibleContent from '../../CollapsibleContent/CollapsibleContent';
import {
  NamedItem,
  LandParcel,
  PlantProtectionEvent,
  CalendarEventContent,
} from '../../../types/types';
import { baseFormLayout, fullWidth } from '../../../shared/styles/baseStyle';
import LoadingSpin from '../../LoadingSpin/LoadingSpin';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getData, mutateData } from '../../../shared/services/apiServices';
import useCreateAxiosInstance from '../../../shared/hooks/useCreateAxiosInstance';
import endPoints from '../../../shared/endPoints.json';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { Method } from 'axios';
import CommonFormItems from './CommonFormItems';
import useReminderMutation from '../../../shared/hooks/useEventReminderMutation';
import EventReminder from '../../EventReminder/EventReminder';
import { CultivationActivity } from '../../../shared/context/activityMutation';

const checkboxesOptions = [
  { label: '31.6-Β ΚΟΜΦΟΥΖΙΟ', value: '31.6-Β ΚΟΜΦΟΥΖΙΟ' },
  {
    label: '31.6-Γ Εξαπόλυση ωφέλιμων αρπακτικών εντόμων και ακάρεων',
    value: '31.6-Γ Εξαπόλυση ωφέλιμων αρπακτικών εντόμων και ακάρεων',
  },
  {
    label: '31.6-Δ Χρήση εντομοπαθογόνων νηματωδών',
    value: '31.6-Δ Χρήση εντομοπαθογόνων νηματωδών',
  },
  {
    label:
      '31.6-Ε Μηχανική ζιζανιοκτονία και κάλυψη με φυτικά υλικά εδαφοκάλυψης (mulch)',
    value:
      '31.6-Ε Μηχανική ζιζανιοκτονία και κάλυψη με φυτικά υλικά εδαφοκάλυψης (mulch)',
  },
  {
    label: '31.6-ΣΤ Πολλαπλά καλοκαιρινά κλαδέματα για ελαιώνες και οπωρώνες',
    value: '31.6-ΣΤ Πολλαπλά καλοκαιρινά κλαδέματα για ελαιώνες και οπωρώνες',
  },
  {
    label: '31.6-Ζ Χρήση ψεκαστικών ακροφυσίων μειωμένης διασποράς',
    value: '31.6-Ζ Χρήση ψεκαστικών ακροφυσίων μειωμένης διασποράς',
  },
  {
    label: '31.6-Η Διαχείριση των υπολειμμάτων ψεκαστικών υγρών',
    value: '31.6-Η Διαχείριση των υπολειμμάτων ψεκαστικών υγρών',
  },
  {
    label: '31.6-Θ Χρήση γεωργίας ακρίβειας κατά τους ψεκασμούς και τη λίπανση',
    value: '31.6-Θ Χρήση γεωργίας ακρίβειας κατά τους ψεκασμούς και τη λίπανση',
  },
  {
    label: '31.6-ΙΓ Εφαρμογή εθελοντικών οδηγιών ολοκληρωμένης φυτοπροστασίας',
    value: '31.6-ΙΓ Εφαρμογή εθελοντικών οδηγιών ολοκληρωμένης φυτοπροστασίας',
  },
];

type Props = {
  selectedCultivation: LandParcel | undefined;
  selectedDate: Dayjs | undefined;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEvent: CalendarEventContent | undefined;
  apiMethod: Method;
  successMessage: string;
  viewOnly: boolean;
  disabledDate: any;
};
const PlantProtectionForm: FC<Props> = ({
  selectedCultivation,
  selectedDate,
  setIsModalOpen,
  selectedEvent,
  apiMethod,
  successMessage,
  viewOnly,
  disabledDate,
}) => {
  const { request } = useCreateAxiosInstance();
  const { activityMutation, setActivityMutation } =
    useContext(CultivationActivity);
  const { mutate: mutateReminder } = useReminderMutation();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const dateFormat = 'DD/MM/YYYY';
  const [weatherId, setWeatherId] = useState<number>();
  const [doseUnitId, setDoseUnitId] = useState<number>();
  const requiredRuleConfig = {
    required: true,
    message: 'Υποχρεωτικό πεδίο',
  };
  const { data: plantProtectionData } = useQuery({
    queryKey: ['plantProtectionData'],
    queryFn: () =>
      getData(
        request,
        `${endPoints.CALENDAR.LANDPARCELPLANTPROTECTION}/${selectedEvent?.id}`,
      ),
    select(data: { data: PlantProtectionEvent }) {
      return data.data;
    },
    enabled: !!selectedEvent,
  });

  useEffect(() => {
    if (plantProtectionData) {
      setDoseUnitId(plantProtectionData.doseUnitId);
      setWeatherId(plantProtectionData.weatherId);
      form.setFieldsValue({
        cultivation: !selectedEvent?.reminderId
          ? selectedEvent?.landParcelCultivation
          : selectedEvent?.cultivationName,
        landParcelId: selectedEvent?.landParcelId,
        activeSubstance: plantProtectionData.activeSubstance,
        commercialFormulation: plantProtectionData.commercialFormulation,
        applicationDose: plantProtectionData.applicationDose,
        perimeterOfSafety: plantProtectionData.perimeterOfSafety,
        volumeOfSpraySolution: plantProtectionData.volumeOfSpraySolution,
        harvestAbility: plantProtectionData.harvestAbility,
        observations: plantProtectionData.observations,
        plantProtectionDate: !selectedEvent?.reminderId
          ? dayjs(plantProtectionData.plantProtectionDate)
          : undefined,
        weatherId: plantProtectionData.weather,
        doseUnitId: plantProtectionData.doseUnit,
      });
    }
  }, [plantProtectionData]);

  useEffect(() => {
    if (selectedCultivation) {
      form.setFieldsValue({
        cultivation: selectedCultivation?.cultivation,
        landParcelId: selectedCultivation?.id,
        plantProtectionDate: selectedDate?.startOf('day'),
      });
    }
  }, [selectedCultivation]);

  const { data: weatherItems, isLoading: weatherItemsLoading } = useQuery({
    queryKey: ['weatherItems'],
    queryFn: () => getData(request, endPoints.CALENDAR.WEATHER),
    select(data: { data: NamedItem[] }) {
      return data.data.map((item: NamedItem) => ({
        value: item.id,
        label: item.name,
      }));
    },
  });
  const { data: doseUnitItems, isLoading: doseUnitItemsLoading } = useQuery({
    queryKey: ['doseUnitItems'],
    queryFn: () => getData(request, endPoints.CALENDAR.DOSEUNIT),
    select(data: { data: NamedItem[] }) {
      return data.data.map((item: NamedItem) => ({
        value: item.id,
        label: item.name,
      }));
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (formValues: PlantProtectionEvent & { reminderDate: number }) =>
      mutateData(
        request,
        endPoints.CALENDAR.LANDPARCELPLANTPROTECTION,
        formValues,
        apiMethod,
      ),
    onSuccess(data, variables) {
      message.success(successMessage);
      setIsModalOpen(false);
      setActivityMutation(!activityMutation);
      variables?.reminderDate &&
        mutateReminder({
          eventId: data.data,
          eventType: endPoints.CALENDAR.LANDPARCELPLANTPROTECTION,
          reminderDate: variables.reminderDate,
        });
    },
  });
  const onFinish: FormProps['onFinish'] = async (values: any) => {
    const formattedValues = {
      ...values,
      plantProtectionDate: dayjs(values.plantProtectionDate?.$d)
        .startOf('day')
        .valueOf(),
      weatherId: weatherId,
      doseUnitId: doseUnitId,
      id: selectedEvent?.id,
      reminderDate: values?.reminderDate?.$d.getTime(),
    };
    mutate(formattedValues);
  };
  if (doseUnitItemsLoading || weatherItemsLoading || isPending)
    return <LoadingSpin />;
  return (
    <Form
      {...baseFormLayout}
      layout="vertical"
      size="large"
      form={form}
      onFinish={onFinish}
    >
      <CommonFormItems />
      <Row>
        <Col xs={12}>
          <Form.Item
            name="plantProtectionDate"
            label="Ημερομηνία"
            rules={[requiredRuleConfig]}
          >
            <DatePicker
              format={dateFormat}
              placeholder="Ημερομηνία"
              style={fullWidth}
              disabled={viewOnly}
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name="weatherId"
            label="Καιρός κατά την Εφαρμογή"
            rules={[requiredRuleConfig]}
          >
            <Select
              allowClear
              options={weatherItems}
              placeholder="Καιρός κατά την Εφαρμογή"
              disabled={viewOnly}
              onSelect={(value) => {
                setWeatherId(value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.Item
            name="activeSubstance"
            label="Δραστική ουσία"
            rules={[requiredRuleConfig]}
          >
            <Input placeholder="Δραστική ουσία" disabled={viewOnly} />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name="commercialFormulation"
            label="Εμπορικό σκεύασμα"
            rules={[requiredRuleConfig]}
          >
            <Input placeholder="Εμπορικό σκεύασμα" disabled={viewOnly} />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="start">
        <Col xs={12}>
          <Form.Item
            name="perimeterOfSafety"
            label="Όρια Ασφάλειας (PHI)"
            rules={[requiredRuleConfig]}
          >
            <Input placeholder="Όρια Ασφάλειας (PHI)" disabled={viewOnly} />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name="doseUnitId"
            label="Μονάδα Δόσης "
            rules={[requiredRuleConfig]}
          >
            <Select
              allowClear
              options={doseUnitItems}
              placeholder="Μονάδα Δόσης"
              disabled={viewOnly}
              onSelect={(value) => {
                setDoseUnitId(value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={12}>
          <Form.Item
            name="applicationDose"
            label="Δόση εφαρμογής "
            rules={[requiredRuleConfig]}
          >
            <InputNumber
              type="number"
              style={fullWidth}
              step={1}
              min={0}
              placeholder="Δόση εφαρμογής "
              disabled={viewOnly}
            />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name="harvestAbility"
            label="Δυνατότητα Συγκομιδής"
            rules={[requiredRuleConfig]}
          >
            <Input placeholder="Δυνατότητα Συγκομιδής" disabled={viewOnly} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.Item
            name="volumeOfSpraySolution"
            label="Όγκος Ψεκαστικού Διαλύματος (lt/ha)"
            rules={[requiredRuleConfig]}
          >
            <InputNumber
              type="number"
              style={fullWidth}
              step={1}
              min={0}
              placeholder="Όγκος Ψεκαστικού Διαλύματος"
              disabled={viewOnly}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <CollapsibleContent
            activeKey={undefined}
            items={[
              {
                key: '1',
                label: 'Οικολογικά σχήματα',
                children: (
                  <Row>
                    {checkboxesOptions?.map((item, key) => (
                      <Col key={key} span={24}>
                        <Checkbox value={item.value}>{item.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                ),
              },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Form.Item
            name="observations"
            label="Σημειώσεις/Παρατηρήσεις"
            rules={[requiredRuleConfig]}
          >
            <Input.TextArea
              rows={3}
              placeholder="Σημειώσεις/Παρατηρήσεις"
              disabled={viewOnly}
            />
          </Form.Item>
        </Col>
      </Row>
      {!viewOnly && plantProtectionData && (
        <EventReminder
          reminderDate={
            apiMethod == 'put' ? plantProtectionData?.reminderDate : null
          }
        />
      )}
      {apiMethod == 'post' && (
        <Row justify="center">
          <Button htmlType="submit">Προσθήκη</Button>
        </Row>
      )}
      {!viewOnly && apiMethod == 'put' && (
        <Row justify="center">
          <Button htmlType="submit">Αποθήκευση</Button>
        </Row>
      )}
    </Form>
  );
};
export default PlantProtectionForm;
