import { useQuery } from '@tanstack/react-query';
import { createContext, FC, useMemo, useContext, useEffect } from 'react';
import { getData } from '../services/apiServices';
import useCreateAxiosInstance from '../hooks/useCreateAxiosInstance';
import endPoints from '../endPoints.json';
import { EAESeasonItems } from '../../types/types';
import { AuthContext } from './auth-context';

interface EAESeasonContextType {
  eaeSeason: EAESeasonItems[] | undefined;
  eaeSeasonLoading: boolean;
}

export const EAESeasonContext = createContext<EAESeasonContextType>({
  eaeSeason: undefined,
  eaeSeasonLoading: true,
});
type Props = {
  children: any;
};
const EAESeasonProvider: FC<Props> = ({ children }) => {
  const { token } = useContext(AuthContext);
  const { request } = useCreateAxiosInstance();

  const { data: eaeSeason, isLoading: eaeSeasonLoading } = useQuery({
    queryKey: ['eaeSeasonItems', token],
    queryFn: () => getData(request, endPoints.EAESEASON.EAESEASON),
    select(data) {
      return data.data;
    },
    enabled: !!token,
  });

  useEffect(() => {
    if (eaeSeason) {
      const storage =
        sessionStorage.length === 0 ? localStorage : sessionStorage;
      storage.setItem('opekepeApplicationYear', eaeSeason[0].eae.toString());
    }
  }, [eaeSeason]);
  const memoedValue = useMemo(
    () => ({
      eaeSeason,
      eaeSeasonLoading,
    }),
    [eaeSeason, eaeSeasonLoading],
  );
  return (
    <EAESeasonContext.Provider value={memoedValue}>
      {children}
    </EAESeasonContext.Provider>
  );
};
export default EAESeasonProvider;
