import { Grid } from 'antd';

const useGetFontSize = () => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const fontSizeTitle = screens.xxl
    ? 60
    : screens.xl
      ? 52
      : screens.lg
        ? 52
        : screens.md
          ? 43
          : screens.sm
            ? 35
            : 35;

  const fontSizeParagraph = screens.xxl
    ? 32
    : screens.xl
      ? 28
      : screens.lg
        ? 22
        : screens.md
          ? 22
          : 18;
  const fontSizeText = screens.xxl
    ? 22
    : screens.xl
      ? 18
      : screens.lg
        ? 15
        : screens.md
          ? 15
          : screens.sm
            ? 16
            : 14;

  return { fontSizeText, fontSizeTitle, fontSizeParagraph };
};
export default useGetFontSize;
