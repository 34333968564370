import { baseColors } from '../baseStyle';
export const config = {
  token: {
    colorPrimary: baseColors.fsBlue,
    colorInfo: baseColors.fsBlue,
    borderRadiusLG: 12,
    boxShadow: '0 20px 40px rgba(0,0,0,0.4)',
  },
  components: {
    Button: {
      borderRadius: 5,
      borderRadiusLG: 5,
      colorLink: baseColors.fsBlue,
      colorLinkActive: baseColors.fsBlue,
      colorLinkHover: baseColors.fsBlue,
      colorTextDisabled: baseColors.fsLightBlue,
    },
    Card: {
      colorTextHeading: baseColors.fsBlue,
      paddingLG: 10,
      headerHeight: 40,
      headerFontSize: 20,
      lineWidth: 0,
    },
    Layout: {
      headerColor: '#f5f5f5',
      headerHeight: 58,
      headerBg: '#f5f5f5',
      triggerBg: baseColors.fsGreyBlue,
      siderBg: baseColors.fsGreyBlue,
    },
    Input: {
      borderRadius: 5,
      borderRadiusLG: 5,
      colorBgContainerDisabled: 'white',
      colorTextDisabled: 'black',
    },
    InputNumber: {
      colorBgContainerDisabled: 'white',
      colorTextDisabled: 'black',
    },
    Spin: {},
    Form: { verticalLabelPadding: 0, fontSize: 12 },
    Table: {},
    Radio: {},
    Pagination: {},
    Select: {
      colorBgContainerDisabled: 'white',
      colorTextDisabled: 'black',
    },
    Dropdown: {
      fontSize: 15,
      borderRadius: 12,
      borderRadiusLG: 12,
      boxShadowPopoverArrow: '2 20px 40px rgba(0,0,0,0.4)',
      boxShadowSecondary: '2 20px 40px rgba(0,0,0,0.4)',
      zIndexPopup: 2000,
      colorBgElevated: baseColors.fsLightBlue,
    },
    Menu: {
      itemHeight: 50,
      padding: 8,
      paddingXL: 8,
      itemActiveBg: baseColors.fsBlue,
      itemDisabledColor: 'white',
      subMenuItemBg: baseColors.fsBlue,
      itemBg: baseColors.fsGreyBlue,
      itemSelectedColor: 'white',
      itemColor: 'white',
      itemHoverColor: 'white',
      popupBg: baseColors.fsGreyBlue,
      iconSize: 17,
    },
    List: {
      itemPadding: '1px 0',
      padding: 0,
    },
    Steps: {
      colorPrimary: baseColors.fsBlue,
    },
    Message: {
      fontSize: 16,
      fontSizeLG: 20,
      lineHeight: 0.571428571428571,
      boxShadow: '2 20px 40px rgba(0,0,0,0.4)',
    },
  },
};

export const messageConfig = { duration: 5.5, top: 45 };
