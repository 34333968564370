import axios, { Method, AxiosRequestConfig } from 'axios';
import { App } from 'antd';
import { useContext } from 'react';
import { AuthContext } from '../context/auth-context';
import useCheckRefresh from './useCheckRefresh';
import { getBaseUrl } from '../services/helperFunctions';
interface RequestParams {
  url: string;
  method: Method;
  data?: any;
  responseType?: any;
}

let isRefreshing = false;
let failedQueue: Array<{
  resolve: (value?: unknown) => void;
  reject: (reason?: any) => void;
}> = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });
  failedQueue = [];
};

const useCreateAxiosInstance = (): any => {
  const { message } = App.useApp();
  const { token, refreshToken } = useContext(AuthContext);
  const { mutateAsync } = useCheckRefresh();

  const axiosInstance = axios.create({
    baseURL: getBaseUrl(),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest: AxiosRequestConfig & { _retry?: boolean } =
        error.config;

      if (error?.code === 'ERR_NETWORK') {
        message.error('Παρακαλώ ελέγξτε τη σύνδεση σας');
        return;
      }

      if (error?.response?.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((newToken) => {
              if (originalRequest.headers) {
                originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
              }
              return axiosInstance(originalRequest);
            })
            .catch((err) => Promise.reject(err));
        }
        originalRequest._retry = true;
        isRefreshing = true;

        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
          try {
            const newTokens = await mutateAsync({
              accessToken: token,
              refreshToken,
            });
            processQueue(null, newTokens.data.accessToken);
            if (originalRequest.headers) {
              originalRequest.headers['Authorization'] =
                `Bearer ${newTokens.data.accessToken}`;
            }
            resolve(axiosInstance(originalRequest));
          } catch (refreshError) {
            processQueue(refreshError, null);
            reject(refreshError);
          } finally {
            isRefreshing = false;
          }
        });
      }

      if (error?.response?.status === 500) {
        message.error('Παρουσιάστηκε σφάλμα');
        return;
      }

      if (axios.isAxiosError(error)) {
        console.error('Axios Error:', error.message);
      } else {
        console.error('Unexpected Error:', error);
      }

      return Promise.reject(error);
    },
  );

  const request = async ({
    url,
    method,
    data,
    responseType,
  }: RequestParams) => {
    try {
      return await axiosInstance.request({
        url,
        method,
        data,
        responseType,
      });
    } catch (error) {
      console.error('API request error:', error);
      throw error;
    }
  };

  return { request };
};

export default useCreateAxiosInstance;
