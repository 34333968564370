import { FC, useEffect, useMemo, useState } from 'react';
import type { CalendarProps } from 'antd';
import {
  Badge,
  Button,
  Calendar,
  Card,
  Col,
  Flex,
  Modal,
  Row,
  Tag,
  Typography,
} from 'antd';
import type { Dayjs, OpUnitType } from 'dayjs';
import dayjs from 'dayjs';
import endPoints from '../../shared/endPoints.json';
import { useQuery } from '@tanstack/react-query';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import { getData } from '../../shared/services/apiServices';
import { dateEpochToGR } from '../../shared/services/helperFunctions';
import CalendarList from './CalendarList';
import { addCatalogueButton, cardStyle } from '../../shared/styles/baseStyle';
import {
  CalendarEventContent,
  CalendarEvent,
  LandParcel,
} from '../../types/types';
import { AppstoreAddOutlined } from '@ant-design/icons';
import AddEvent from './AddEvent';

const getLastDay = (date: Dayjs, panel: OpUnitType) => {
  return date.endOf(panel).startOf('day').valueOf();
};
const getFirstDay = (date: Dayjs, panel: OpUnitType) => {
  return date.startOf(panel).valueOf();
};
const tagsData = [
  { name: 'Λίπανση', color: '#ffa500' },
  { name: 'Φυτοπροστασία', color: '#a020f0' },
  { name: 'Άρδευση', color: '#0aaef0' },
  { name: 'Καλλιεργητικές φροντίδες', color: '#964b00' },
  { name: 'Ποιοτικά χαρακτηριστικά', color: '#008000' },
  { name: 'Συγκομιδή', color: '#e9b40b' },
  { name: 'Επισκέψεις', color: '#ff0000' },
];
const FarmerCalendar: FC = () => {
  type ExtendetCalendarData = CalendarEventContent & { dateTime: number };
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [activityMutation, setActivityMutation] = useState(false);
  const [selectedCultivation, setSelectedCultivation] = useState<LandParcel>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [startDate, setStartDate] = useState(getFirstDay(dayjs(), 'month'));
  const [endDate, setEndDate] = useState(getLastDay(dayjs(), 'month'));
  const [panel, setPanel] = useState('month');
  const { request } = useCreateAxiosInstance();
  const [data, setData] = useState<CalendarEvent[] | undefined>();
  const { data: calendarData, isSuccess } = useQuery({
    queryKey: ['calendarData', startDate, endDate, activityMutation],
    queryFn: () =>
      getData(
        request,
        `${endPoints.CALENDAR.CALENDAR}?DateFrom=${startDate}&DateTo=${endDate}`,
      ),
    select(data: { data: CalendarEvent[] }) {
      return data.data;
    },
  });
  const filterCalendarDataByTags = (data: CalendarEvent[]) => {
    if (selectedTags.length == 0) return data;
    return data
      .map((item) => ({
        ...item,
        contents: item.contents.filter((content) =>
          selectedTags.includes(content.name),
        ),
      }))
      .filter((item) => item.contents.length > 0);
  };
  useEffect(() => {
    if (calendarData && isSuccess) {
      setData(filterCalendarDataByTags(calendarData));
    }
  }, [selectedTags, calendarData, isSuccess]);

  const filteredData = useMemo(() => {
    if (!calendarData || !isSuccess) return [];
    return filterCalendarDataByTags(calendarData);
  }, [calendarData, selectedTags, isSuccess]);
  const groupedDataByMonth = useMemo(() => {
    return filteredData.reduce(
      (acc, item) => {
        const monthKey = dayjs(item.dateTime).startOf('month').valueOf();
        acc[monthKey] = acc[monthKey] || [];
        acc[monthKey].push(
          ...item.contents.map((content) => ({
            ...content,
            dateTime: item.dateTime,
          })),
        );
        return acc;
      },
      {} as Record<string, ExtendetCalendarData[]>,
    );
  }, [filteredData]);

  const getMonthData = (value: Dayjs): ExtendetCalendarData[] => {
    const monthKey = value.startOf('month').valueOf();
    return groupedDataByMonth[monthKey] || [];
  };

  const monthCellRender = (value: Dayjs) => {
    const monthData = getMonthData(value);
    return monthData.length ? (
      <>
        {monthData.map((item, index) => (
          <li key={index}>
            <Badge
              key={index}
              color={item.color}
              text={
                <Typography.Text>
                  {item.name}
                  {', '}
                  {item.landParcelName}, {dateEpochToGR(item.dateTime)}
                </Typography.Text>
              }
            />
          </li>
        ))}
      </>
    ) : (
      <></>
    );
  };
  const getCalendarList = (epoch: number): CalendarEventContent[] =>
    data?.find((item: CalendarEvent) => item.dateTime === epoch)?.contents ||
    [];
  const dateCellRender = (value: Dayjs) => {
    const calendarItems = getCalendarList(value.startOf('day').valueOf());
    return (
      <>
        {calendarItems.map((item, index) => (
          <li key={index}>
            <Badge
              key={index}
              color={item.color}
              text={
                <Typography.Text>
                  {item.name}
                  {', '}
                  {item.landParcelName}
                </Typography.Text>
              }
            />
          </li>
        ))}
      </>
    );
  };
  const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    if (info.type === 'month') return monthCellRender(current);
    return info.originNode;
  };
  const handleChange = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
  };

  const hexToRgba = (hex: string, alpha: number): string => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  return (
    <Row justify="center">
      <Col xs={24} md={20}>
        <Flex gap="small" vertical>
          <Button
            onClick={() => {
              setIsModalOpen(true);
            }}
            style={addCatalogueButton}
            type="primary"
            size="large"
            icon={<AppstoreAddOutlined />}
          >
            Προσθήκη
          </Button>
          <Row>
            <Flex gap={0} wrap align="center">
              <span>Ενέργειες: </span>
              {tagsData.map((tag) => (
                <Tag.CheckableTag
                  key={tag.name}
                  style={{
                    color: tag.color,
                    backgroundColor: selectedTags.includes(tag.name)
                      ? `${hexToRgba(tag.color, 0.1)}`
                      : 'transparent',
                  }}
                  checked={selectedTags.includes(tag.name)}
                  onChange={(checked) => handleChange(tag.name, checked)}
                >
                  {tag.name}
                </Tag.CheckableTag>
              ))}
            </Flex>
          </Row>
          <Modal
            open={isModalOpen}
            footer={false}
            width={800}
            destroyOnClose
            onCancel={() => {
              setIsModalOpen(false);
              setSelectedCultivation(undefined);
            }}
          >
            <AddEvent
              selectedCultivation={selectedCultivation}
              setSelectedCultivation={setSelectedCultivation}
              selectedDate={selectedDate}
              setIsModalOpen={setIsModalOpen}
              setActivityMutation={setActivityMutation}
              activityMutation={activityMutation}
            ></AddEvent>
          </Modal>
          <Card style={cardStyle}>
            <Row align="middle" justify="center">
              <Col span={20} style={{ padding: '20' }}>
                <Calendar
                  cellRender={cellRender}
                  onSelect={(date, info) => {
                    setSelectedDate(date);
                    setPanel(info.source);
                  }}
                  onPanelChange={(date, info) => {
                    setStartDate(getFirstDay(date, info));
                    setEndDate(getLastDay(date, info));
                    setPanel(info);
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Flex>
      </Col>
      <Col xs={24} md={20} lg={8}>
        <CalendarList
          setActivityMutation={setActivityMutation}
          selectedDate={selectedDate}
          panel={panel}
          activityMutation={activityMutation}
          selectedTags={selectedTags}
        ></CalendarList>
      </Col>
    </Row>
  );
};

export default FarmerCalendar;
