import {
  Col,
  Row,
  Button,
  Form,
  type FormProps,
  Input,
  Typography,
  Checkbox,
  Modal,
  App,
  Flex,
  Divider,
} from 'antd';
import { FC, useContext, useState } from 'react';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import {
  formStyle,
  cardRow,
  forgotPasswordbutton,
  checkboxRow,
  checkboxLink,
  loginButton,
} from './style';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../shared/context/auth-context';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import { useMutation } from '@tanstack/react-query';
import { mutateData } from '../../shared/services/apiServices';
import endPoints from '../../shared/endPoints.json';
import { AxiosError } from 'axios';
import LoginLayout from '../../components/LoginLayout/LoginLayout';

type FieldType = {
  email: string;
  password: string;
};
const Login: FC = () => {
  const { message } = App.useApp();
  const [checkAuthentication, setCheckAuthentication] = useState(false);
  const [checkPrivacyPolicy, setCheckPrivacyPolicy] = useState(false);
  const [checkTermsOfUse, setCheckTermsOfUse] = useState(false);
  const [isModalOpenAuthentication, setIsModalOpenAuthentication] =
    useState(false);
  useState(false);
  const authenticationTitle = 'Δήλωση Αυθεντικοποίησης';
  const privacyPolicyTitle = 'Πολιτική Απορρήτου';
  const termsOfUseTitle = 'Όροι Χρήσης';
  const authenticationStatement =
    'Τα στοιχεία που υποβάλλονται στην παρούσα πλατφόρμα (https://www.agros-online.gr) είναι ακριβή και αληθή. Δεσμεύομαι να συμμορφώνομαι με την σχετική Ελληνική νομοθεσία και με τους εφαρμοστέους κανόνες του δικαίου της Ένωσης.';
  const { login } = useContext(AuthContext);
  const { request } = useCreateAxiosInstance();
  const navigate = useNavigate();
  const { Text } = Typography;
  const [isLoading, setIsLoading] = useState(false);

  const { mutate } = useMutation({
    mutationFn: ({ email, password }: FieldType) =>
      mutateData(
        request,
        endPoints.USER.LOGIN,
        { username: email, password },
        'post',
      ),
    onSuccess(data) {
      login(data.data);
    },
    onError(error: AxiosError) {
      if (error.status === 400) {
        message.error('Τα στοιχεία εισόδου που καταχωρήσατε δεν είναι σωστά');
      }
    },
    onSettled() {
      setIsLoading(false);
      setCheckAuthentication(false);
      setCheckPrivacyPolicy(false);
      setCheckTermsOfUse(false);
    },
  });

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    setIsLoading(true);
    mutate({ email: values.email, password: values.password });
  };
  const openTermsOfUsePDF = () => {
    window.open('/pdfs/termsOfUse.pdf', '_blank');
  };
  const openPrivacyPolicyPDF = () => {
    window.open('/pdfs/privacyPolicy.pdf', '_blank');
  };
  const openEmailProvider = () => {
    window.location.href = 'mailto:info@agros-online.gr';
  };

  return (
    <LoginLayout loading={isLoading}>
      <>
        <Row justify="center">
          <Text>Για τη δημιουργία λογαριασμού επικοινωνήστε μαζί μας στo</Text>
        </Row>
        <Row justify="center">
          <Button type="link" onClick={openEmailProvider}>
            info@agros-online.gr
          </Button>
        </Row>
        <Form size="large" onFinish={onFinish} style={formStyle}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Εισάγετε το email σας!',
                type: 'email',
              },
            ]}
          >
            <Input prefix={<MailOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Εισάγετε τον κωδικό πρόσβασής σας!',
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Κωδικός Πρόσβασης"
            />
          </Form.Item>
          <Row style={checkboxRow}>
            <Checkbox
              onChange={(e) => {
                setCheckTermsOfUse(e.target.checked);
              }}
            >
              <Button
                type="link"
                onClick={openTermsOfUsePDF}
                style={checkboxLink}
              >
                {termsOfUseTitle}
              </Button>
            </Checkbox>
          </Row>
          <Row style={checkboxRow}>
            <Checkbox
              onChange={(e) => {
                setCheckPrivacyPolicy(e.target.checked);
              }}
            >
              <Button
                type="link"
                onClick={openPrivacyPolicyPDF}
                style={checkboxLink}
              >
                {privacyPolicyTitle}
              </Button>
            </Checkbox>
          </Row>
          <Row style={checkboxRow}>
            <Checkbox
              onChange={(e) => {
                setCheckAuthentication(e.target.checked);
              }}
            >
              <Button
                type="link"
                onClick={() => {
                  setIsModalOpenAuthentication(true);
                }}
                style={checkboxLink}
              >
                {authenticationTitle}
              </Button>
            </Checkbox>
            <Modal
              title={authenticationTitle}
              footer={null}
              open={isModalOpenAuthentication}
              onCancel={() => {
                setIsModalOpenAuthentication(false);
              }}
            >
              {authenticationStatement}
            </Modal>
          </Row>
          <Row style={cardRow}>
            <Col span={24}>
              <Flex vertical gap="small">
                <Row justify="center">
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={loginButton}
                    disabled={
                      !(
                        checkAuthentication &&
                        checkPrivacyPolicy &&
                        checkTermsOfUse
                      )
                    }
                  >
                    Σύνδεση
                  </Button>
                </Row>
                <Button
                  type="link"
                  style={forgotPasswordbutton}
                  onClick={() => {
                    navigate('/forgotPassword');
                  }}
                >
                  Ξεχάσατε τον κωδικό;
                </Button>
              </Flex>
            </Col>
          </Row>
        </Form>
      </>
    </LoginLayout>
  );
};
export default Login;
