import { useContext } from 'react';
import { AuthContext } from '../../shared/context/auth-context';
import Home from './Home';
import UsersCatalogue from '../Users/UsersCatalogue';

const RoleBasedHome = () => {
  const { role } = useContext(AuthContext);
  return role === 'Γεωπόνος' ? <UsersCatalogue /> : <Home />;
};

export default RoleBasedHome;
