import { useMutation } from '@tanstack/react-query';
import endPoints from '../endPoints.json';
import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from '../context/auth-context';
import { App } from 'antd';
import { getBaseUrl } from '../services/helperFunctions';
type RefreshTokenBody = {
  accessToken: string | null;
  refreshToken: string | null;
};
const refreshAxios = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});
const useCheckRefresh = () => {
  const { message } = App.useApp();
  const { setToken, setRefreshToken, logout } = useContext(AuthContext);
  return useMutation({
    mutationFn: (values: RefreshTokenBody) =>
      refreshAxios.post(endPoints.USER.REFRESH, values),
    onSuccess(data) {
      setToken(data.data?.accessToken);
      setRefreshToken(data.data?.refreshToken);
      const storage =
        sessionStorage.length === 0 ? localStorage : sessionStorage;
      storage.setItem('accessToken', data.data?.accessToken);
      storage.setItem('refreshToken', data.data?.refreshToken);

      return data;
    },
    onError() {
      message.info('Παρακαλώ συνδεθείτε ξανά!');
      logout();
    },
  });
};
export default useCheckRefresh;
