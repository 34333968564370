import React, { useState, useContext, useEffect } from 'react';
import { Button, Tabs } from 'antd';
import AgriculturalInflowCatalogue from './AgriculturalInflow/AgriculturalInflowCatalogue';
import AgriculturalOuflowCatalogue from './AgriculturalOutflow/AgriculturalOutflowCatalogue';
import {
  ImportOutlined,
  ExportOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import EAESeason from '../../components/EAESeason/EAESeason';
import { EAESeasonContext } from '../../shared/context/EAESeason';
import type { TabsProps } from 'antd';
import LoadingSpin from '../../components/LoadingSpin/LoadingSpin';
import DownloadFile from '../../components/UploadFile/DownloadFile';
import endPoints from '../../shared/endPoints.json';

const AgriculturalFlowsTabs: React.FC = () => {
  const { eaeSeasonLoading } = useContext(EAESeasonContext);
  const [selectedEaeSeason, setSelectedEaeSeason] = useState<any>();
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Εισροές',
      children: selectedEaeSeason && (
        <AgriculturalInflowCatalogue
          selectedEaeSeasonValue={selectedEaeSeason.id}
        />
      ),
      icon: <ImportOutlined />,
    },
    {
      key: '2',
      label: 'Εκροές',
      children: selectedEaeSeason && (
        <AgriculturalOuflowCatalogue
          selectedEaeSeasonValue={selectedEaeSeason.id}
        />
      ),
      icon: <ExportOutlined />,
    },
  ];
  if (eaeSeasonLoading) return <LoadingSpin />;
  return (
    <div>
      <EAESeason setSelectedEaeSeason={setSelectedEaeSeason} />
      <Tabs
        size="large"
        tabBarGutter={30}
        centered={true}
        defaultActiveKey="1"
        items={items}
        tabBarExtraContent={
          <DownloadFile
            url={`${endPoints.AGRICULTURAL.DOWNLOADEXCEL}?EaeSeasonId=${selectedEaeSeason?.id}`}
            fileName={null}
            fileType={'excel'}
          >
            <Button icon={<DownloadOutlined />}>Εισροές-Εκροές</Button>
          </DownloadFile>
        }
      />
    </div>
  );
};

export default AgriculturalFlowsTabs;
