import {
  Button,
  Col,
  DatePicker,
  Form,
  FormProps,
  InputNumber,
  Row,
  Select,
  App,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import {
  NamedItem,
  LandParcel,
  CultivationCareEvent,
  CalendarEventContent,
} from '../../../types/types';
import { baseFormLayout, fullWidth } from '../../../shared/styles/baseStyle';
import LoadingSpin from '../../LoadingSpin/LoadingSpin';
import useCreateAxiosInstance from '../../../shared/hooks/useCreateAxiosInstance';
import { getData, mutateData } from '../../../shared/services/apiServices';
import { useMutation, useQuery } from '@tanstack/react-query';
import endPoints from '../../../shared/endPoints.json';
import type { Dayjs } from 'dayjs';
import { Method } from 'axios';
import dayjs from 'dayjs';
import CommonFormItems from './CommonFormItems';

type Props = {
  selectedCultivation: LandParcel | undefined;
  setActivityMutation: React.Dispatch<React.SetStateAction<boolean>>;
  activityMutation: boolean;
  selectedDate: Dayjs;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEvent: CalendarEventContent | undefined;
  apiMethod: Method;
  successMessage: string;
};
const CultivationCareForm: FC<Props> = ({
  selectedCultivation,
  setActivityMutation,
  activityMutation,
  selectedDate,
  setIsModalOpen,
  selectedEvent,
  apiMethod,
  successMessage,
}) => {
  const { request } = useCreateAxiosInstance();
  const [form] = Form.useForm();
  const dateFormat = 'DD/MM/YYYY';
  const requiredRuleConfig = {
    required: true,
    message: 'Υποχρεωτικό πεδίο',
  };
  const { message } = App.useApp();
  const [disabled, setDisabled] = useState(apiMethod == 'put');
  const [cultivationCareId, setCultivationCareId] = useState<number>();
  const { data: cultivationCareData } = useQuery({
    queryKey: ['cultivationCareData'],
    queryFn: () =>
      getData(
        request,
        `${endPoints.CALENDAR.LANDPARCELCULTIVATIONCARE}/${selectedEvent?.id}`,
      ),
    select(data: { data: CultivationCareEvent }) {
      return data.data;
    },
  });

  const { data: cultivationCareItems, isLoading } = useQuery({
    queryKey: ['cultivationCareItems'],
    queryFn: () => getData(request, endPoints.CALENDAR.CULTIVATIONCARE),
    select(data) {
      return data.data.map((item: NamedItem) => ({
        value: item.id,
        label: item.name,
      }));
    },
  });

  const { mutate } = useMutation({
    mutationFn: (formValues) =>
      mutateData(
        request,
        endPoints.CALENDAR.LANDPARCELCULTIVATIONCARE,
        formValues,
        apiMethod,
      ),
    onSuccess() {
      message.success(successMessage);
      setIsModalOpen(false);
      setActivityMutation(!activityMutation);
    },
  });
  useEffect(() => {
    if (selectedCultivation) {
      form.setFieldsValue({
        cultivation: selectedCultivation?.cultivation,
        landParcelId: selectedCultivation?.id,
        startDate: selectedDate?.startOf('day'),
      });
    }
  }, [selectedCultivation]);
  useEffect(() => {
    if (cultivationCareData) {
      setCultivationCareId(cultivationCareData.cultivationCareId);
      form.setFieldsValue({
        cultivation: selectedEvent?.landParcelCultivation,
        landParcelId: selectedEvent?.landParcelId,
        startDate: dayjs(cultivationCareData.startDate),
        duration: cultivationCareData.duration,
        personnel: cultivationCareData.personnel,
        cultivationCareId: cultivationCareData.cultivationCare,
      });
    }
  }, [cultivationCareData]);
  const onFinish: FormProps['onFinish'] = async (values: any) => {
    const formattedValues = {
      ...values,
      startDate: values.startDate?.$d.getTime(),
      cultivationCareId: cultivationCareId,
      id: selectedEvent?.id,
    };

    mutate(formattedValues);
  };
  if (isLoading) return <LoadingSpin />;
  return (
    <>
      <Form
        {...baseFormLayout}
        layout="vertical"
        size="large"
        form={form}
        onFinish={onFinish}
      >
        <CommonFormItems disabled={disabled} setDisabled={setDisabled} />
        <Row>
          <Col xs={12}>
            <Form.Item
              name="cultivationCareId"
              label="Καλλιεργητικές Φροντίδες"
              rules={[requiredRuleConfig]}
            >
              <Select
                allowClear
                options={cultivationCareItems}
                placeholder="Καλλιεργητικές Φροντίδες"
                disabled={disabled}
                onSelect={(value) => {
                  setCultivationCareId(value);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="startDate"
              label="Ημ/νια Έναρξης"
              rules={[requiredRuleConfig]}
            >
              <DatePicker
                format={dateFormat}
                placeholder="Ημ/νια Έναρξης"
                style={fullWidth}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Item
              name="duration"
              label="Διάρκεια (Ημέρες)"
              rules={[requiredRuleConfig]}
            >
              <InputNumber
                type="number"
                style={fullWidth}
                step={1}
                min={0}
                placeholder="Διάρκεια (Ημέρες)"
                disabled={disabled}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="personnel"
              label="Προσωπικό (Αριθμός)"
              rules={[requiredRuleConfig]}
            >
              <InputNumber
                type="number"
                style={fullWidth}
                step={1}
                min={0}
                placeholder="Προσωπικό (Αριθμός)"
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>
        {apiMethod == 'post' && (
          <Row justify="center">
            <Button htmlType="submit">Προσθήκη</Button>
          </Row>
        )}
        {!disabled && apiMethod == 'put' && (
          <Row justify="center">
            <Button htmlType="submit">Αποθήκευση</Button>
          </Row>
        )}
      </Form>
    </>
  );
};
export default CultivationCareForm;
