export const coordinatesOnMouseContainer = {
  backgroundColor: 'white',
  fontSize: 14,
  width: '110%',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  marginBottom: 10,
  boxSizing: 'border-box',
  borderRadius: 6,
};
