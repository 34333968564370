import { FC } from 'react';
import {
  Typography,
  Row,
  Col,
  Image,
  Grid,
  Divider,
  Collapse,
  CollapseProps,
  Space,
} from 'antd';
import { motion } from 'framer-motion';
import feature5 from '../../assets/feature5.png';
import feature6 from '../../assets/feature6.png';
import feature7 from '../../assets/feature7.png';
import feature8 from '../../assets/feature8.png';
import feature9 from '../../assets/feature9.png';
import feature15 from '../../assets/feature15.png';
import feature11 from '../../assets/feature11.png';
import feature10 from '../../assets/feature10.png';
import feature13 from '../../assets/feature13.png';
import feature12 from '../../assets/feature12.png';
import feature14 from '../../assets/feature14.png';
import { leftTransition, rightTransition } from './styles';
import { baseColors } from '../../shared/styles/baseStyle';
import { RightOutlined } from '@ant-design/icons';
import useGetFontSize from '../../shared/hooks/useGetFontSize';
import ContactForm from './ContactForm';

const Features: FC = () => {
  const { fontSizeText } = useGetFontSize();
  const { Text, Title } = Typography;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const collapsedItems: CollapseProps['items'] = [
    {
      key: '1',
      children: (
        <Text style={{ fontSize: fontSizeText, color: baseColors.fsBlue }}>
          <ul>
            <li>
              <Text
                style={{
                  fontSize: fontSizeText,
                  color: baseColors.fsBlue,
                  fontWeight: 'bold',
                }}
              >
                {'FARMALEARN: '}
              </Text>
              Προσφέρει εκπαιδευτικό υλικό για εχθρούς και ασθένειες, ζιζάνια
              και βιοπαράγοντες, θρέψη-λίπανση καθώς παρέχει και εγχειρίδιο
              καλλιεργητή.
            </li>
            <li>
              <Text
                style={{
                  fontSize: fontSizeText,
                  color: baseColors.fsBlue,
                  fontWeight: 'bold',
                }}
              >
                {'FARMATOOLS: '}
              </Text>
              Ένα εργαλείο με τρία πεδία, τη διαγνωστική κλείδα που αφορά στην
              αναγνώριση ασθενειών και εχθρών των καλλιεργειών, τον οδηγό
              φυτοπροστασίας και τον οδηγό λίπανσης.
            </li>
          </ul>
          Αυτές οι υπηρεσίες ενισχύουν τη συνολική διαχείριση της αγροτικής
          εκμετάλλευσης, προσφέροντας ενημέρωση εργαλεία και εκπαίδευση σε ένα
          ολοκληρωμένο πακέτο.
        </Text>
      ),
    },
  ];

  const items = [
    {
      image: feature5,
      title: 'Καταγραφή αγροτεμαχίων',
      text: 'Μέσω της πλατφόρμας AGROS, οι χρήστες έχουν τη δυνατότητα να οργανώνουν τα αγροτεμάχια τους, να καταγράφουν τις καλλιεργητικές φροντίδες και τα έξοδα, καθώς και να παρακολουθούν δορυφορικές εικόνες (πχ δείκτης βλάστησης NDVI). Επιπλέον μπορούν να παρακολουθούν παραγωγές, έσοδα, και άλλα σημαντικά στατιστικά δεδομένα.',
    },
    {
      image: feature6,
      title: 'Ημερολόγιο εργασιών αγρού',
      text: 'Το ημερολόγιο βοηθά στο να συγκεντρώνονται όλες οι ενέργειες και φροντίδες που πραγματοποιούνται στο χωράφι. Επιτρέπει στους χρήστες να σημειώνουν παρατηρήσεις, να προσθέτουν φωτογραφίες και να δημιουργούν υπενθυμίσεις για προγραμματισμένες εργασίες.',
    },
    {
      image: feature7,
      title: 'Πλήρες ισοζύγιο θρεπτικών ουσιών',
      text: 'Μια λειτουργία της πλατφόρμας AGROS που βοηθάει τους αγρότες να διαχειρίζονται και να παρακολουθούν τη συνολική ισορροπία θρεπτικών συστατικών που χρησιμοποιούνται στις καλλιέργειές τους όπως παρακολούθηση εισροών-εκροών, αποτροπή υπερλίπανσης, παρακολούθηση ελλείψεων θρεπτικών συστατικών, προσαρμογή προγραμμάτων λίπανσης, συμμόρφωση με κανονισμούς λίπανσης.',
    },
    {
      image: feature8,
      title: 'Εφαρμογή λιπασμάτων βάσει εδαφολογικών αναλύσεων',
      text: 'Η εφαρμογή βοηθά στον υπολογισμό της απαιτούμενης ποσότητας λιπασμάτων και παρέχει εξατομικευμένες συστάσεις λίπανσης, λαμβάνοντας υπόψη τις εδαφολογικές αναλύσεις. Με αυτό το τρόπο εξασφαλίζεται η βέλτιστη θρέψη των καλλιεργειών μειώνοντας παράλληλα τις περιττές δαπάνες και περιβαλλοντικές επιπτώσεις.',
    },
    {
      image: feature9,
      title: 'Ενδείξεις νόμιμων ορίων & απαιτήσεων',
      text: 'Ο AGROS δίνει πληροφορίες σχετικά με τα νόμιμα όρια και τις απαιτήσεις που σχετίζονται με τα θρεπτικά συστατικά, τα φυτοπροστατευτικά προϊόντα και τη κατανάλωση νερού. Αυτή η λειτουργία διασφαλίζει τη συμμόρφωση με κανονισμούς, αποτρέποντας την υπερβολική χρήση προϊόντων και προστατεύοντας τις καλλιέργειες. Παράλληλα, βελτιστοποιεί τη διαχείριση πόρων, μειώνοντας τη σπατάλη νερού και θρεπτικών συστατικών, ενώ συμβάλλει στην περιβαλλοντική προστασία, περιορίζοντας τη ρύπανση του εδάφους και των υδάτων.',
    },
    {
      image: feature10,
      title: 'Κατανάλωση ύδατος',
      text: 'Η πλατφόρμα παρέχει εργαλεία παρακολούθησης της κατανάλωσης νερού, με σκοπό τη βελτιστοποίηση των αρδευτικών πρακτικών. Αυτό επιτρέπει την ακριβή κατανομή νερού, ανάλογα με τις ανάγκες των καλλιεργειών μειώνοντας την άσκοπη σπατάλη και αυξάνοντας την αποδοτικότητα της άρδευσης.',
    },
    {
      image: feature11,
      title: 'Εκτίμηση ανθρακικού αποτυπώματος',
      text: 'Δίνεται η δυνατότητα εκτίμησης του συνολικού αποτυπώματος άνθρακα για μια καλλιέργεια, επιτρέποντας τη μέτρηση του σε κάθε στάδιο της καλλιέργειας. Αυτό βοηθά τους παραγωγούς να μειώσουν τις εκπομπές τους, βελτιώνοντας τη βιωσιμότητα των καλλιεργειών και ενισχύοντας το προφίλ τους ως φιλικές προς το περιβάλλον επιχειρήσεις.',
    },
    {
      image: feature12,
      title: 'Διαχείριση άρδευσης',
      text: 'Βοηθά στη διαχείριση της άρδευσης, καταγράφοντας πότε και πόσο νερό έχει εφαρμοστεί. Αυτό επιτρέπει τον ακριβή έλεγχο της ποσότητας νερού που χρησιμοποιείται, συμβάλλοντας στην εξοικονόμηση πόρων και στην αποφυγή υπερβολικής ή ανεπαρκούς άρδευσης, κάτι που βελτιώνει την αποδοτικότητα των καλλιεργειών και μειώνει το κόστος.',
    },
    {
      image: feature13,
      title: 'Διασύνδεση με FARMACON.GR',
      text: (
        <Text style={{ fontSize: fontSizeText, color: baseColors.fsBlue }}>
          Επιτρέπει στους χρήστες να αξιοποιήσουν ένα ευρύ φάσμα υπηρεσιών της
          FARMACON που υποστηρίζουν την αποτελεσματική διαχείριση των
          καλλιεργειών:
          <ul>
            <li>
              <Text
                style={{
                  fontSize: fontSizeText,
                  color: baseColors.fsBlue,
                  fontWeight: 'bold',
                }}
              >
                {'FARMABASE: '}
              </Text>
              Παρέχει μια εκτενή βάση δεδομένων με πληροφορίες για
              φυτοπροστατευτικά προϊόντα, καλλιεργητικές πρακτικές, προϊόντα
              θρέψης κ.α., βοηθώντας στη λήψη τεκμηριωμένων αποφάσεων.
            </li>
          </ul>
          <Collapse
            ghost
            items={collapsedItems}
            expandIcon={({ isActive }) => (
              <Space size={5}>
                <RightOutlined rotate={isActive ? 90 : 0} />
                <Text style={{ fontSize: 15, color: baseColors.fsBlue }}>
                  {isActive ? 'Λιγότερα...' : 'Περισσότερα...'}
                </Text>
              </Space>
            )}
          />
        </Text>
      ),
    },
    {
      image: feature14,
      title: 'Πρόγνωση καιρού με βέλτιστους χρόνους ψεκασμού 48 ωρών',
      text: 'Παρέχονται προβλέψεις καιρού και ενδείξεις για τους βέλτιστους χρόνους ψεκασμού εντός των επόμενων 48 ωρών. Αυτές οι προβλέψεις συνδυάζουν δεδομένα για τη θερμοκρασία, την εδαφική υγρασία , τις βροχοπτώσεις και τους ανέμους για να προσδιοριστούν οι πιο κατάλληλες ώρες για την εφαρμογή ψεκαστικών προϊόντων, μειώνοντας τις πιθανότητες αποτυχίας και βελτιώνοντας την αποτελεσματικότητα των εφαρμογών.',
    },
    {
      image: feature15,
      title: 'Alert & Ειδοποιήσεις',
      text: 'Η εφαρμογή AGROS περιλαμβάνει ένα προηγμένο σύστημα ειδοποιήσεων που προσαρμόζεται στις ανάγκες των χρηστών. Το σύστημα αυτό παρέχει άμεσες ενημερώσεις σχετικά με κρίσιμα γεγονότα όπως αλλαγές στον καιρό, πιθανές ασθένειες ή εχθρούς και προθεσμίες εφαρμογής προϊόντων. Χάρη στις ειδοποιήσεις, οι χρήστες μπορούν να λαμβάνουν έγκαιρη προειδοποίηση και να προσαρμόζουν τις στρατηγικές τους ανάλογα.',
    },
  ];

  return (
    <>
      {items.map((item, index) => (
        <>
          <Divider />
          <Row
            key={index}
            style={{
              minHeight: '50vh',
            }}
            justify="center"
            align="middle"
            gutter={[80, 16]}
          >
            {(screens.md === true ||
              screens.lg === true ||
              screens.xl === true ||
              screens.xxl === true) &&
            index % 2 === 0 ? (
              <>
                <Col xs={23} sm={23} md={11} lg={10}>
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    variants={leftTransition}
                    viewport={{ once: false, amount: 0.5 }}
                  >
                    <Image
                      loading="lazy"
                      width="100%"
                      preview={false}
                      height="auto"
                      src={item.image}
                      style={{
                        boxShadow:
                          '0 1px 2px 0 rgba(60,64,67,.3),0 1px 3px 1px rgba(60,64,67,.15)',
                        borderRadius: 12,
                      }}
                    />
                  </motion.div>
                </Col>
                <Col xs={23} sm={23} md={11} lg={10}>
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    variants={rightTransition}
                    viewport={{ once: false, amount: 0.5 }}
                  >
                    <Title level={2} style={{ color: baseColors.fsBlue }}>
                      {item.title}
                    </Title>
                    <Text
                      strong
                      style={{
                        fontSize: fontSizeText,
                        color: baseColors.fsBlue,
                        width: '100%',
                      }}
                    >
                      {item.text}
                    </Text>
                  </motion.div>
                </Col>
              </>
            ) : (
              <>
                <Col xs={23} sm={23} md={11} lg={10}>
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    variants={leftTransition}
                    viewport={{ once: false, amount: 0.5 }}
                  >
                    <Title level={2} style={{ color: baseColors.fsBlue }}>
                      {item.title}
                    </Title>
                    <Text
                      strong
                      style={{
                        fontSize: fontSizeText,
                        color: baseColors.fsBlue,
                      }}
                    >
                      {item.text}
                    </Text>
                  </motion.div>
                </Col>
                <Col xs={23} sm={23} md={11} lg={10}>
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    variants={rightTransition}
                    viewport={{ once: false, amount: 0.5 }}
                  >
                    <Image
                      loading="lazy"
                      width="100%"
                      preview={false}
                      height="auto"
                      src={item.image}
                      style={{
                        boxShadow:
                          '0 1px 2px 0 rgba(60,64,67,.3),0 1px 3px 1px rgba(60,64,67,.15)',
                        borderRadius: 12,
                      }}
                    />
                  </motion.div>
                </Col>
              </>
            )}
          </Row>
        </>
      ))}
      <Divider />
      <ContactForm />
    </>
  );
};

export default Features;
