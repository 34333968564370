import { baseColors } from '../../shared/styles/baseStyle';

export const backgroundImage = {
  width: '100vw',
  height: '100vh',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  opacity: 0.9,
};
export const coverCard = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottom: '0.05px solid #AACFD0',
};
export const title = { marginBottom: 15, marginTop: 10 };
export const registerButton = { marginLeft: -10 };
export const formStyle = {
  marginTop: 10,
};
export const cardRow = {
  marginTop: 30,
  alignItems: 'center',
  justifyContent: 'space-between',
};
export const registerBackgroundColor = {
  backgroundColor: baseColors.fsGrey,
};
export const signUpRow = { marginTop: 40 };
export const whiteText = {
  color: 'white',
};
export const registerButtonRow = { marginTop: 30 };
export const forgotPasswordbutton = { fontSize: '1em', marginTop: -12 };
export const checkboxRow = { marginBottom: 5 };
export const checkboxLink = { fontSize: '1em', margin: -10 };
export const loginButton = { width: '30%' };
export const loginOpekepeButton = { marginBottom: 10 };
