import { FC, useContext } from 'react';
import { Row, Col, Card } from 'antd';
import { useQuery } from '@tanstack/react-query';
import MapView from '../../components/MapView/MapView';
import { cardStyle } from '../../shared/styles/baseStyle';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import { getData } from '../../shared/services/apiServices';
import endPoints from '../../shared/endPoints.json';
import FarmerCalendar from '../Calendar/FarmerCalendar';
import dayjs from 'dayjs';
import { EAESeasonContext } from '../../shared/context/EAESeason';
import LoadingSpin from '../../components/LoadingSpin/LoadingSpin';

const Home: FC = () => {
  const { request } = useCreateAxiosInstance();
  const { eaeSeason, eaeSeasonLoading } = useContext(EAESeasonContext);

  const defaultEaeSeasonId = eaeSeason ? eaeSeason[0].id : undefined;
  const { data: cultivations, isLoading } = useQuery({
    queryKey: ['cultivations'],
    queryFn: () =>
      getData(
        request,
        `${endPoints.CULTIVATION.LANDPARCEL}?items=10000000&page=1&eaeSeasonId=${defaultEaeSeasonId}`,
      ),
    select(data) {
      const coordinates = data?.data?.items?.map(
        (item: { coordinates: string }) => JSON.parse(item.coordinates),
      );
      const names = data?.data?.items?.map(
        (item: { name: string }) => item.name,
      );
      return { coordinates, names };
    },
    enabled: !!eaeSeason,
  });
  if (eaeSeasonLoading) return <LoadingSpin />;
  return (
    <>
      <Row justify="center" gutter={[5, 30]}>
        <Col xs={24} md={14}>
          <Card style={cardStyle} loading={isLoading}>
            <MapView
              path="home"
              height={'75vh'}
              setPolygon={undefined}
              showDrawControls={false}
              landParcelMutation={undefined}
              clearCoordinates={undefined}
              coordinates={cultivations?.coordinates}
              cultivationsNames={cultivations?.names}
            />
          </Card>
        </Col>
        <Col xs={24} md={10}>
          <Card style={cardStyle}>
            <iframe
              src="https://www.ventusky.com/?p=38.17;23.39;8&l=temperature-2m&lang=el"
              style={{
                width: '100%',
                height: '74vh',
                border: 'none',
              }}
            ></iframe>
          </Card>
        </Col>
        <Col span={24}>
          <FarmerCalendar
            defaultDate={dayjs()}
            calendarSpan={13}
            showEAESeasonSelect={false}
          />
        </Col>
      </Row>
    </>
  );
};
export default Home;
