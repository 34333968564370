import { FC } from 'react';
import { Layout, Image, Menu, Col, Row, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { baseColors } from '../../../shared/styles/baseStyle';
import logo from '../../../assets/Color logo - no background.png';
import { useNavigate } from 'react-router-dom';
import { header, headerImage, headerMenu } from '../styles';

const Header: FC = () => {
  const navigate = useNavigate();
  const { Header } = Layout;
  const items = [
    {
      key: 'home',
      label: `Home`,
      style: { fontSize: 18 },
    },
    {
      key: 'about',
      label: `Σχετικά με εμάς`,
      style: { fontSize: 18 },
    },

    {
      key: 'login',
      label: (
        <Button
          onClick={() => {
            navigate('/login');
          }}
          style={{ borderRadius: 12 }}
          type="primary"
          size="large"
        >
          Σύνδεση
        </Button>
      ),
      style: { alignSelf: 'end' },
    },
  ];
  return (
    <Header style={header}>
      <Row align="middle" justify="space-between">
        <Col xs={8} sm={6} md={5} lg={5} xl={4} xxl={3}>
          <Image
            loading="lazy"
            style={headerImage}
            preview={false}
            src={logo}
            width="100%"
            height="auto"
          />
        </Col>
        <Col xs={1} xl={8} xxl={7}>
          <Menu
            overflowedIndicator={
              <MenuOutlined
                style={{
                  color: baseColors.fsBlue,
                }}
              />
            }
            triggerSubMenuAction="click"
            subMenuOpenDelay={0.2}
            style={headerMenu}
            mode="horizontal"
            items={items}
          />
        </Col>
      </Row>
    </Header>
  );
};

export default Header;
