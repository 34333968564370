import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Typography,
  type FormProps,
  App,
} from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { FC } from 'react';
import { resetButton } from '../Profile/style';
import { outerRow } from '../../shared/styles/baseStyle';
import { useMutation } from '@tanstack/react-query';
import { mutateData } from '../../shared/services/apiServices';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import endPoints from '../../shared/endPoints.json';
import { useNavigate } from 'react-router-dom';
import { cardStyle } from '../../shared/styles/baseStyle';
import loginBackground from '../../assets/introBackground.jpg';
import { AxiosError } from 'axios';

type FieldType = {
  newPassword: string;
  oldPassword: string;
  username: string;
  confirmPassword: string;
};
const ForgotPassword: FC = () => {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { Title } = Typography;
  const { request } = useCreateAxiosInstance();
  const { mutate, isPending } = useMutation({
    mutationFn: (values: any) =>
      mutateData(request, endPoints.USER.FORGOTPASSWORD, values, 'post'),
    onSuccess() {
      message.success(
        'Ακολουθήστε τις οδηγίες που έχουν σταλεί στο email σας για επαναφορά του κωδικού σας',
      );
      navigate('/login');
    },
    onError(error: AxiosError) {
      if (error.status === 400) {
        message.error('Το συγκεκριμένο email δεν υπάρχει');
      }
    },
  });

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    mutate(values);
  };

  return (
    <>
      <Row align="middle" justify="center" style={outerRow}>
        <Col
          style={{
            width: '100vw',
            height: '100vh',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            position: 'absolute',
            opacity: 0.9,
            backgroundImage: `url(${loginBackground})`,
          }}
        />
        <Col xs={22} sm={18} md={14} lg={10} xl={8} xxl={6}>
          <Card loading={isPending} style={cardStyle}>
            <Title level={3} style={{ marginBottom: 30, textAlign: 'center' }}>
              {'Επαναφορά κωδικού πρόσβασης'}
            </Title>
            <Col>
              <Form size="large" onFinish={onFinish} name="basic">
                <Form.Item
                  extra={
                    'Συμπληρώστε το e-mail σας, για την επαναφορά του κωδικού σας'
                  }
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Εισάγετε έγκυρο email',
                    },
                  ]}
                >
                  <Input prefix={<MailOutlined />} placeholder="Email" />
                </Form.Item>
                <Flex gap="small" vertical>
                  <Row justify="center">
                    <Button
                      htmlType="submit"
                      type="primary"
                      size="large"
                      style={resetButton}
                    >
                      Αποστολή
                    </Button>
                  </Row>
                  <Row justify="center">
                    <Button
                      type="link"
                      onClick={() => {
                        navigate('/login');
                      }}
                    >
                      Επιστροφή
                    </Button>
                  </Row>
                </Flex>
              </Form>
            </Col>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default ForgotPassword;
