/* eslint-disable react/prop-types */
import { useEffect, useState, useMemo } from 'react';
import { createControlComponent } from '@react-leaflet/core';
import { RollbackOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import L from 'leaflet';
import 'leaflet.fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css';
import { useMap, useMapEvents } from 'react-leaflet';
import { coordinatesOnMouseContainer } from './styles';

export const POSITION_CLASSES = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right',
};

export const FullscreenControl = createControlComponent(() =>
  L.control.fullscreen({
    titleCancel: 'Exit fullscreen mode',
    content: null,
    forceSeparateButton: true,
    forcePseudoFullscreen: true,
    fullscreenElement: false,
  }),
);

export const ComponentResize = () => {
  const map = useMap();
  useEffect(() => {
    if (map) {
      map.invalidateSize();
    }
  }, [map]);

  return null;
};

export const InitilizeMap = () => {
  const map = useMap();
  useEffect(() => {
    if (map) {
      map.invalidateSize();
    }
  }, [map]);

  return null;
};

export const CoordinatesOnMouse = () => {
  const [position, setPosition] = useState(null);
  useMapEvents({
    mousemove(e) {
      setPosition(e.latlng);
    },
    mouseout() {
      setPosition(null);
    },
  });

  return (
    position && (
      <div className={POSITION_CLASSES.bottomleft}>
        <div className="leaflet-control">
          <div style={coordinatesOnMouseContainer}>
            {position?.lat.toFixed(5)}, {position?.lng.toFixed(5)}
          </div>
        </div>
      </div>
    )
  );
};

export const ResetZoom = ({ setSelectedPolygon, setFlyTo }) => {
  const resetZoom = useMemo(
    () => (
      <Button
        onClick={() => {
          setSelectedPolygon(null);
          setFlyTo(null);
        }}
        icon={<RollbackOutlined />}
        trigger={['click']}
      ></Button>
    ),
    [],
  );
  return (
    <div className={POSITION_CLASSES.bottomright}>
      <div className="leaflet-control leaflet-bar">{resetZoom}</div>
    </div>
  );
};
