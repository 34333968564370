import { FC } from 'react';
import AgriculturalOutflowForm from '../../../components/Forms/Agricultural/AgriculturalOutflowForm';
import { AgriculturalOutflowDetails } from '../../../types/types';

type Props = {
  selectedAgriculturalFlowForEdit: AgriculturalOutflowDetails;
  setIsEditCatalogueModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const EditAgriculturalOutflow: FC<Props> = ({
  selectedAgriculturalFlowForEdit,
  setIsEditCatalogueModalOpen,
}) => {
  if (!selectedAgriculturalFlowForEdit) return <></>;

  return (
    <AgriculturalOutflowForm
      selectedAgriculturalOutflow={selectedAgriculturalFlowForEdit}
      apiMethod="put"
      successTitle="Η εκροή επεξεργάστηκε με επιτυχία!"
      setIsEditCatalogueModalOpen={setIsEditCatalogueModalOpen}
    />
  );
};

export default EditAgriculturalOutflow;
