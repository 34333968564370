import { Col, Divider, Row, Select } from 'antd';
import { FC, useContext } from 'react';
import { fullWidth } from '../../shared/styles/baseStyle';
import { NamedItem, LandParcel } from '../../types/types';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import { useQuery } from '@tanstack/react-query';
import { getData } from '../../shared/services/apiServices';
import endPoints from '../../shared/endPoints.json';
import { EAESeasonContext } from '../../shared/context/EAESeason';

type Props = {
  selectedCultivation: LandParcel | undefined;
  setSelectedActivity: React.Dispatch<React.SetStateAction<string>>;
  setSelectedCultivation: React.Dispatch<
    React.SetStateAction<LandParcel | undefined>
  >;
  children: any;
  selectedEaeSeason: any;
};
const SelectActivity: FC<Props> = ({
  selectedCultivation,
  setSelectedActivity,
  setSelectedCultivation,
  selectedEaeSeason,
  children,
}) => {
  const { request } = useCreateAxiosInstance();
  const { eaeSeason } = useContext(EAESeasonContext);

  if (!eaeSeason) return <></>;
  const { data: calendarActivityItems } = useQuery({
    queryKey: ['calendarActivityItems'],
    queryFn: () => getData(request, endPoints.CALENDAR.CALENDARACTIVITY),
    select(data) {
      return data.data.map((item: NamedItem) => ({
        value: item.id,
        label: item.name,
      }));
    },
  });
  const eaeSeasonId = selectedEaeSeason
    ? selectedEaeSeason.value
    : eaeSeason[0].id;
  const { data: landParcels } = useQuery({
    queryKey: ['landParcels'],
    queryFn: () =>
      getData(
        request,
        `${endPoints.CULTIVATION.LANDPARCEL}?Items=10000&Page=1&eaeSeasonId=${eaeSeasonId}`,
      ),
    select(data) {
      return data.data.items;
    },
  });
  return (
    <>
      <Row gutter={[10, 0]} justify="center">
        <Col span={12}>
          <Select
            size="large"
            style={fullWidth}
            options={landParcels?.map((item: LandParcel) => ({
              value: item.id,
              label: item.name,
            }))}
            placeholder="Επιλέξτε Αγροτεμάχιο"
            onSelect={(value: any, option: any) => {
              setSelectedCultivation(
                landParcels?.find(
                  (item: LandParcel) => item.id === option.value,
                ),
              );
            }}
          />
        </Col>
        <Col span={12}>
          <Select
            disabled={!selectedCultivation}
            size="large"
            style={fullWidth}
            allowClear
            options={calendarActivityItems}
            placeholder="Επιλέξτε Ενέργεια"
            onSelect={(value: any, option: any) => {
              setSelectedActivity(option.label);
            }}
            onClear={() => setSelectedActivity('')}
          />
        </Col>
      </Row>
      <Divider />
      {children}
    </>
  );
};
export default SelectActivity;
