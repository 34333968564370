/* eslint-disable react/prop-types */
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useMap } from 'react-leaflet';
import Endpoints from '../../shared/endPoints.json';
import L, { CRS } from 'leaflet';
import { geojsonToWKT } from '@terraformer/wkt';
import { Control, DomUtil } from 'leaflet';
import { createRoot } from 'react-dom/client';
import { Collapse, Checkbox, Col, Tooltip } from 'antd';

const closePolygon = (polygon) => {
  polygon.push(polygon[0]);
  return polygon;
};

const LayerControl = ({ polygons }) => {
  const createWmsLayer = useCallback(
    (layerId) => {
      return polygons?.map(
        (polygon) =>
          new L.tileLayer.wms(Endpoints.SENTINEL, {
            uppercase: true,
            layers: layerId,
            tms: true,
            tyled: true,
            format: 'image/png',
            maxZoom: '20',
            minZoom: '1',
            crs: CRS.EPSG4326,
            transparent: true,
            bounds: polygon,
            version: '1.3.0',
            detectRetina: true,
            geometry: geojsonToWKT({
              type: 'Polygon',
              coordinates: [closePolygon(polygon)],
            }),
          }),
      );
    },
    [polygons],
  );

  const groupLayers = useMemo(() => {
    return [
      {
        key: '1',
        groupName: 'Δορυφορικά',
        containingLayers: [
          {
            name: 'NDVI',
            layer: createWmsLayer('3_NDVI'),
            tooltip: 'Δείκτης φωτοσυνθετικής δραστηριότητας',
          },
          {
            name: 'True Color',
            layer: createWmsLayer('1_TRUE_COLOR'),
            tooltip:
              'Η πραγματική απεικόνιση του αγροτεμαχίου επάνω στο χάρτη, στο ορατό φάσμα.',
          },
          {
            name: 'Chlorophyll-Red-Edge',
            layer: createWmsLayer('CHLOROPHYLL-RED-EDGE'),
            tooltip: 'Ο δείκτης μετρά την χλωροφύλλη στον θόλο των φυτών.',
          },
          {
            name: 'Burned Area Index',
            layer: createWmsLayer('BAIS2'),
            tooltip:
              'Υποδηλώνει τα υπολλείμματα καμμένων φυτών. Τα σημεία που είναι πιο ανοιχτόχρωμα στο χάρτη υποδεικνύουν τις καμμένες περιοχές.',
          },
          {
            name: 'Moisture-Index',
            layer: createWmsLayer('5-MOISTURE-INDEX1'),
            tooltip: 'Δείκτης Υγρασίας',
          },
        ],
      },
    ];
  }, []);

  const map = useMap();
  const [collapsedKey, setCollapsedKey] = useState([]);

  const toggleLayer = (e) => {
    const selectedLayerName = e?.target?.value?.name;
    const selectedLayerChecked = e?.target?.checked;
    const selectedGroupLayers = e?.target?.value?.group;
    const selectedGroup = groupLayers.find(
      (groupLayer) => groupLayer.groupName === selectedGroupLayers,
    );
    selectedGroup.containingLayers?.forEach((containingLayer) => {
      if (containingLayer?.name === selectedLayerName) {
        containingLayer.layer?.forEach((mapLayer) =>
          selectedLayerChecked
            ? map.addLayer(mapLayer)
            : map.removeLayer(mapLayer),
        );
      }
    });
  };

  const onCollapseChange = (key) => {
    setCollapsedKey(key);
  };

  useEffect(() => {
    const control = new Control({ position: 'topright' });
    const container = DomUtil.create('div');
    control.onAdd = () => container;
    control.addTo(map);
    const root = createRoot(container);

    root.render(
      <>
        {groupLayers.map((groupLayer) => (
          <Collapse
            key={groupLayer.groupName}
            defaultActiveKey={collapsedKey}
            onChange={onCollapseChange}
            style={{ backgroundColor: 'white' }}
            items={[
              {
                label: groupLayer.groupName,
                key: groupLayer.groupName,
                children: groupLayer?.containingLayers.map(
                  (containingLayer) => {
                    return (
                      <Col key={containingLayer.name}>
                        <Tooltip
                          mouseEnterDelay={0.3}
                          placement="left"
                          title={containingLayer?.tooltip}
                        >
                          <Checkbox
                            value={{
                              name: containingLayer.name,
                              group: groupLayer.groupName,
                            }}
                            key={containingLayer.name}
                            onChange={toggleLayer}
                          >
                            {containingLayer.name}
                          </Checkbox>
                        </Tooltip>
                      </Col>
                    );
                  },
                ),
              },
            ]}
          ></Collapse>
        ))}
      </>,
    );

    return () => {
      DomUtil.remove(container);
    };
  }, [map, groupLayers]);

  return null;
};

export default LayerControl;
