import { Layout, Grid, Image, Row, Divider } from 'antd';
import { FC, useContext, useState, ReactNode, useEffect } from 'react';
import {
  TeamOutlined,
  SnippetsOutlined,
  ScheduleOutlined,
  BugOutlined,
  ControlOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import SiderMenu from './SiderMenu';
import { AuthContext } from '../../shared/context/auth-context';
import { baseColors, baseStyle } from '../../shared/styles/baseStyle';
const Sider: FC = () => {
  const { role } = useContext(AuthContext);
  const { useBreakpoint } = Grid;
  const { Sider } = Layout;
  const breakpoints = useBreakpoint();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  if (!role) return <></>;
  type SiderItem = {
    key: string;
    icon: JSX.Element;
    label: ReactNode;
    role: string[];
    children?: {
      key: string;
      label: string;
      role: string[];
    }[];
  };
  const siderItems: SiderItem[] = [
    {
      key: '/',
      icon: <ControlOutlined />,
      label: 'Πίνακας Ελέγχου',
      role: ['Διαχειριστής', 'Καλλιεργητής'],
    },
    {
      key: '/users',
      icon: <TeamOutlined />,
      label: 'Χρήστες',
      role: ['Διαχειριστής', 'Γεωπόνος'],
    },
    {
      key: '/cultivations',
      icon: <SnippetsOutlined />,
      label: 'Αγροτεμάχια',
      role: ['Διαχειριστής', 'Καλλιεργητής'],
    },
    {
      key: '/calendar',
      icon: <ScheduleOutlined />,
      label: 'Ημερολόγιο Αγρού',
      role: ['Διαχειριστής', 'Καλλιεργητής'],
    },
    {
      key: 'pesticides',
      icon: <BugOutlined />,
      label: (
        <div style={{ whiteSpace: 'pre-wrap', lineHeight: '22px' }}>
          Φυτοπροστατευτικά Σκευάσματα
        </div>
      ),
      role: ['Διαχειριστής', 'Καλλιεργητής', 'Γεωπόνος'],
    },
  ];
  const siderItemsForRole = siderItems
    .map((item) => {
      const filteredChildren = item.children?.filter((child) =>
        child.role.includes(role),
      );
      if (item.role.includes(role)) {
        return {
          ...item,
          children: filteredChildren,
        };
      }
      return null;
    })
    .filter((item) => item !== null);

  const currentPath = location.pathname;
  const openParentKey = siderItemsForRole.find((item) =>
    item?.children?.some((child) => child.key === currentPath),
  )?.key;

  useEffect(() => {
    if (
      currentPath !== '/' &&
      openParentKey &&
      (breakpoints.lg === true ||
        breakpoints.xl === true ||
        breakpoints.xxl === true)
    ) {
      setOpenKeys([openParentKey]);
    }
  }, [currentPath, openParentKey]);

  const handleOpenChange = (keys: string[]) => {
    setOpenKeys(keys);
  };

  return (
    <>
      <Sider
        defaultCollapsed={true}
        style={{ borderTopRightRadius: 12, borderBottomLeftRadius: 12 }}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => {
          setCollapsed(value);
        }}
      >
        <Divider />
        <Row
          style={{
            backgroundColor: baseColors.fsGreyBlue,
          }}
          justify="center"
        >
          <Image
            loading="lazy"
            style={{
              cursor: 'pointer',
              backgroundColor: baseColors.fsGreyBlue,
            }}
            onClick={() => {
              navigate('/');
              window.location.reload();
            }}
            preview={false}
            src={process.env.PUBLIC_URL + '/logo.png'}
            height="100%"
            width={!collapsed ? 120 : 65}
          />
        </Row>
        <Divider />
        <SiderMenu
          items={siderItemsForRole}
          selectedKeys={[currentPath]}
          openKeys={openKeys}
          onOpenChange={handleOpenChange}
        />
      </Sider>
    </>
  );
};
export default Sider;
