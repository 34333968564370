import { FC, useState, useEffect } from 'react';
import { Card, Row, Space, Col, Button, Flex } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { EditOutlined, AppstoreAddOutlined } from '@ant-design/icons';
import BaseModal from '../../../components/BaseModal/BaseModal';
import BaseTable from '../../../components/BaseTable/BaseTable';
import {
  baseStyle,
  cardStyle,
  addCatalogueButton,
} from '../../../shared/styles/baseStyle';
import { useNavigate } from 'react-router-dom';
import { getData } from '../../../shared/services/apiServices';
import useCreateAxiosInstance from '../../../shared/hooks/useCreateAxiosInstance';
import endPoints from '../../../shared/endPoints.json';
import EditAgriculturalInflow from './EditAgriculturalInflow';
import { AgriculturalOutflow } from '../../../types/types';
import dayjs from 'dayjs';

type Props = {
  selectedEaeSeasonValue: number;
};

const AgriculturalInflowCatalogue: FC<Props> = ({ selectedEaeSeasonValue }) => {
  const navigate = useNavigate();
  const dateFormat = 'DD/MM/YYYY';
  const { request } = useCreateAxiosInstance();
  const [isEditCatalogueModalOpen, setIsEditCatalogueModalOpen] =
    useState(false);
  const defaultCurrentPage = 1;
  const pageSize = 10;
  const defaultUrl = `${endPoints.AGRICULTURAL.AGRICULTURALINFLOW}?Items=${pageSize}&Page=${defaultCurrentPage}&eaeSeasonId=${selectedEaeSeasonValue}`;
  const [url, setUrl] = useState(defaultUrl);
  const [
    selectedEditAgriculturalInflowId,
    setSelectedEditAgriculturalInflowId,
  ] = useState<number>();
  const [editClick, setEditClick] = useState(false);

  useEffect(() => {
    setUrl(
      `${endPoints.AGRICULTURAL.AGRICULTURALINFLOW}?Items=${pageSize}&Page=${defaultCurrentPage}&eaeSeasonId=${selectedEaeSeasonValue}`,
    );
  }, [selectedEaeSeasonValue]);

  const { data: agriculturalInflow, isLoading } = useQuery({
    queryKey: ['agriculturalInflow', url, isEditCatalogueModalOpen],
    queryFn: () => getData(request, url),
    select(data) {
      return data.data;
    },
    enabled: isEditCatalogueModalOpen == false,
  });

  const { data: selectedAgriculturalFlowForEdit, isLoading: isEditLoading } =
    useQuery({
      queryKey: ['selectedAgriculturalFlowForEdit', editClick],
      queryFn: () =>
        getData(
          request,
          `${endPoints.AGRICULTURAL.AGRICULTURALINFLOW}/${selectedEditAgriculturalInflowId}`,
        ),
      enabled: !!selectedEditAgriculturalInflowId,
      select(data) {
        return data.data;
      },
    });
  const onChange = (pagination: any) => {
    setUrl(
      `${endPoints.AGRICULTURAL.AGRICULTURALOUTFLOW}?Items=${pageSize}&Page=${pagination.current}&eaeSeasonId=${selectedEaeSeasonValue}`,
    );
  };

  const handleEditClick = async (record: AgriculturalOutflow) => {
    setSelectedEditAgriculturalInflowId(record.id);
    setIsEditCatalogueModalOpen(true);
    setEditClick(!editClick);
  };
  const columns = [
    {
      title: '',
      key: 'action',
      render: (_: any, record: AgriculturalOutflow) => (
        <Space size="middle">
          <EditOutlined onClick={() => handleEditClick(record)} />
        </Space>
      ),
    },
    {
      title: 'Αριθμός Παραστατικού',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
    },
    {
      title: 'Ημερομηνία Αγοράς',
      dataIndex: 'purchaseDate',
      key: 'purchaseDate',
      render: (text: any) => dayjs(text).format(dateFormat),
    },
    {
      title: 'Επωνυμία Προμηθευτή',
      dataIndex: 'supplierName',
      key: 'supplierName',
    },
    {
      title: 'Διεύθυνση Προμηθευτή',
      dataIndex: 'supplierAddress',
      key: 'supplierAddress',
    },
    {
      title: 'Είδος Προιόντος',
      dataIndex: 'productType',
      key: 'productType',
    },
    {
      title: 'Βιολογικό',
      dataIndex: 'isBiological',
      key: 'isBiological',
      render: (text: any) =>
        text === true ? 'ΝΑΙ' : text === false ? 'ΟΧΙ' : '---',
    },
    {
      title: 'Εμπορικό Όνομα',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: 'Αναλογία Ν-Ρ-Κ',
      dataIndex: 'fertilizerRatio',
      key: 'fertilizerRatio',
    },
    {
      title: 'Αριθμός Συσκευασιών',
      dataIndex: 'numberOfPackages',
      key: 'numberOfPackages',
    },
    {
      title: 'Συνολική Ποσότητα',
      dataIndex: 'totalQuantity',
      key: 'totalQuantity',
    },
  ];
  return (
    <Row justify="center" style={baseStyle.mainRow}>
      <Col span={24}>
        <Flex gap="middle" vertical>
          <Button
            onClick={() => {
              navigate('/agricultural/addInflow');
            }}
            style={addCatalogueButton}
            type="primary"
            size="large"
            icon={<AppstoreAddOutlined />}
          >
            Προσθήκη Εισροής
          </Button>
          <Card style={cardStyle} title="Εισροές" loading={isEditLoading}>
            <BaseTable
              dataSource={agriculturalInflow?.items}
              columns={columns}
              loading={isLoading}
              paginationConfig={{
                pageSize: pageSize,
                total: agriculturalInflow?.totalCount,
              }}
              onChange={onChange}
              rowSelection={null}
            />
            <BaseModal
              modalTitle=""
              modalWidth={'85%'}
              isModalOpen={isEditCatalogueModalOpen}
              setIsModalOpen={setIsEditCatalogueModalOpen}
            >
              <EditAgriculturalInflow
                selectedAgriculturalFlowForEdit={
                  selectedAgriculturalFlowForEdit
                }
                setIsEditCatalogueModalOpen={setIsEditCatalogueModalOpen}
              ></EditAgriculturalInflow>
            </BaseModal>
          </Card>
        </Flex>
      </Col>
    </Row>
  );
};
export default AgriculturalInflowCatalogue;
