import { useMutation } from '@tanstack/react-query';
import { App, Flex, Row, Typography } from 'antd';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingSpin from '../../components/LoadingSpin/LoadingSpin';
import endPoints from '../../shared/endPoints.json';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import { mutateData } from '../../shared/services/apiServices';

const AuthenticationCallback = () => {
  const { request } = useCreateAxiosInstance();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const authCode = searchParams.get('code');
  const { message } = App.useApp();
  const storage = sessionStorage.length === 0 ? localStorage : sessionStorage;
  const opekepeApplicationYear = storage.getItem('opekepeApplicationYear');
  const { mutate } = useMutation({
    mutationFn: () =>
      mutateData(
        request,
        `${endPoints.CULTIVATION.OPEKEPELANDPARCELS}`,
        {
          applicationYear: opekepeApplicationYear,
          authCode: authCode,
        },
        'post',
      ),
    onSuccess(data) {
      message.success(`Τα αγροτεμάχια ανακτήθηκαν με επιτυχία`);
    },
    onError(error: any) {
      const messageValueArray = Object.values(error?.response?.data)[0];
      if (error?.response?.status === 400 && Array.isArray(messageValueArray)) {
        messageValueArray.some((item) => {
          if (
            item.includes(
              'Δεν υπάρχει αντιστοιχία Χρήστη ΟΠΕΚΕΠΕ με συνδεδεμένο χρήστη',
            )
          ) {
            message.error(
              <>
                <Row>
                  Ο χρήστης ΟΠΕΚΕΠΕ δεν αντιστοιχεί στο συνδεδεμένο χρήστη.
                </Row>
                <Row justify="center">
                  Παρακαλώ αποσυνδεθείτε απο τον προηγούμενο.
                </Row>
              </>,
            );
          }
          if (item.includes('Unsupported year')) {
            message.error('Δεν υπάρχουν δηλώσεις για το επιλεγμένο ΕΑΕ');
          }
        });
        return;
      }

      message.error('Κάτι πήγε στραβά');
    },
    onSettled() {
      navigate('/cultivations');
    },
  });

  useEffect(() => {
    if (authCode && opekepeApplicationYear) {
      mutate();
    }
  }, [authCode]);

  return (
    <Flex vertical>
      <LoadingSpin />
      <Row align="middle" justify="center">
        <Typography.Text
          type="secondary"
          style={{ fontSize: 16, fontWeight: 'bold' }}
        >
          Αναμονή για ανάκτηση αγροτεμαχίων...
        </Typography.Text>
      </Row>
    </Flex>
  );
};
export default AuthenticationCallback;
