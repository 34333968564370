import {
  Button,
  Card,
  Checkbox,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Typography,
  type FormProps,
  App,
} from 'antd';
import {
  LockOutlined,
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpin from '../../components/LoadingSpin/LoadingSpin';
import { title, registerButton, loginButton } from './style';
import { outerRow } from '../../shared/styles/baseStyle';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getData, mutateData } from '../../shared/services/apiServices';
import useCreateAxiosInstance from '../../shared/hooks/useCreateAxiosInstance';
import endPoints from '../../shared/endPoints.json';

type FieldType = {
  password: string;
  rePassword: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  role: string;
};
const Register: FC = () => {
  const { message } = App.useApp();
  const { Title, Text } = Typography;
  const { request } = useCreateAxiosInstance();
  const navigate = useNavigate();

  const { data: roleItems, isLoading } = useQuery({
    queryKey: ['roleItems'],
    queryFn: () => getData(request, endPoints.USER.GETROLES),
    select(data) {
      return data.data.map((item: any) => ({
        value: item.name,
        label: item.name,
      }));
    },
  });
  const { mutate } = useMutation({
    mutationFn: (values: any) =>
      mutateData(request, endPoints.USER.USER, values, 'post'),
    onSuccess() {
      message.success('Ολοκλήρωση εγγραφής! Συνδεθείτε στο λογαριασμό σας');
      navigate('/login');
    },
  });

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    mutate(values);
  };
  if (isLoading) return <LoadingSpin />;

  return (
    <>
      <Row align="middle" justify="center" style={outerRow}>
        <Col xs={16} sm={14} md={12} lg={9} xl={8} xxl={6}>
          <Card>
            <Title level={2} style={title}>
              Εγγραφή
            </Title>
            <Text>Δημιουργήστε τον λογαριασμό σας</Text>
            <Col>
              <Form size="large" onFinish={onFinish} name="basic">
                <Form.Item
                  name="role"
                  rules={[
                    {
                      required: true,
                      message: 'Επιλέξτε ρόλο!',
                    },
                  ]}
                >
                  <Select options={roleItems} placeholder="Επιλογή ρόλου" />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Καταχωρείστε το email σας!',
                    },
                  ]}
                >
                  <Input prefix={<MailOutlined />} placeholder="Email" />
                </Form.Item>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: 'Καταχωρείστε το όνομα σας!',
                    },
                  ]}
                >
                  <Input prefix={<UserOutlined />} placeholder="Όνομα" />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: 'Καταχωρείστε το επίθετο σας!',
                    },
                  ]}
                >
                  <Input prefix={<UserOutlined />} placeholder="Επίθετο" />
                </Form.Item>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: 'Καταχωρείστε το τηλέφωνο σας!',
                    },
                  ]}
                >
                  <Input prefix={<PhoneOutlined />} placeholder="Τηλέφωνο" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Καταχωρείστε κωδικό πρόσβασης!',
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Κωδικό Πρόσβασης"
                  />
                </Form.Item>
                <Form.Item
                  name="rePassword"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Επιβεβαιώστε τον κωδικό πρόσβασης!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error('Οι κωδικοί δεν συμπίπτουν!'),
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Επιβεβαίωση Κωδικού"
                  />
                </Form.Item>
                <Flex gap="small" vertical>
                  <Row>
                    <Checkbox>Αποδέχομαι τους όρους.</Checkbox>
                  </Row>
                  <Row>
                    <Checkbox>
                      Θα ήθελα να λαμβάνω το ενημερωτικό δελτίο.
                    </Checkbox>
                  </Row>
                  <Row justify="center">
                    <Button
                      htmlType="submit"
                      size="large"
                      style={registerButton}
                    >
                      Εγγραφή
                    </Button>
                  </Row>
                  <Row justify="center">
                    <Button
                      type="link"
                      onClick={() => {
                        navigate('/login');
                      }}
                      style={loginButton}
                    >
                      Συνδεθείτε στον λογαριασμό σας
                    </Button>
                  </Row>
                </Flex>
              </Form>
            </Col>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default Register;
