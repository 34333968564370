import { Card, Col, Row, Image } from 'antd';
import { outerRow, cardStyle } from '../../shared/styles/baseStyle';
import { backgroundImage, coverCard } from './style';

import loginBackground from '../../assets/introBackground.jpg';
import { FC, ReactNode } from 'react';
type Props = {
  loading: boolean;
  children: ReactNode;
};
const LoginLayout: FC<Props> = ({ loading, children }) => {
  return (
    <Row align="middle" justify="center" style={outerRow}>
      <Col
        style={{
          ...backgroundImage,
          position: 'absolute',
          backgroundImage: `url(${loginBackground})`,
        }}
      />
      <Col xs={22} sm={18} md={14} lg={9} xxl={7}>
        <Card
          loading={loading}
          style={cardStyle}
          cover={
            <Col style={coverCard}>
              <Image
                loading="lazy"
                preview={false}
                height={120}
                width={150}
                alt="logo"
                src={process.env.PUBLIC_URL + '/logo.png'}
              />
            </Col>
          }
        >
          {children}
        </Card>
      </Col>
    </Row>
  );
  //
};

export default LoginLayout;
