/* eslint-disable react/prop-types */
import { useRef, useEffect } from 'react';
import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import L from 'leaflet';
import { locale } from './locale';
import * as turf from '@turf/turf';
const extractLatLng = (coordinates) => {
  return coordinates.map((obj) => [obj.lat, obj.lng]);
};
const DrawTools = ({
  coordinates,
  setPolygon,
  showDrawControls,
  landParcelMutation,
  clearCoordinates,
}) => {
  const ref = useRef(null);
  const clearMap = () => {
    const polygons = ref.current?.getLayers();
    polygons.map((polygon) => polygon.remove());
  };
  useEffect(() => {
    if (!coordinates) {
      clearMap();
    }
  }, [landParcelMutation, clearCoordinates, coordinates]);

  let prevLayer;
  const _onCreated = (e) => {
    let layer = e.layer;
    const polygonCoords = extractLatLng(layer.getLatLngs()[0]);
    if (
      polygonCoords.length > 2 &&
      (polygonCoords[0][0] !== polygonCoords[polygonCoords.length - 1][0] ||
        polygonCoords[0][1] !== polygonCoords[polygonCoords.length - 1][1])
    ) {
      polygonCoords.push(polygonCoords[0]);
    }

    const polygon = turf.polygon([
      polygonCoords.map(([lat, lng]) => [lng, lat]),
    ]);

    const areaInSqMeters = turf.area(polygon);
    const areaInHectares = (areaInSqMeters / 10000).toFixed(2);
    const center = turf.centerOfMass(polygon);
    const centerCoords = [
      center.geometry.coordinates[1],
      center.geometry.coordinates[0],
    ];

    layer
      .bindTooltip(`${areaInHectares} ha`, {
        permanent: true,
        direction: 'center',
      })
      .openTooltip(centerCoords);

    if (coordinates && ref.current?.getLayers().length == 2) {
      ref.current?.getLayers()[0].remove();
    }
    if (coordinates && ref.current?.getLayers().length > 2) {
      prevLayer?.remove();
    }
    if (!coordinates && ref.current?.getLayers().length > 1) {
      prevLayer?.remove();
      ref.current?.getLayers()[0].remove();
    }
    prevLayer = layer;
    setPolygon(polygonCoords);
  };

  const _onDeleted = () => {
    clearMap();
    setPolygon(undefined);
  };

  useEffect(() => {
    if (showDrawControls) {
      L.drawLocal = locale;
    }
  }, []);

  return (
    <FeatureGroup ref={ref}>
      {showDrawControls && (
        <EditControl
          position="topleft"
          onCreated={_onCreated}
          onDeleted={_onDeleted}
          draw={{
            polyline: false,
            rectangle: false,
            circlemarker: false,
            circle: false,
            polygon: {
              allowIntersection: false,
              showArea: true,
              showLength: true,
            },
            marker: false,
          }}
          edit={{
            edit: false,
          }}
        />
      )}
    </FeatureGroup>
  );
};

export default DrawTools;
