import { FC } from 'react';
import AgriculturalInflowForm from '../../../components/Forms/Agricultural/AgriculturalInflowForm';
import { AgriculturalInflowDetails } from '../../../types/types';

type Props = {
  selectedAgriculturalFlowForEdit: AgriculturalInflowDetails;
  setIsEditCatalogueModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const EditAgriculturalInflow: FC<Props> = ({
  selectedAgriculturalFlowForEdit,
  setIsEditCatalogueModalOpen,
}) => {
  if (!selectedAgriculturalFlowForEdit) return <></>;

  return (
    <AgriculturalInflowForm
      selectedAgriculturalInflow={selectedAgriculturalFlowForEdit}
      apiMethod="put"
      successTitle="Η εισροή επεξεργάστηκε με επιτυχία!"
      setIsEditCatalogueModalOpen={setIsEditCatalogueModalOpen}
    />
  );
};

export default EditAgriculturalInflow;
