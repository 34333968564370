import { Modal } from 'antd';
import { FC, ReactNode } from 'react';
type Props = {
  isModalOpen: boolean;
  modalTitle: string;
  modalWidth: string;
  children: ReactNode;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const BaseModal: FC<Props> = ({
  modalTitle,
  modalWidth,
  isModalOpen,
  setIsModalOpen,
  children,
}) => {
  return (
    <Modal
      maskClosable={false}
      title={modalTitle}
      open={isModalOpen}
      footer={null}
      onCancel={() => setIsModalOpen(false)}
      width={modalWidth}
    >
      {children}
    </Modal>
  );
};
export default BaseModal;
