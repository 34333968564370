import { FC } from 'react';
import AgriculturalInflowForm from '../../../components/Forms/Agricultural/AgriculturalInflowForm';

const AddAgriculturalInflow: FC = () => {
  return (
    <AgriculturalInflowForm
      selectedAgriculturalInflow={null}
      apiMethod="post"
      successTitle="Η εισροή προστέθηκε με επιτυχία!"
      setIsEditCatalogueModalOpen={() => {
        // intentionally empty
      }}
    />
  );
};

export default AddAgriculturalInflow;
