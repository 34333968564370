import { Badge, Card, Flex, Modal, Row, Space, Typography } from 'antd';
import { FC, useState } from 'react';
import { CalendarEvent, CalendarEventContent } from '../../types/types';
import dayjs, { Dayjs } from 'dayjs';
import FertilizerForm from '../../components/Forms/Calendar/FertilizerForm';
import QualityCharacteristicsForm from '../../components/Forms/Calendar/QualityCharacteristicsForm';
import VisitsForm from '../../components/Forms/Calendar/VisitsForm';
import PlantProtectionForm from '../../components/Forms/Calendar/PlantProtectionForm';
import HarvestForm from '../../components/Forms/Calendar/HarvestForm';
import IrrigationForm from '../../components/Forms/Calendar/IrrigationForm';
import CultivationCareForm from '../../components/Forms/Calendar/CultivationCareForm';
import { Method } from 'axios';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';

type Props = {
  selectedDate: Dayjs | undefined;
  eventList: CalendarEvent[] | undefined;
  month: string;
  selectedEaeSeason: any;
};
const CalendarList: FC<Props> = ({
  selectedDate,
  eventList,
  month,
  selectedEaeSeason,
}) => {
  const [selectedEvent, setSelectedEvent] = useState<CalendarEventContent>();
  const [viewOnly, setViewOnly] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const disabledDate = (current: any) => {
    if (!selectedEaeSeason) return undefined;
    const { start, end } = selectedEaeSeason;
    const startDate = dayjs(start);
    const endDate = end ? dayjs(end) : null;
    return (
      current.isBefore(startDate, 'day') ||
      (endDate && current.isAfter(endDate, 'day'))
    );
  };
  const commonProps = (): {
    selectedCultivation: undefined;
    selectedDate: Dayjs | undefined;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    selectedEvent: CalendarEventContent | undefined;
    successMessage: string;
    apiMethod: Method;
    viewOnly: boolean;
    disabledDate: any;
  } => {
    return {
      selectedCultivation: undefined,
      selectedDate,
      setIsModalOpen,
      selectedEvent,
      successMessage: 'Η ενέργεια επεξεργάστηκε με επιτυχία!',
      apiMethod: 'put',
      viewOnly,
      disabledDate,
    };
  };
  const calendarActivityForms: { [key: string]: JSX.Element } = {
    Λίπανση: <FertilizerForm {...commonProps()} />,
    Καλλιεργητικέςφροντίδες: <CultivationCareForm {...commonProps()} />,
    Άρδευση: <IrrigationForm {...commonProps()} />,
    Συγκομιδή: <HarvestForm {...commonProps()} />,
    Φυτοπροστασία: <PlantProtectionForm {...commonProps()} />,
    Επισκέψεις: <VisitsForm {...commonProps()} />,
    Ποιοτικάχαρακτηριστικά: <QualityCharacteristicsForm {...commonProps()} />,
  };

  return (
    <>
      <Typography.Title level={4} style={{ textAlign: 'center', marginTop: 0 }}>
        {month}
      </Typography.Title>
      {eventList?.length === 0 ? (
        <Row justify="center">
          <Typography.Text>
            Δεν υπάρχουν ενέργειες {selectedDate && `για `}
            {selectedDate && dayjs(selectedDate).format('DD/MM')}
          </Typography.Text>
        </Row>
      ) : (
        <>
          {eventList?.map((item, index) => {
            return (
              <div key={index}>
                <Typography.Text strong>
                  {dayjs(item.dateTime).format('dddd DD')}
                </Typography.Text>
                {item.contents.map((item, index) => {
                  return (
                    <div key={index}>
                      <Space
                        direction="vertical"
                        size="middle"
                        style={{ width: '100%' }}
                      >
                        <Badge.Ribbon
                          text={item.nameGR}
                          color={item.color}
                          key={item.id}
                        >
                          <Card
                            hoverable
                            title={
                              <Flex gap={7}>
                                <EyeOutlined
                                  onClick={() => {
                                    setIsModalOpen(true);
                                    setSelectedEvent(item);
                                    setViewOnly(true);
                                  }}
                                />
                                <EditOutlined
                                  onClick={() => {
                                    setIsModalOpen(true);
                                    setSelectedEvent(item);
                                    setViewOnly(false);
                                  }}
                                />
                              </Flex>
                            }
                            size="small"
                          >
                            <Row>
                              <Typography.Text strong>
                                {item.landParcelName}
                              </Typography.Text>
                            </Row>
                            <Row>Καλλιέργεια: {item.landParcelCultivation}</Row>
                          </Card>
                        </Badge.Ribbon>
                      </Space>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </>
      )}
      <Modal
        open={isModalOpen}
        footer={false}
        width={800}
        destroyOnClose
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        {selectedEvent &&
          calendarActivityForms[selectedEvent.nameGR.replace(/\s+/g, '')]}
      </Modal>
    </>
  );
};
export default CalendarList;
