import { Outlet } from 'react-router-dom';
import { AuthContext } from '../../shared/context/auth-context';
import { FC, useContext } from 'react';
import Calendar from '../../pages/Calendar/Calendar';

const CalendarRouter: FC = () => {
  const { role } = useContext(AuthContext);
  return role != 'Διαχειριστής' ? <Outlet /> : <Calendar />;
};
export default CalendarRouter;
