export const dateTimeEpochToGr = (epochTime: number): string => {
  const time = new Date(epochTime);
  return new Intl.DateTimeFormat('el-GR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  }).format(time);
};

export const dateEpochToGR = (epochTime: number | null): string | null => {
  if (epochTime == null) return null;
  const date = new Date(epochTime);
  return new Intl.DateTimeFormat('el-GR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(date);
};

export const formatDate = (arr: any[] | undefined) => {
  if (!arr) return [];
  return arr.map((obj) => {
    const newObj = {
      ...obj,
      from: dateEpochToGR(obj.from),
      to: dateEpochToGR(obj.to),
    };
    return newObj;
  });
};

export const messagesFromApi = (messages: any) => {
  for (const key in messages) {
    if (Array.isArray(messages[key])) {
      return messages[key];
    }
  }
};
