import endPoints from '../endPoints.json';
import dayjs from 'dayjs';

export const dateTimeEpochToGr = (epochTime: number): string => {
  const time = new Date(epochTime);
  return new Intl.DateTimeFormat('el-GR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  }).format(time);
};

export const dateEpochToGR = (epochTime: number | null): string | null => {
  if (epochTime == null) return null;
  const date = new Date(epochTime);
  return new Intl.DateTimeFormat('el-GR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(date);
};

export const formatDate = (arr: any[] | undefined) => {
  if (!arr) return [];
  return arr.map((obj) => {
    const newObj = {
      ...obj,
      from: dateEpochToGR(obj.from),
      to: dateEpochToGR(obj.to),
    };
    return newObj;
  });
};

export const messagesFromApi = (messages: any) => {
  for (const key in messages) {
    if (Array.isArray(messages[key])) {
      return messages[key];
    }
  }
};

export const getBaseUrl = () => {
  switch (process.env.REACT_APP_ENVIROMENT) {
    case 'PRODUCTION':
      return endPoints.BASEURL;
    case 'QA':
      return endPoints.BASEURLQA;
    case 'DEVELOPMENT_TEST':
      return endPoints.BASEURLDEVTEST;
    case 'ECS_TEST':
      return endPoints.BASEURLECSTEST;
    default:
      return endPoints.BASEURLDEV;
  }
};

export const getOpekepeAuthorizationEndpoint = () => {
  let baseRedirectUrl;

  switch (process.env.REACT_APP_ENVIROMENT) {
    case 'PRODUCTION':
      baseRedirectUrl = endPoints.BASEOPEKEPEREDIRECTURL;
      break;
    case 'QA':
      baseRedirectUrl = endPoints.BASEOPEKEPEREDIRECTURLQA;
      break;
    default:
      baseRedirectUrl = endPoints.BASEOPEKEPEREDIRECTURLDEV;
  }

  return `${endPoints.BASEOPEKEPEURL}auth?client_id=farmacon&redirect_uri=${baseRedirectUrl}/authcallback/taxisnet&response_type=code&scope=email`;
};

export const getCurrentYear = (): string => {
  return dayjs().year().toString();
};

export const getEAESeasonDisabledDates = (eaeSeason: any) => (current: any) => {
  if (!eaeSeason) return undefined;
  const season = Array.isArray(eaeSeason) ? eaeSeason[0] : eaeSeason;

  const { start, end } = season;
  const startDate = dayjs(start);
  const endDate = end ? dayjs(end) : null;
  return (
    current.isBefore(startDate, 'day') ||
    (endDate && current.isAfter(endDate, 'day'))
  );
};
