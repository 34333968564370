import { FC, useContext } from 'react';
import { Col, Dropdown, Flex, Layout, MenuProps, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { AuthContext } from '../../shared/context/auth-context';
import { useNavigate } from 'react-router-dom';
import NotificationList from '../EventReminder/NotificationList';
import endPoints from '../../shared/endPoints.json';

const Header: FC = () => {
  const navigate = useNavigate();
  const { Header } = Layout;
  const { logout, firstName, lastName, role, userOrigin } =
    useContext(AuthContext);
  const baseRedirectUrl = `${
    process.env.REACT_APP_ENVIROMENT === 'PRODUCTION'
      ? endPoints.BASEOPEKEPEREDIRECTURL
      : process.env.REACT_APP_ENVIROMENT === 'QA'
        ? endPoints.BASEOPEKEPEREDIRECTURLQA
        : endPoints.BASEOPEKEPEREDIRECTURLDEV
  }`;

  const items: MenuProps['items'] = [
    ...(role === 'Καλλιεργητής'
      ? [
          {
            label: 'Το πακέτο μου',
            key: '/mysubscription',
          },
        ]
      : []),
    {
      label: 'Αλλαγή στοιχείων πρόσβασης',
      key: '/profile',
    },
    { type: 'divider' },
    {
      danger: true,
      label: 'Αποσύνδεση',
      key: '/logout',
    },
  ];

  const handleOpekepeLogout = () => {
    const logoutOpekepeUrl = `${endPoints.BASEOPEKEPEURL}logout?post_logout_redirect_uri=${baseRedirectUrl}/logout/taxisnet&client_id=farmacon`;
    window.location.href = logoutOpekepeUrl;
  };
  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === '/logout') {
      userOrigin == 'Basic-ΟΠΕΚΕΠΕ' ? handleOpekepeLogout() : logout();
      return;
    }
    navigate(e.key);
  };

  return (
    <Header style={{}}>
      <Row
        justify="end"
        style={{
          width: '100%',
          height: '100%',
        }}
        align="bottom"
      >
        <Col sm={{ span: 4 }}>
          <Flex justify="flex-end" align="center" gap={15}>
            {role === 'Καλλιεργητής' && <NotificationList />}

            <Dropdown.Button
              icon={<UserOutlined />}
              size="large"
              type="primary"
              menu={{
                items: items,
                onClick: handleMenuClick,
              }}
              trigger={['click']}
            >
              {firstName} {lastName}
            </Dropdown.Button>
          </Flex>
        </Col>
      </Row>
    </Header>
  );
};
export default Header;
